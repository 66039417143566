import { Client as client } from 'api';
import { User } from 'types';
import { DASHBOARD_PATH } from './constants';

export type GetDashboardParams = {
  lookerName: string;
  currentUser: User;
  permutationArray: string[];
  version?: string;
};

export const getDashboard = ({
  lookerName,
  currentUser,
  permutationArray,
  version,
}: GetDashboardParams): Promise<{ [key: string]: any }> => {
  const { account, id: userId } = currentUser;
  const accountUri = account?.uri_name;

  const params = new URLSearchParams();
  params.set('account_uri', accountUri);
  params.set('user_id', userId);
  params.set('permutation', JSON.stringify(permutationArray));
  params.set('embed_domain', window.location.origin);
  if (version) params.set('version', version);

  return client.get(`${DASHBOARD_PATH}/${lookerName}?${params.toString()}`);
};
