import React, { useState, useCallback, useEffect } from 'react';
import CustomProvider from 'state/provider';
import { useReportsWithMultiMvr } from 'api/reports';
import { useReportMergedExceptions } from 'hooks';
import { Settings } from 'types';
import Context from './context';
import { getRecordsFilter } from './utils';
import { usePackagesFromApi, useReportEta } from './hooks';

type Props = {
  reportId: string;
  screenings: string[];
  initialSettings: Settings;
};

const Provider: React.FC<Props> = ({
  reportId,
  screenings,
  initialSettings,
  children,
}) => {
  const { report, isLoading, isError } = useReportsWithMultiMvr({ reportId });
  const reportExceptions = useReportMergedExceptions(reportId);
  const { packageObj, packages, packagesLoading, upgradePackages } =
    usePackagesFromApi(report);
  const recordsFilter = getRecordsFilter(report);
  const eta = useReportEta(report);

  const [settings, setSettings] = useState(initialSettings);

  const updateSettings = useCallback((newSettings: Partial<Settings>) => {
    setSettings(prevSettings => ({
      ...prevSettings,
      ...newSettings,
    }));
  }, []);

  useEffect(() => {
    setSettings(initialSettings);
  }, [initialSettings]);

  return (
    <CustomProvider
      context={Context}
      props={{
        isLoading,
        isError,
        packageObj,
        packages,
        packagesLoading,
        recordsFilter,
        report,
        reportExceptions,
        reportId,
        screenings,
        upgradePackages,
        eta,
        settings,
        updateSettings,
      }}
      stateKey='reportPage'
    >
      {children}
    </CustomProvider>
  );
};

export default Provider;
