import React from 'react';
import { FetchPackageType } from '@dashboard-experience/utils';
import {
  StyledTooltip,
  StyledPackageNameLabel,
  StyledRadioButton,
  StyledRadioWrapper,
} from './PackageChoiceCard.styles';

type RadioContainerProps = {
  packageData: FetchPackageType;
  availableForManualBulk: boolean;
  checked: boolean;
};

const RadioContainer: React.FC<RadioContainerProps> = ({
  packageData,
  availableForManualBulk,
  checked,
}) => {
  return (
    <StyledRadioWrapper>
      {packageData.name.length > 47 && availableForManualBulk ? (
        <StyledTooltip label={packageData.name} data-testid='name-tooltip'>
          <StyledPackageNameLabel>{packageData.name}</StyledPackageNameLabel>
        </StyledTooltip>
      ) : (
        <StyledPackageNameLabel>{packageData.name}</StyledPackageNameLabel>
      )}
      <StyledRadioButton checked={checked} />
    </StyledRadioWrapper>
  );
};

export default RadioContainer;
