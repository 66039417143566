/* eslint-disable react/jsx-no-bind */
import React, {
  RefObject,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { IMaskMixin } from 'react-imask';
import { useLocation } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import ReCAPTCHA from 'react-google-recaptcha';
// @ts-ignore
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { useFlag } from '@dashboard-experience/react-flagr';
import { ENV, RECAPTCHA_SITE_KEY, TERMS_OF_SERVICE_CLICKWRAP } from 'Constants';
import { updateSessionStorage } from 'components/Signup/utils/sessionStorage';
import useGetUser from 'components/Signup/hooks/useGetUser';
import {
  useTrackEvent,
  SIGNUP_EVENT_NAMES,
  FOX_SIGNUP_EVENT_NAMES,
} from 'utils/analytics';
import { getExperiment } from 'components/amplitudeExperiment';

import Accordion from '../Accordion';
import {
  CaptchaPolicy,
  CheckboxRow,
  CheckboxStyled,
  Container,
  FlexColumn,
  FormRow,
  FormSection,
  HiddenReCAPTCHA,
  Input,
  RadioButton,
  RadioButtonTitle,
  Section,
  SectionDescription,
  TooltipIcon,
  StyledClickWrap,
  StyledClickWrapChecked,
} from './styles';
import schema from './schema';
import ComplianceStateAndCitySelector from './ComplianceStateAndCitySelector';
import useSubmitWithRecaptcha from '../../hooks/useSubmitWithRecaptcha';
import useInitialValues from './useInitialValues';
import getFormStatus from './getFormStatus';
import PasswordInput from './PasswordInput';
import ChoiceCardGroup from './ChoiceCardGroup';
import AddressInput from './AddressInput';

type Props = {
  setPaymentDetailsOpen: (value: boolean) => void;
  setError: (value: any) => void;
  hasCreatedAccount?: boolean;
};

const ConfirmationOfPurposeText = () => {
  const { t } = useTranslation('signup');
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    window.open('https://checkr.com/legal/customer-agreement', '_blank');
  };
  return (
    <div>
      {t(`pages.createAccountPage.confirmationOfPurpose`)}
      <a
        href='https://checkr.com/legal/customer-agreement'
        onClick={handleClick}
      >
        {t(`pages.createAccountPage.checkrCustomerServicesAgreement`)}
      </a>
    </div>
  );
};

const ConfirmationOfPurposeClickwrapText = () => {
  const { t } = useTranslation('signup');
  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    window.open(
      'https://vault.pactsafe.io/s/a7d790b7-a76b-44e6-bc86-560d72c03739/legal.html?g=47534',
      '_blank',
    );
  };
  return (
    <div>
      {t(`pages.createAccountPage.confirmationOfPurposeClickwrap.one`)}{' '}
      <a
        href='https://vault.pactsafe.io/s/a7d790b7-a76b-44e6-bc86-560d72c03739/legal.html?g=47534'
        onClick={handleClick}
      >
        {t(`pages.createAccountPage.checkrIncServicesAgreement`)}
      </a>{' '}
      {t(`pages.createAccountPage.confirmationOfPurposeClickwrap.two`)}
    </div>
  );
};

const MaskedInput = IMaskMixin(
  ({
    inputRef,
    ...props
  }: {
    inputRef: RefObject<HTMLInputElement>;
    [key: string]: any;
  }) => <Input {...props} ref={inputRef} />,
);

export const getFirstName = (fullName: string | undefined | null) => {
  if (!fullName) return ''; // Handle empty or undefined input
  return fullName.split(' ')[0];
};

export const getAccordionDisplayName = (
  queryParams: URLSearchParams,
  createAccountDisabled: boolean,
  getUserName: string,
) => {
  const searchParam = queryParams.get('fullName');
  let createAccountSection;
  try {
    const signup = sessionStorage.getItem('signup');
    const parsedSignup = JSON.parse(signup || '{}');
    createAccountSection = parsedSignup.createAccountSection;
  } catch (error) {
    createAccountSection = null;
  }

  // 1. Use query params
  if (searchParam) return `Hi, ${getFirstName(searchParam)}!`;

  // 2. If account was created, check sessionStorage
  if (createAccountDisabled && createAccountSection?.fullName)
    return `Hi, ${getFirstName(createAccountSection?.fullName)}!`;

  // 3. If account was created but sessionStorage is empty use GET response
  if (createAccountDisabled && getUserName)
    return `Hi, ${getFirstName(getUserName)}!`;

  return '';
};

const CreateAccountSection = ({
  setPaymentDetailsOpen,
  setError,
  hasCreatedAccount = false,
}: Props) => {
  const { data: user } = useGetUser();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { t } = useTranslation('signup');
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const currentPage = window.location.pathname;
  const disableRecaptcha =
    !!localStorage.getItem('accessToken') || currentPage === '/signup/payment';

  const { mutate: createAccount } = useSubmitWithRecaptcha();
  const [isLoading, setIsLoading] = useState(false);
  const [disableFields, setDisableFields] = useState(
    !!localStorage.getItem('accessToken'),
  );
  const [isAccountCreationSuccess, setIsAccountCreationSuccess] =
    useState(false);
  const trackEvent = useTrackEvent();
  const [isAccordionOpen, setAccordionOpen] = useState(!disableFields);
  const [hasServerError, setHasServerError] = useState(false);
  const [experiment, setExperiment] = useState<any>(null);

  const [hasCheckedClickWrapTerms, setHasCheckedClickWrapTerms] =
    useState(false);
  const siteAccessId = 'a7d790b7-a76b-44e6-bc86-560d72c03739';
  const clickwrapGroupKey = 'checkr-inc-services-agreement-clickwrap';
  const showTermsOfServiceClickwrapFlag =
    useFlag(TERMS_OF_SERVICE_CLICKWRAP)?.variantKey === 'on';

  useEffect(() => {
    setExperiment(getExperiment());
  }, []);

  const displayName = getAccordionDisplayName(
    searchParams,
    disableFields,
    user?.full_name,
  );

  const initialValues = useInitialValues({
    email: '',
    fullName: '',
    password: '',
    phone: '',
    company: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    taxId: '',
    multipleLocations: null,
    purpose: '',
    complianceState: '',
    complianceCity: '',
  });

  const onSubmit = async (data: any) => {
    setError(null);
    setHasServerError(false);
    setIsLoading(true);
    let recaptchaCode: string | null | undefined;
    try {
      recaptchaCode = await recaptchaRef.current?.executeAsync();
    } catch (error) {
      setIsLoading(false);
      setError(error);
      setHasServerError(true);
    } finally {
      recaptchaRef.current?.reset();
    }

    createAccount(
      {
        formData: {
          ...data,
          amplitudeExperimentBucket: experiment?.variant(
            'ab-13-mvt-on-signup-packages-page',
          ).value,
        },
        recaptchaCode,
      },
      {
        onSuccess: data => {
          setDisableFields(true);
          setPaymentDetailsOpen(true);
          setIsLoading(false);
          setIsAccountCreationSuccess(true);

          if (hasCheckedClickWrapTerms) {
            /* eslint no-underscore-dangle: 0 */
            // @ts-ignore
            global.window._ps('set', 'custom_data', {
              name: data.res.user.full_name,
              company_name: data.res.company.name,
              email: data.res.user.email,
              mobile_number: data.res.company.phone,
              account_resource_id: data.res.id,
              account_uri_name: data.res.uri_name,
            });

            /* eslint no-underscore-dangle: 0 */
            // @ts-ignore
            global.window._ps('send', 'updated', 'custom_data');

            /* eslint no-underscore-dangle: 0 */
            // @ts-ignore
            global.window._ps(`${clickwrapGroupKey}:send`, 'agreed', {
              disable_sending: false,
            });
          }
        },
        onError: (error: any) => {
          setError({ error, source: 'create-account' });
          setHasServerError(true);
          setIsLoading(false);
        },
      },
    );
  };

  const trackSaveClick = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_SIGNUP_PAGE_NEXT_CLICKED, {
      'Page Type': 'checkr',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
  }, [trackEvent]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: schema,
    isInitialValid: false,
  });

  const {
    touched,
    handleChange,
    handleBlur,
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = formik;

  useEffect(() => {
    if (showTermsOfServiceClickwrapFlag) {
      setFieldValue('purpose', undefined);
    }
    if (user && disableFields) {
      setFieldValue('email', user.email);
      setFieldValue('fullName', user.full_name);
      setFieldValue('company', user.account.company.name);
      setFieldValue('street', user.account.company.street);
      setFieldValue('state', user.account.company.state);
      setFieldValue('city', user.account.company.city);
      setFieldValue('zipcode', user.account.company.zipcode);
      setFieldValue('purpose', 'employment');
    }
  }, [user, disableFields, showTermsOfServiceClickwrapFlag, setFieldValue]);

  useEffect(() => {
    const valuesCopy = { ...values };
    delete valuesCopy.password;
    updateSessionStorage('createAccountSection', valuesCopy);
  }, [values]);

  useEffect(() => {
    if (values.multipleLocations) {
      setFieldValue('complianceCity', '');
      setFieldValue('complianceState', '');
    }
  }, [values.multipleLocations, setFieldValue]);

  // TODO: Will be used in the future
  const handlePurposeChange = useCallback(() => {
    setFieldTouched('purpose', true);
    if (values.purpose === 'employment') {
      setFieldValue('purpose', undefined);
    } else setFieldValue('purpose', 'employment');
  }, [setFieldTouched, setFieldValue, values.purpose]);

  useEffect(() => {
    if (!disableFields) {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PAGE_VIEWED, {
        'Page Type': 'checkr',
        'Self Serve Signup Version': 'FOX Signup v2',
      });
    }
  }, [disableFields, trackEvent]);

  useEffect(() => {
    if (isAccountCreationSuccess) {
      trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PAGE_COMPLETED, {
        'Page Type': 'checkr',
        'Self Serve Signup Version': 'FOX Signup v2',
      });
    }
  }, [isAccountCreationSuccess, trackEvent]);

  const handleAccordionHeadingClick = useCallback(() => {
    if (!isAccordionOpen) {
      trackEvent(FOX_SIGNUP_EVENT_NAMES.FOX_SIGNUP_ACCORDION_EXPANDED, {
        Accordion: 'Create Account',
      });
    }

    setAccordionOpen(!isAccordionOpen);
  }, [isAccordionOpen, trackEvent]);

  const showError = (field: string): boolean => {
    return (errors[field as keyof typeof errors] &&
      touched[field as keyof typeof touched]) as boolean;
  };

  const errorMessage = (field: string): string | null => {
    return touched[field as keyof typeof touched]
      ? (errors[field as keyof typeof errors] as string)
      : null;
  };

  const onClickwrapValid = useCallback(() => {
    setHasCheckedClickWrapTerms(true);
    setFieldTouched('purpose', true);
    setFieldValue('purpose', 'employment');
  }, [setHasCheckedClickWrapTerms, setFieldTouched, setFieldValue]);

  const onClickwrapInvalid = useCallback(() => {
    setHasCheckedClickWrapTerms(false);
    setFieldTouched('purpose', false);
    setFieldValue('purpose', undefined);
  }, [setHasCheckedClickWrapTerms, setFieldTouched, setFieldValue]);

  return (
    <Accordion
      primaryText='Create account'
      secondaryText={displayName}
      status={getFormStatus(
        values,
        errors,
        touched,
        disableFields,
        hasServerError,
      )}
      open={!disableFields}
      onHeadingClick={handleAccordionHeadingClick}
    >
      <form onSubmit={handleSubmit}>
        <Container>
          <Section>
            <FlexColumn>
              <h5 className='mb-0'>About you</h5>
            </FlexColumn>
            <FormSection>
              <FormRow>
                <Input
                  labelText='Full name'
                  size='sm'
                  name='fullName'
                  id='fullName'
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('fullName')}
                  invalidText={errorMessage('fullName')}
                  data-testid='fullName-input'
                  disabled={disableFields}
                  autoComplete='name'
                />
              </FormRow>
              <FormRow>
                <Input
                  labelText='Email'
                  size='sm'
                  name='email'
                  id='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={showError('email')}
                  invalidText={errorMessage('email')}
                  data-testid='email-input'
                  disabled={disableFields}
                  autoComplete='email'
                />
                <PasswordInput
                  id='password'
                  data-testid='password-input'
                  size='sm'
                  name='password'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  invalid={showError('password')}
                  invalidText={errorMessage('password')}
                  disabled={disableFields}
                  placeholder='Create password'
                />
              </FormRow>
              <FormRow>
                <MaskedInput
                  labelText='Phone number'
                  mask='000-000-0000'
                  width='50%'
                  size='sm'
                  name='phone'
                  id='phone'
                  value={values.phone}
                  onAccept={(value: any, mask: any) =>
                    handleChange({ target: { name: 'phone', value } })
                  }
                  onBlur={handleBlur}
                  invalid={showError('phone')}
                  invalidText={errorMessage('phone')}
                  data-testid='phone-input'
                  disabled={disableFields}
                  autoComplete='tel-national'
                />
              </FormRow>
            </FormSection>
          </Section>
          <Section>
            <FlexColumn>
              <h5 className='mb-0'>About your business</h5>
              <SectionDescription>
                More information helps us create and verify your account faster.
              </SectionDescription>
            </FlexColumn>
            <FormSection gap='24px'>
              <FormRow>
                <Input
                  labelText='Business name'
                  name='company'
                  id='company'
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  data-testid='company-input'
                  disabled={disableFields}
                  placeholder="Dimitry's Donuts"
                  invalid={showError('company')}
                  invalidText={errorMessage('company')}
                />
              </FormRow>
              <AddressInput
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                disableFields={disableFields}
                errors={errors}
                handleBlur={handleBlur}
                showError={showError}
                errorMessage={errorMessage}
              />
              <FormRow>
                <MaskedInput
                  mask='00-0000000'
                  width='50%'
                  labelText={
                    <>
                      <M.FormLabel>US Tax ID</M.FormLabel>
                      <M.Tooltip
                        label={t('pages.createAccountPage.taxIdTooltip')}
                      >
                        <TooltipIcon
                          icon='InformationFilled'
                          disabled
                          onClick={(e: React.MouseEvent) => {
                            e.preventDefault();
                          }}
                        />
                      </M.Tooltip>
                    </>
                  }
                  size='sm'
                  name='taxId'
                  id='taxId'
                  value={values.taxId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onAccept={(value: any, mask: any) =>
                    handleChange({ target: { name: 'taxId', value } })
                  }
                  placeholder='US Tax ID'
                  invalid={showError('taxId')}
                  invalidText={errorMessage('taxId')}
                  data-testid='taxId-input'
                  disabled={disableFields}
                />
              </FormRow>
              <FormRow>
                <RadioButton>
                  <RadioButtonTitle>
                    Do you hire in multiple locations?
                  </RadioButtonTitle>
                  <ChoiceCardGroup
                    name='multipleLocations'
                    value={values.multipleLocations}
                    onChange={setFieldValue}
                    label1='Yes'
                    label2='No'
                    disabled={disableFields}
                    error={showError('multipleLocations')}
                  />
                </RadioButton>
              </FormRow>
              {values.multipleLocations === false && (
                <FormRow>
                  <ComplianceStateAndCitySelector
                    errors={errors}
                    values={values}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    initialValues={initialValues}
                  />
                </FormRow>
              )}

              {showTermsOfServiceClickwrapFlag ? (
                <StyledClickWrap>
                  <h5>Terms of Service</h5>
                  {hasCreatedAccount ? (
                    <StyledClickWrapChecked>
                      <CheckboxStyled
                        type='checkbox'
                        id='confirmation-of-purpose-checkbox'
                        data-testid='confirmation-of-purpose-checkbox'
                        name='purpose'
                        onChange={handlePurposeChange}
                        checked={values.purpose === 'employment'}
                        labelText={<ConfirmationOfPurposeClickwrapText />}
                        disabled
                      />
                    </StyledClickWrapChecked>
                  ) : (
                    <PSClickWrap
                      name='purpose'
                      data-testid='confirmation-of-purpose-checkbox'
                      accessId={siteAccessId}
                      groupKey={clickwrapGroupKey}
                      signerIdSelector='email'
                      disableSending
                      onValid={onClickwrapValid}
                      onInvalid={onClickwrapInvalid}
                      allowDisagreed
                      testMode={ENV !== 'production'}
                      confirmationEmailSubject="You've agreed to Checkr, Inc.'s Services Agreement"
                    />
                  )}
                </StyledClickWrap>
              ) : (
                <CheckboxRow>
                  <CheckboxStyled
                    type='checkbox'
                    id='confirmation-of-purpose-checkbox'
                    data-testid='confirmation-of-purpose-checkbox'
                    name='purpose'
                    onChange={handlePurposeChange}
                    checked={values.purpose === 'employment'}
                    labelText={<ConfirmationOfPurposeText />}
                    invalid={showError('purpose')}
                    invalidText={errorMessage('purpose')}
                    disabled={disableFields}
                  />
                </CheckboxRow>
              )}
            </FormSection>
          </Section>
          <Section>
            <FlexColumn>
              <h5 className='mb-0'>Review your information</h5>
              <SectionDescription>
                Before you continue, confirm your information because you
                can&#39;t change it later.
              </SectionDescription>
            </FlexColumn>
            {disableFields ? (
              <span>Your account has been created.</span>
            ) : (
              <FormSection gap='24px'>
                {isLoading ? (
                  <M.LoadingInline description='Creating your account...' />
                ) : (
                  <M.Button
                    type='submit'
                    data-testid='create-account-save-continue-button'
                    onClick={trackSaveClick}
                    disabled={!formik.isValid}
                  >
                    Save & continue
                  </M.Button>
                )}
                <CaptchaPolicy>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a href='https://policies.google.com/privacy'>
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href='https://policies.google.com/terms'>
                    Terms of Service
                  </a>{' '}
                  apply.
                </CaptchaPolicy>
              </FormSection>
            )}
          </Section>
        </Container>
      </form>
      {!disableRecaptcha && (
        <HiddenReCAPTCHA
          ref={recaptchaRef}
          size='invisible'
          sitekey={RECAPTCHA_SITE_KEY || ''}
        />
      )}
    </Accordion>
  );
};

export default CreateAccountSection;
