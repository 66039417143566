import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import type { AddonsT } from '../..';
import { ScreeningProductType } from '../../AddonsStep.enums';
import ScreeningSectionItemButton from '../ScreeningSectionItem/ScreeningSectionItemButton';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import { StyledScreeningContainer } from '../../AddonsStep.styles';

type Props = {
  id: string;
  price: string;
  isAdded: boolean;
  isIncluded: boolean;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
};

const GlobalWatchlistSearch = ({
  id,
  price,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
}: Props) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.crimSearch.globalWatchlistSearch',
  });

  const handleAddClick = useCallback(() => {
    onAddClick(ScreeningProductType.GLOBAL_WATCHLIST_SEARCH, price);
  }, [onAddClick, price]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.GLOBAL_WATCHLIST_SEARCH);
  }, [onRemoveClick]);

  return (
    <StyledScreeningContainer>
      <ScreeningSectionItem
        id={id}
        description={t('description')}
        advantages={t('advantages', { returnObjects: true })}
        disadvantages={t('considerations', {
          returnObjects: true,
        })}
        price={price}
        screeningName={t('name')}
      />
      <AddButtonContainer>
        <ScreeningSectionItemButton
          id={id}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          added={isAdded}
          included={isIncluded}
        />
      </AddButtonContainer>
    </StyledScreeningContainer>
  );
};

export default GlobalWatchlistSearch;
