import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from '../locales';
import { StyledUnavailablePackageTooltip } from './styles/SelectPackageStyles';

const UnavailableForManualBulkTooltip = (children: ReactNode) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:selectPackage`;

  return (
    <StyledUnavailablePackageTooltip
      align='top'
      label={t(`${basePath}.manualBulkTooltip`)}
      highlighted={false}
      trigger='button'
      leaveDelayMs={0}
      mini
    >
      {children}
    </StyledUnavailablePackageTooltip>
  );
};

export default UnavailableForManualBulkTooltip;
