import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledPackageHeader,
  StyledMobilePackageHeader,
} from '../styles/PackageCardList.styles';
import { namespace } from '../../locales';

const PackageHeaderWithMobile = () => {
  const { t } = useTranslation();
  const basePath = `${namespace}:selectPackage`;
  return (
    <>
      <StyledPackageHeader>
        {t(`${basePath}.tableHeaders.packageName`)}
      </StyledPackageHeader>
      <StyledMobilePackageHeader>
        {t(`${basePath}.tableHeaders.package`)}
      </StyledMobilePackageHeader>
    </>
  );
};

export default PackageHeaderWithMobile;
