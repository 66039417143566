import styled from 'styled-components';
import { Container } from '../../styles';

export const VerifyEmailAddressContainer = styled(Container)<{
  orderSubmitted: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  overflow: hidden;
  margin-top: ${({ orderSubmitted }) => (orderSubmitted ? '170px' : '250px')};
  border-radius: 8px;
`;

export const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

export const OrderSubmissionInstructions = styled.div`
  align-self: stretch;
  color: #1a2026;
  text-align: center;
  font-family: 'National 2';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`;

export const OrderSubmittedEmail = styled.div`
  align-self: stretch;
  color: rgba(26, 32, 38, 0.78);
  text-align: center;
  font-family: 'Public Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
`;

export const OrderSubmittedTitle = styled.div`
  width: 482px;
  color: #1a2026;
  text-align: center;
  font-family: 'National 2';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-bottom: 4px;
`;

export const ErrorMessage = styled.div`
  color: #cd2f1d;
  text-align: center;
  font-family: 'Public Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Separator = styled.div`
  width: 75px;
  height: 1px;
  background-color: #e5e7e9;
  margin: auto;
  align-self: center;
`;
