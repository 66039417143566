import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

// eslint-disable-next-line import/prefer-default-export
export const StyledToggleTip = styled(M.Toggletip)`
  #mastodon && {
    margin-left: 0.25rem;
    margin-top: 0.275rem;
    .mastodon-toggletip-content {
      max-width: 18rem;
      color: ${colors.white} !important;
    }
    .mastodon-toggletip-button > svg {
      fill: ${colors.uiNavy600};
    }
  }
`;
