import querystring from 'querystring';
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  CurrentUser,
  GenericObject,
  accountHasPermission,
} from '@dashboard-experience/utils';
import styled from 'styled-components';
import { useFlag } from '@dashboard-experience/react-flagr';
import { PR_US_STATE_KEY, WASHINGTON_CONSENT_FLAG_KEY } from 'Constants';
import { LoadingScreen } from 'components/Account';
import { useUser } from 'context/CurrentUser';
import PageHeader from 'components/PageHeader';
import { useTranslation, Trans } from 'react-i18next';
import { Package } from 'types/report/package';
import Context from '../context';

const iframeStyle = {
  width: '100%',
  height: '100%',
  minHeight: '1000px',
};

// So the Dropdown doesn't extend below the iframe
export const Buffer = styled.div`
  padding-bottom: 200px;
  box-sizing: border-box;
`;

const Center = styled.div`
  color: ${colors.brandNavy4};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  h2 {
    margin-top: 32px !important;
  }
`;

export const Wrapper = ({ ...props }) => <div {...props} />;
Wrapper.defaultProps = {
  withWrapper: true,
  _hasBeenWrapped: undefined,
};
Wrapper.propTypes = {
  withWrapper: PropTypes.bool,
  _hasBeenWrapped: PropTypes.bool,
};

type Props = {
  selection: any;
  currentUser: CurrentUser;
};

const getApplyUrl = ({ selection, currentUser }: Props) => {
  if (!selection || !selection.package) return undefined;
  const {
    node,
    geo,
    // program
    country,
    city,
    state,
    package: pkg,
    purpose,
  } = selection;
  const base_url = pkg.apply_url;
  const query = { order: currentUser.id } as GenericObject;
  if (!currentUser?.account?.segmentation_enabled && geo && geo.id !== 'None')
    query.geo_id = geo.id;
  if (node) query.node_id = node.custom_id;
  if (country) query.wl_country = country;
  if (state) query.wl_state = state.abbreviation;
  if (city) query.wl_city = city.name;
  if (purpose) query.permissible_purpose = purpose;
  return `${base_url}?${querystring.stringify(query)}`;
};

const View = () => {
  const {
    account,
    geos,
    isSingleNode,
    nodes,
    selectedNode,
    nodeIsLoading,
    nodePackages,
    onNodeChange,
    onNodeFilterChange,
    packages,
    programs,
    anyLoading,
  } = useContext(Context);
  const currentUser = useUser();

  const { t } = useTranslation();
  const title = {
    label: t(`pageHeaders.manualOrder.title`),
    // Id to track component in Pendo
    props: { className: 'mastodon-manual-package-order-title' },
  };

  const description = (
    <>
      <p>
        <Trans t={t} i18nKey='pageHeaders.manualOrder.description'>
          <strong>candidates</strong>
          <a
            href='https://help.checkr.com/helpcenter/s/article/360010537253-Use-report-features'
            target='_blank'
            rel='noreferrer'
          >
            Learn more
          </a>
        </Trans>
      </p>
      <p>{t('pageHeaders.manualOrder.description2')}</p>
    </>
  );

  const [selection, setSelection] = useState(undefined);
  const [selectedPackage, setSelectedPackage] = useState<Package | undefined>();

  const applyUrl = getApplyUrl({ selection, currentUser });

  const withPr = useFlag(PR_US_STATE_KEY)?.variantKey === 'true' || false;
  const washingtonConsentEnabled =
    useFlag(WASHINGTON_CONSENT_FLAG_KEY)?.variantKey === 'true' || false;
  const screeningListProps = {
    showPriceFlag:
      accountHasPermission(currentUser, 'show_package_price') &&
      currentUser?.account?.package_price_state !== 'disabled via partner',
    packagePrice: selectedPackage?.price,
  };

  return anyLoading ? (
    <LoadingScreen />
  ) : (
    <Buffer>
      <PageHeader title={title} descriptionNode={description} />
      <M.PackageOrder
        container='default'
        onChange={setSelection}
        onPackageChange={setSelectedPackage}
        nodes={nodes}
        geos={geos}
        programs={programs}
        packages={packages}
        nodePackages={nodePackages}
        nodeIsLoading={nodeIsLoading}
        isSingleNode={isSingleNode}
        selectedNode={selectedNode}
        onNodeChange={onNodeChange}
        onNodeFilterChange={onNodeFilterChange}
        account={account}
        workLocationSelectProps={{ withPr }}
        isWashingtonConsentEnabled={washingtonConsentEnabled}
        screeninglistProps={screeningListProps}
      >
        {applyUrl && (
          <Wrapper>
            <Center>
              <p>
                If you don&apos;t see the forms below,{' '}
                <a href={applyUrl} target='_blank' rel='noopener noreferrer'>
                  click here to open and fill them in a new window.
                </a>
              </p>
              <h2>Add your candidate&apos;s information</h2>
            </Center>
            <iframe
              src={applyUrl}
              title='Manual Order'
              frameBorder='0'
              data-align='middle'
              style={iframeStyle}
            />
          </Wrapper>
        )}
      </M.PackageOrder>
      <span>* Additional third-party fees may apply. </span>
      <M.Link
        style={{ color: colors.uiNavy600 }}
        href='https://checkr.com/pricing/pass-through-fees'
        target='_blank'
        rel='noreferrer'
      >
        Learn More
      </M.Link>
    </Buffer>
  );
};

export default View;
