import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import CIRCLE_CHECKMARK from '../../../../Packages/CreatePackage/Context/CIRCLE_CHECKMARK';

export const StyledContainer = styled.div<{
  checked: boolean;
  onClick?: Function;
}>`
  flex: 1;
  min-width: 173px;
  min-height: 261px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 8px 16px 24px 16px;
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  border: ${props =>
    props.checked
      ? `1px solid ${colors.uiNavy200} !important`
      : `1px solid ${colors.uiGrey200} !important`};
  box-shadow: none !important;
  :hover,
  :hover .mastodon-radio-button,
  :hover .cds--radio-button__appearance {
    background: ${colors.uiNavy100} !important;
  }
  :has(:active .cds--radio-button__appearance) {
    background: ${colors.uiNavy100} !important;
  }
  :hover {
    border: 1px solid ${colors.uiNavy200} !important;
  }

  &:hover {
    cursor: ${props => (props.onClick ? 'pointer' : 'not-allowed')};
  }

  &.card-disabled {
    background: ${colors.uiGrey100} !important;
    border-color: ${colors.borderPrimaryLight} !important;
    color: ${colors.uiTextDisabledLight} !important;
    cursor: not-allowed !important;
    height: 100%;
    margin-bottom: 0 !important;

    &:active,
    .mastodon-radio-button,
    &:hover .cds--radio-button__appearance,
    .cds--radio-button__appearance:before {
      background: ${colors.uiGrey100} !important;
    }

    .cds--radio-button__label {
      cursor: not-allowed !important;
    }
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    min-width: 0;
  }

  @media screen and (max-width: 1023px) {
    height: 100%;
    min-height: 0;
    padding: 1rem;
  }
`;

export const StyledRadioButton = styled(M.RadioButton)<{ checked: boolean }>`
  .highlighted-label {
    border-bottom: none !important;
  }
  .cds--radio-button__appearance {
    border: 1px solid ${colors.brandSlate5} !important;
  }
  background: ${props =>
    props.checked
      ? `${colors.uiNavy100} !important`
      : `${colors.uiGrey0} !important`};
  .cds--radio-button:focus
    + .cds--radio-button__label
    .cds--radio-button__appearance::after {
    box-shadow: unset !important;
  }

  .cds--radio-button:checked
    + .cds--radio-button__label
    .cds--radio-button__appearance::before {
    margin-left: -15px;
    margin-top: -15px;
    content: url(${CIRCLE_CHECKMARK}) !important;
    background-color: ${props =>
      props.checked
        ? `${colors.uiNavy100} !important`
        : `${colors.uiGrey0} !important`};
    width: 100% !important;
    height: 100% !important;
    transform: scale(0.5) !important;
  }
`;

export const StyledRadioWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 22px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 9px;

  @media all and (max-width: 940px) {
    width: 100%;
  }

  @media all and (max-width: 1023px) {
    margin-bottom: 0.25rem;
  }
`;

export const StyledPackageNameLabel = styled.div`
  color: ${colors.uiTextPrimaryLight};

  max-width: 176px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 102%;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; // Number of lines to show before ellipsis
  max-height: 3em;

  .card-disabled & {
    color: ${colors.uiTextDisabledLight};
  }
`;

export const StyledRecommendedTag = styled.div<{ visible: boolean }>`
  color: ${colors.uiAqua600};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 5px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  .card-disabled & {
    color: ${colors.uiTextDisabledLight} !important;
  }

  // hide empty recommended tag on mobile
  @media all and (max-width: 1023px) {
    display: ${props => (props.visible ? 'visible' : 'none')};
  }
`;

export const StyledBreakLine = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  border-bottom: 1px solid ${colors.uiGrey300};

  @media all and (max-width: 1023px) {
    display: none;
  }
`;

export const StyledScreeningListWrapper = styled.div<{
  showMoreMobile: boolean;
}>`
  ul {
    list-style-type: square;
  }
  ul > li {
    color: ${colors.brandGreen4};
  }

  ul > li > span {
    color: ${colors.uiTextPrimaryLight};
  }

  .card-disabled & {
    ul > li {
      &::before,
      span {
        color: ${colors.uiTextDisabledLight};
      }
    }
  }

  @media all and (max-width: 1023px) {
    display: ${({ showMoreMobile }) => (showMoreMobile ? 'block' : 'none')};
  }
`;

export const StyledTooltip = styled(M.Tooltip)`
  border: none !important;
`;

export const StyledShowMoreLink = styled(M.Link)`
  margin-left: 22px;
`;

export const StyledShowMoreLinkMobile = styled(M.Link)`
  display: none;
  margin-top: 9px;
  font-size: 12px;

  @media all and (max-width: 1023px) {
    display: flex;
  }
`;

export const ScreeningUl = styled.ul`
  list-style: none !important;
  padding-left: 5px;

  @media all and (max-width: 1023px) {
    padding-left: 12px;
  }
`;

export const StyledScreeningList = styled(ScreeningUl)`
  @media all and (max-width: 1023px) {
    padding-left: 0;
    padding-top: 5px;
  }
`;

export const StyledBlurb = styled.p`
  color: ${colors.uiTextTertiaryLight} !important;
  height: 2.25rem;
  padding-bottom: 0.25rem;

  @media all and (max-width: 1023px) {
    height: unset;
  }
`;
