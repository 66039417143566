import React, { useCallback } from 'react';
import type { GetStartedT } from '..';
import { StyledNodeDropdown } from './Dropdown.styles';

type Props = {
  nodes: GetStartedT.Node[];
  selectedNode?: GetStartedT.Node;
  setSelectedLocation: (location: GetStartedT.WorkLocation) => void;
  setSelectedNode: (node: GetStartedT.Node) => void;
  handleNodeFilter: (searchText: string) => void;
  isLoading: boolean;
  invalid: boolean;
  disabled: boolean;
};

const NodesDropdown: React.FC<Props> = ({
  nodes,
  selectedNode,
  setSelectedNode,
  setSelectedLocation,
  handleNodeFilter,
  isLoading,
  invalid,
  disabled,
}) => {
  const handleNodeChange = useCallback(
    selectedItem => {
      setSelectedNode(selectedItem);

      // Auto set location to US once a new node is selected
      setSelectedLocation({
        country: 'US',
        state: undefined,
        city: undefined,
      });
    },
    [setSelectedNode, setSelectedLocation],
  );

  const handleFilterChange = useCallback(
    searchText => {
      handleNodeFilter(searchText);
    },
    [handleNodeFilter],
  );

  return (
    <StyledNodeDropdown
      data-testid='node-dropdown'
      id='node-select'
      nodes={nodes}
      selectedNode={selectedNode}
      disabled={disabled} // Add logic to remove
      onFilterChange={handleFilterChange}
      onChange={handleNodeChange}
      loading={isLoading}
      invalid={invalid}
      titleText='Node'
      label=''
    />
  );
};

export default NodesDropdown;
