import React, { useCallback, useEffect } from 'react';
import { parse, unparse } from 'papaparse';
import { M } from '@dashboard-experience/mastodon';
import { toastError } from 'actions';
import { useTranslation } from 'react-i18next';
import { downloadBlobAsFile } from 'utils/APIClient';
import { useDispatch } from 'react-redux';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { namespace } from '../locales';
import FileUploadInput from './FileUploadInput';
import {
  StyledDownloadLink,
  StyledGridCol,
  StyledInlineNotification,
} from './Upload.styles';
import {
  CsvHeaders,
  getRowsWithErrors,
  ManualBulkUploadRow,
  showLinesWithError,
} from '../utils/BulkUploadUtils';
import type { BulkUploadT } from '.';

const Upload: React.FC<{
  setIsButtonDisabled: Function;
}> = ({ setIsButtonDisabled }) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'bulkUploadStep',
  });
  const { manualBulkUploadData, update } = useBetterOrderExperienceContext();
  const dispatchToast = useDispatch();

  useEffect(() => {
    if (manualBulkUploadData?.isValid) {
      setIsButtonDisabled(false);
    }
  }, [manualBulkUploadData, setIsButtonDisabled]);

  const changeHandler = useCallback(file => {
    if (!file) return;

    parseFile(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCsvValidFormat = (headings: string[]) => {
    let isValid = true;
    CsvHeaders.forEach(heading => {
      if (!headings.includes(heading)) isValid = false;
    });

    return isValid;
  };

  const parseFile = (file: File) => {
    parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      complete: result => {
        const data = result.data as ManualBulkUploadRow[];

        if (data.length === 0) {
          dispatchToast(toastError('', t('toastError.unsuccessful')));
          return;
        }

        if (data.length > 300) {
          dispatchToast(toastError('', t('toastError.rowsExceeded')));
          return;
        }

        if (!isCsvValidFormat(Object.keys(data[0]))) {
          dispatchToast(toastError('', t('toastError.invalidColumns')));
          return;
        }

        if (getRowsWithErrors(data).length === data.length) {
          dispatchToast(toastError('', t('toastError.validationFailed')));
          return;
        }

        setIsButtonDisabled(false);
        update({
          manualBulkUploadData: {
            fileName: file?.name,
            fileSize: file?.size,
            parsedData: data,
            rowsWithError: getRowsWithErrors(data),
            isValid: getRowsWithErrors(data).length === 0,
          },
          emails: data.map(row => row.email) as string[],
        });
      },
    });
  };

  const onDelete = useCallback(() => {
    update({
      manualBulkUploadData: {} as BulkUploadT.ManualBulkUploadData,
    });
    setIsButtonDisabled(true);
  }, [setIsButtonDisabled, update]);

  const viewList = useCallback(
    (e: any) => {
      e.preventDefault();
      const { parsedData, fileName, rowsWithError } = manualBulkUploadData;
      const errors: ManualBulkUploadRow[] = [];
      rowsWithError.forEach(rowIndex => {
        errors.push(parsedData[rowIndex - 1]);
      });

      const blob = new Blob([unparse(errors)]);
      downloadBlobAsFile({
        data: blob,
        headers: {
          'content-type': 'application/CSV',
        },
        filename: `${fileName.slice(0, -4)}-errors.csv`,
      });
    },
    [manualBulkUploadData],
  );

  return (
    <>
      <M.Grid>
        <M.GridRow>
          <StyledGridCol lg={8}>
            <FileUploadInput
              onUpload={changeHandler}
              onDelete={onDelete}
              manualBulkUploadData={manualBulkUploadData}
            />
          </StyledGridCol>
        </M.GridRow>
      </M.Grid>
      {manualBulkUploadData.rowsWithError?.length > 0 && (
        <StyledInlineNotification kind='error' hideCloseButton>
          <p>
            <strong>{t('formattingErrors')}</strong>{' '}
            {showLinesWithError(manualBulkUploadData.rowsWithError)}
            {t('correctTheseErrors')}
          </p>
          <StyledDownloadLink onClick={viewList} download>
            {t('viewCompleteList')}
          </StyledDownloadLink>
        </StyledInlineNotification>
      )}
    </>
  );
};

export default Upload;
