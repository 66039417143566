import React, { useMemo, useState } from 'react';
import { FetchPackageType, PostPackageType } from '@dashboard-experience/utils';
import PackageChoiceCard from './PackageChoiceCard/PackageChoiceCard';
import { StyledRecommendPackagesContainer } from './RecommendedPackages.styles';
import { DEFAULT_PACKAGE_NAMES } from '../../locales';
import {
  isAvailableForManualBulk,
  getHighestPricedPackageName,
} from '../SelectPackageUtils';

type RecommendedPackagesProp = {
  fetchedRecommendedPackages: FetchPackageType[];
  showPackagePrice?: boolean;
  isManualBulkOrder: boolean;
  update: Function;
  selectedPackage: PostPackageType;
};

const RecommendedPackages: React.FC<RecommendedPackagesProp> = ({
  fetchedRecommendedPackages,
  showPackagePrice,
  isManualBulkOrder,
  update,
  selectedPackage,
}) => {
  const [isAllCustom, setIsAllCustom] = useState(false);
  const selectedPackageName = getHighestPricedPackageName(
    fetchedRecommendedPackages,
    isManualBulkOrder,
    isAvailableForManualBulk,
  );

  const selectedPackagePrice = fetchedRecommendedPackages.find(
    pkg => pkg.name === selectedPackageName,
  )?.price;
  const essentialPackagePrice = fetchedRecommendedPackages.find(
    pkg => pkg.name === 'Essential',
  )?.price;
  const hideMostPopular =
    selectedPackagePrice && essentialPackagePrice
      ? selectedPackageName !== 'Essential' &&
        selectedPackagePrice > essentialPackagePrice
      : false;

  useMemo(() => {
    setIsAllCustom(
      fetchedRecommendedPackages.every(pkg => {
        return !DEFAULT_PACKAGE_NAMES.includes(pkg.name);
      }),
    );
  }, [fetchedRecommendedPackages]);

  return (
    <StyledRecommendPackagesContainer>
      {fetchedRecommendedPackages.map(
        (recommendedPackage: FetchPackageType, idx: number) => (
          <PackageChoiceCard
            key={recommendedPackage.id}
            packageData={recommendedPackage}
            selected={
              !isManualBulkOrder ||
              isAvailableForManualBulk(recommendedPackage, isManualBulkOrder)
                ? selectedPackageName === recommendedPackage.name
                : false
            }
            showPackagePrice={showPackagePrice}
            idx={idx}
            hideMostPopularTag={hideMostPopular}
            isManualBulkOrder={isManualBulkOrder}
            isAllCustom={isAllCustom}
            update={update}
            selectedPackage={selectedPackage}
          />
        ),
      )}
    </StyledRecommendPackagesContainer>
  );
};

export default RecommendedPackages;
