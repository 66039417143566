import React, { FC } from 'react';
import { BetaState, useBetaState, useShowBeta } from 'providers/Beta';
import { ToggleBanner, ToggleStates } from './ToggleBanner';

const BetaBanner: FC<{
  flagKey: string;
  translationKey: string;
  learnMoreLink?: string;
}> = ({ flagKey, translationKey, learnMoreLink }) => {
  const betaState = useBetaState(flagKey);
  const showBeta = useShowBeta(flagKey);
  const betaTranslationKey = `beta.${translationKey}.banner`;

  if (betaState === BetaState.DISABLED) return null;

  return (
    <ToggleBanner
      toggleState={
        betaState === BetaState.OPT_OUT
          ? ToggleStates.OPT_OUT
          : ToggleStates.DEFAULT
      }
      learnMoreLink={learnMoreLink}
      toggle={showBeta}
      translationKey={betaTranslationKey}
      buttonPendoId='candidate-beta-switch-to-beta'
    />
  );
};

export default BetaBanner;
