import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledDownloadLink = styled(M.Link)`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 0.5rem;
`;

export const StyledGridCol = styled(M.GridCol)`
  padding: 0;
`;

export const StyledInlineNotification = styled(M.InlineNotification)`
  p {
    margin-bottom: 0 !important;
  }
`;
