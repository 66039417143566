import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const CSV_TEMPLATE =
  'https://us-east-1-checkr.s3.amazonaws.com/assets/csv_uploads/candidate-info-csv-template.csv';
export const CSV_EXAMPLE =
  'https://us-east-1-checkr.s3.amazonaws.com/assets/csv_uploads/example-manual-candidate-info.csv';

export const StyledContainer = styled.div`
  width: 42.5rem;
`;

export const StyledStepTitle = styled.h4`
  margin: 2rem 0 1rem !important;
`;

export const StyledStepContent = styled.div`
  margin-bottom: 1rem;
`;

export const StyledSecondaryBtn = styled(M.Button)`
  margin-right: 0 !important;

  .mastodon-icon {
    margin-right: 0.5rem;
  }
`;
