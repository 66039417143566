import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import find from 'lodash/find';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import * as BetterOrderExperience from 'components/BetterOrderExperience';
import { STEPS } from 'components/BetterOrderExperience/utils/constants';
import { Breadcrumbs, BreadcrumbT } from 'components/BetterOrderExperience';
import AccountProvider from '../Account/AccountProvider';
import BetterOrderExperienceProvider from './Provider';
import BetterOrderExperienceContext from './context';

const BetterOrderExperiencePage: React.FC<{}> = () => {
  const [currentStep, setCurrentStep] = useState<BreadcrumbT.Step>(
    {} as BreadcrumbT.Step,
  );
  const { t } = useTranslation('better_order_experience');
  const { contextId } = useContext(UIContext);
  const history = useHistory();

  const { selectedPackage } = useContext(BetterOrderExperienceContext);

  const redirectToStep = useCallback(() => {
    if (contextId) {
      updateParentWindowUrl({
        path: STEPS.GET_STARTED.path,
        contextId,
        reload: true,
      });
    } else {
      history.push(STEPS.GET_STARTED.path);
    }
  }, [contextId, history]);

  useEffect(() => {
    const path = window.location.pathname;

    // If no base package was selected, redirect back to first step
    if (!selectedPackage?.name) {
      redirectToStep();
      setCurrentStep(STEPS.GET_STARTED);
    }

    const matchingStep = find(STEPS, step => step.path === path);
    setCurrentStep(matchingStep as BreadcrumbT.Step);
  }, [selectedPackage?.name, history, redirectToStep]);

  return (
    <AccountProvider>
      <BetterOrderExperienceProvider>
        <h1 data-testid='order-background-check-header'>{t('heading')}</h1>
        <Breadcrumbs currentStep={currentStep} />
        <Switch>
          <Route exact path={STEPS.GET_STARTED.path}>
            <BetterOrderExperience.GetStartedStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.SELECT_YOUR_PACKAGE.path}>
            <BetterOrderExperience.SelectPackageStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.ADD_ONS.path}>
            <BetterOrderExperience.AddonsStep setCurrentStep={setCurrentStep} />
          </Route>

          <Route exact path={STEPS.UPLOAD_CANDIDATE_INFO.path}>
            <BetterOrderExperience.BulkUploadStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.REVIEW_AND_SUBMIT.path}>
            <BetterOrderExperience.ReviewAndSubmitStep
              setCurrentStep={setCurrentStep}
            />
          </Route>

          <Route exact path={STEPS.MANUAL_ENTRY.path}>
            <BetterOrderExperience.ManualEntryStep />
          </Route>
        </Switch>
      </BetterOrderExperienceProvider>
    </AccountProvider>
  );
};

export default BetterOrderExperiencePage;
