import * as AddonUtils from 'components/AddScreenings/shared/utils';
import type { AddonsT } from '../AddonsStep';
import { ScreeningProductType } from '../AddonsStep/AddonsStep.enums';

export const getDollarAmount = (val: number = 0) =>
  (val / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const getPricesByScreeningType = (
  addOns: AddonsT.AddonPrice[],
  productType: ScreeningProductType,
  subtype?: string,
  lookbackYears?: number,
) => {
  if (addOns.length === 0) return 'Loading price...';

  const matchingAddons = addOns.filter((addon: AddonsT.AddonPrice) => {
    if (subtype)
      return addon.product_type === productType && addon.subtype === subtype;
    if (lookbackYears) {
      return (
        addon.product_type === productType &&
        addon.lookback_years === lookbackYears
      );
    }
    return addon.product_type === productType;
  });

  if (matchingAddons.length === 0) return 'Price varies';

  // We are currently grabbing the first in the list, as it currently matches the sku
  // Any changes to the SKU that we list will need to be reflected here.
  return AddonUtils.getDollarAmount(matchingAddons[0]?.base_price.toString());
};

export const getDisplayPrice = (price: number | undefined | null) => {
  if (price === undefined || price === null) return 'Loading price...';
  if (price === 0 || isNaN(price)) return 'Price varies';

  return getDollarAmount(price);
};

export type AddonPricesQueryData = {
  service_fee_range: [number, number];
  passthrough_fee_range: [number, number];
};

const DefaultQueryData: AddonPricesQueryData = {
  service_fee_range: [0, 0],
  passthrough_fee_range: [0, 0],
};

type ServiceAndPassthroughFeesProps = {
  addonPricesQueryData?: AddonPricesQueryData;
};

export const getServiceAndPassthroughFees = ({
  addonPricesQueryData = DefaultQueryData,
}: ServiceAndPassthroughFeesProps) => {
  return {
    serviceFeeMin: addonPricesQueryData?.service_fee_range[0] || 0,
    serviceFeeMax: addonPricesQueryData?.service_fee_range[1] || 0,
    passthroughFeeMin: addonPricesQueryData?.passthrough_fee_range[0] || 0,
    passthroughFeeMax: addonPricesQueryData?.passthrough_fee_range[1] || 0,
  };
};
