import React, { useCallback } from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { SIGNUP_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { ESSENTIAL_SCREENINGS } from 'components/Signup/constants/screenings';

import PackageCard from '../../PackageCard';
import ScreeningsListItem from '../ScreeningsListItem';

const EssentialPackageCard: React.FC<PackageCardProps> = ({
  selectedPackage,
  setSelectedPackage,
  showCompleteText,
}) => {
  const trackEvent = useTrackEvent();

  const handlePackageSelect = useCallback(() => {
    trackEvent(SIGNUP_EVENT_NAMES.SIGNUP_PACKAGE_CARD_CLICKED, {
      'Package Selected': 'Essential',
      'Self Serve Signup Version': 'FOX Signup v2',
    });
    setSelectedPackage('checkrdirect_essential_criminal');
  }, [setSelectedPackage, trackEvent]);

  return (
    <PackageCard
      name='Essential'
      description='Enhanced criminal search for expanded results'
      price={5499}
      packageType='checkrdirect_essential_criminal'
      mostPopular
      selected={selectedPackage === 'checkrdirect_essential_criminal'}
      onClick={handlePackageSelect}
      showCompleteText={showCompleteText}
    >
      <>
        {ESSENTIAL_SCREENINGS.map(screeningName => (
          <ScreeningsListItem
            key={screeningName}
            screeningName={screeningName}
          />
        ))}
      </>
    </PackageCard>
  );
};

export default EssentialPackageCard;
