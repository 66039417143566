import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSection, StyledSectionName } from '../OrderSummary.styles';
import { namespace } from '../../../locales';
import { AddonsT } from '../..';
import CustomizationScreeningItem from './CustomizationScreeningItem';
import { StyledScreeningList } from './CustomizationScreeningItem.styles';

const CustomizationsSection: React.FC<{
  addedScreeningTypeWithPrices: AddonsT.ScreeningTypeWithPrices[];
  additionalProperties: AddonsT.AdditionalProperties;
}> = ({ addedScreeningTypeWithPrices, additionalProperties }) => {
  const { t } = useTranslation(namespace);

  return (
    <StyledSection>
      <StyledSectionName>{t('orderSummary.customizations')}</StyledSectionName>
      <StyledScreeningList data-testid='screening-list'>
        {addedScreeningTypeWithPrices.map(item => (
          <CustomizationScreeningItem
            key={item.price}
            addedScreeningTypeWithPrices={item}
            additionalProperties={additionalProperties}
          />
        ))}
      </StyledScreeningList>
    </StyledSection>
  );
};

export default CustomizationsSection;
