import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, M } from '@dashboard-experience/mastodon';

type Row = {
  text: string;
  age: string;
  time: string;
};

const StyledCharges = styled.div`
  padding: 30px;

  p {
    color: ${colors.uiGrey500};
    margin: 0;
    padding: 0;
  }

  .cds--data-table-container {
    padding-top: 1rem;
  }

  .mastodon-table-head {
    border-bottom: none !important;
    border-top: none;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    white-space: nowrap;
  }

  .cds--data-table tr {
    height: 20px;
    border-bottom: none !important;
  }

  .mastodon-table td {
    padding: 0;
  }

  .cds--data-table th {
    color: ${colors.uiGrey600};
    padding-left: 0;
  }
`;

const headerKeys = ['charge', 'age', 'time'];

const ChargeRow: React.FC<{ row: Row }> = ({ row }) => {
  const { text, age, time } = row;
  return (
    <M.TableRow data-testid='charge-row'>
      <M.TableCell data-testid='charge-text'>{text}</M.TableCell>
      <M.TableCell data-testid='charge-age'>{age}</M.TableCell>
      <M.TableCell data-testid='charge-time'>{time}</M.TableCell>
    </M.TableRow>
  );
};

const ChargesTable: React.FC<{ rows: Row[]; displayContextDate: boolean }> = ({
  rows,
  displayContextDate,
}) => {
  const { t } = useTranslation();
  const optionalFCI = 'adjudication:report.actions.pre_adverse.optional_fci';

  const headers = headerKeys.map((key) => {
    const context =
      displayContextDate && key !== 'charge' ? 'dynamic' : 'default';
    const name = t(`${optionalFCI}.charges_table.headers.${key}.${context}`);
    return {
      key,
      name,
    };
  });

  const contextDateInfo = (
    <div data-testid='context-date-info'>
      <br />
      <M.Link
        href='https://help.checkr.com/helpcenter/s/article/4403124123031-Checkr-terms-and-definitions#contextdate'
        target='_blank'
      >
        {t(`${optionalFCI}.learn_more`)}
      </M.Link>{' '}
      <span>{t(`${optionalFCI}.context_dates_info`)}</span>
    </div>
  );

  return (
    <StyledCharges>
      <strong>{t(`${optionalFCI}.selected_criminal_history.title`)}</strong>
      <p>
        {t(`${optionalFCI}.selected_criminal_history.body`)}{' '}
        {displayContextDate && contextDateInfo}
      </p>
      <M.TableContainer>
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              {headers.map((header) => (
                <M.TableHeader
                  key={header.key}
                  data-testid={`charge-header-${header.key}`}
                >
                  {header.name}
                </M.TableHeader>
              ))}
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {rows.map((row) => (
              <ChargeRow key={row.text} row={row} />
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </StyledCharges>
  );
};

export default ChargesTable;
