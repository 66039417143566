import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
`;

export const StyledHeaderTiles = styled.div<{ alignRight?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  min-width: 276px;
  color: ${colors.uiTextPrimaryLight};

  text-align: ${props => (props?.alignRight ? 'right' : 'start')};
  &:nth-child(2) {
    margin-right: auto; /* Pushes second item towards the first */
  }
`;

export const StyledPackageHeader = styled(StyledHeaderTiles)`
  @media (max-width: 940px) {
    display: none;
  }
`;

export const StyledMobilePackageHeader = styled(StyledHeaderTiles)`
  display: none;

  @media all and (max-width: 940px) {
    display: flex;
  }
`;

export const StyledScreeningsHeader = styled(StyledHeaderTiles)`
  width: 100%;
  padding-left: 24px;
  min-width: unset;
  @media all and (max-width: 1156px) {
    display: none;
  }
`;

export const StyledPriceHeader = styled(StyledHeaderTiles)`
  width: 100%;
  min-width: unset;
  @media all and (max-width: 540px) {
    display: none;
  }
`;
