import React, { useCallback, useEffect, useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'modules/candidate/locales';
import { useTranslation } from 'react-i18next';
import { useUpdateAccountSettingsWithoutDispatch } from 'components';
import { useSettings, useUpdateSettings } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { useTrackEvent, REPORT_SETTING_EVENT_NAMES } from 'utils/analytics';

const ShowReportSummary: React.FC = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const { account } = user;
  const { call: updateAccountCall } = useUpdateAccountSettingsWithoutDispatch();
  const { t } = useTranslation();

  const title = t(
    `${namespace}:report.settings.menu.results-page.show-report-summary`,
  );
  const { hideReportSummary } = useSettings();

  const [isHideReportSummary, setIsHideReportSummary] =
    useState(hideReportSummary);

  useEffect(() => {
    setIsHideReportSummary(hideReportSummary);
  }, [hideReportSummary]);

  const updateSettings = useUpdateSettings();

  const toggleReportSummary = useCallback(() => {
    const newValue = !isHideReportSummary;
    const changedSettings = { hide_report_summary: newValue };
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_REPORT_VIEW_SHOW_REPORT_SUMMARY_CLICKED,
      {
        'Show Report Summary Clicked': isHideReportSummary,
      },
    );

    updateAccountCall({ changedSettings, account });

    updateSettings({ hideReportSummary: newValue });

    setIsHideReportSummary(newValue);
  }, [
    isHideReportSummary,
    trackEvent,
    updateAccountCall,
    account,
    updateSettings,
  ]);

  return (
    <M.ActionMenuItem
      title={title}
      kind='toggle'
      initialChecked={!isHideReportSummary}
      onToggle={toggleReportSummary}
      description={t(
        `${namespace}:report.settings.menu.results-page.show-report-summary-description`,
      )}
    />
  );
};

export default ShowReportSummary;
