import { CreateCandidateParams, CandidateContactInfo } from 'api/invitations';
import {
  accountHasPermission,
  CurrentUser,
  Geo,
  PostPackageType,
} from '@dashboard-experience/utils';
import { BillingEntity } from 'types/Billing';
import { UploadCSVPayload } from '../../../api/packages';
import {
  BULK_INVITE_CSV_STORAGE_PATH,
  Invite,
} from '../../Packages/OrderBackgroundCheck/SharedItems';
import { getCSVContent } from '../../Packages/OrderBackgroundCheck/UploadCandidateInfo/helper';
import { SelectionProps } from './ManualEntryStepUtils';
import type { GetStartedT } from '../GetStartedStep';
import type { BulkUploadT } from '../BulkUploadStep';
import { ManualBulkUploadData } from '../BulkUploadStep/BulkUploadStep.types';
import type { AddonsT } from '../AddonsStep';

export const formatLocation = (location: GetStartedT.WorkLocation) => {
  (Object.keys(location) as (keyof typeof location)[]).forEach(key => {
    if (location[key] == null) {
      delete location[key];
    }
  });
  return location;
};

export const buildInvitationParameters = (invites: Invite[]) => {
  const invitation_parameters: Array<CandidateContactInfo> = [];
  invites.forEach(i => {
    if (i.email.isValid && i.firstName.isValid && i.phoneNumber.isValid) {
      invitation_parameters.push({
        email: i.email.value,
        first_name: i.firstName.value,
        phone_number: i.phoneNumber.value,
      });
    }
  });
  return invitation_parameters;
};

type CheckPermissionsParams = {
  currentUser: CurrentUser;
  setGeoError: (value: boolean) => void;
  setPermissionError: (value: boolean) => void;
  geoRequired: boolean;
  geo: Geo | null;
  segmentationEnabled: boolean;
};

export const checkPermissions = ({
  currentUser,
  setGeoError,
  setPermissionError,
  geoRequired,
  geo,
  segmentationEnabled,
}: CheckPermissionsParams) => {
  const unauthorized =
    !accountHasPermission(currentUser, 'authorized') &&
    !accountHasPermission(currentUser, 'api_authorized') &&
    currentUser?.account?.credentialed_at;

  if (unauthorized) {
    setPermissionError(true);
    return false;
  }

  if (geoRequired && !segmentationEnabled && !geo) {
    setGeoError(true);
    return false;
  }

  return true;
};

type CreatePayloadParams = {
  emails: string[];
  selection: SelectionProps;
  payment_profile?: BillingEntity;
  invites: Invite[] | undefined;
  segmentationEnabled: boolean;
  hierarchyPresent: boolean;
};

export const createPayload = ({
  emails,
  selection,
  payment_profile,
  invites,
  segmentationEnabled,
  hierarchyPresent,
}: CreatePayloadParams): Partial<CreateCandidateParams> => {
  const {
    node,
    geo,
    program,
    purpose,
    country,
    state,
    city,
    package: pkg,
  } = selection;

  const payload: Partial<CreateCandidateParams> = {
    emails: emails.join(',').replace(/,\$/, ''),
    package: pkg.slug,
    invited_from: 'dashboard',
    billing_entity: payment_profile?.id,
  };

  if (geo && geo.id !== 'None') payload.geo_id = geo.id;
  if (program) payload.program_id = program.id;
  if (purpose) payload.permissible_purpose = purpose;

  if (invites) {
    const invitationParams = buildInvitationParameters(invites);
    if (invitationParams.length > 0) {
      payload.invitation_parameters = invitationParams;
    }
  }

  if (segmentationEnabled) {
    if (hierarchyPresent) payload.node = node?.custom_id;
    const location = formatLocation({
      country,
      state,
      city,
    } as GetStartedT.WorkLocation);
    payload.work_locations = [location];
  } else if (geo?.country && geo.country !== 'US') {
    const location = formatLocation({
      country: geo.country,
      state: geo.state,
      city: geo.city,
    } as GetStartedT.WorkLocation);
    payload.work_locations = [location];
  }

  return payload;
};

type HandleBulkInviteParams = {
  csv: { fileStorageKey: string; fileDisplayName: string };
  currentUser: CurrentUser;
  payload: Partial<CreateCandidateParams>;
  addons: AddonsT.ScreeningType[];
  uploadCall: (payload: UploadCSVPayload) => void;
  selectedPackage: PostPackageType;
};

export const handleBulkInvite = ({
  csv,
  currentUser,
  payload,
  addons,
  uploadCall,
  selectedPackage,
}: HandleBulkInviteParams) => {
  const { emails, ...modifiedPayload } = payload;

  const uploadPayload: UploadCSVPayload = {
    accountId: currentUser?.account?.id,
    uploaded_list_type: BULK_INVITE_CSV_STORAGE_PATH,
    raw_csv_file_s3_path: csv.fileStorageKey,
    upload_params: {
      addons,
      fileDisplayName: csv.fileDisplayName,
      ...modifiedPayload,
      package: selectedPackage as unknown as string,
    },
  };

  uploadCall(uploadPayload);
};

type HandleManualBulkOrderParams = {
  manualBulkUploadData: BulkUploadT.ManualBulkUploadData;
  geo: Geo | null;
  node: GetStartedT.Node | null;
  pkg: PostPackageType;
  currentUser: CurrentUser;
  manualBulkCall: (params: any) => void;
  payload: Partial<CreateCandidateParams>;
};

type ManualBulkCallParams = {
  bulk_apply_csv: string;
  geo_id: string;
  order: string;
  account_id: string;
  node: string | undefined;
  work_locations: any[] | undefined;
  package_resource_id: string | undefined;
  config_options: {
    company: string;
    zipcode: string;
    order: string;
  };
};

export const handleManualBulkOrder = ({
  manualBulkUploadData,
  geo,
  node,
  pkg,
  currentUser,
  manualBulkCall,
  payload,
}: HandleManualBulkOrderParams) => {
  const params: ManualBulkCallParams = {
    bulk_apply_csv: window.btoa(getCSVContent(manualBulkUploadData.parsedData)),
    geo_id: geo?.id ?? '',
    order: currentUser.id,
    account_id: currentUser?.account?.id,
    node: node?.custom_id,
    work_locations: payload.work_locations,
    package_resource_id: pkg.source_version_id,
    config_options: {
      company: currentUser.account?.company?.name,
      zipcode: currentUser.account?.company?.zipcode,
      order: currentUser.id,
    },
  };

  manualBulkCall(params);
};

type CallProps = {
  payload: Partial<CreateCandidateParams>;
  bulkInviteEnabled: boolean;
};

export type CreateInvitationParams = {
  selection: SelectionProps;
  emails: string[];
  csv: GetStartedT.BulkInviteCSV;
  call: (params: CallProps) => void;
  uploadCall: (payload: UploadCSVPayload) => void;
  setGeoError?: (value: boolean) => void;
  setPermissionError?: (value: boolean) => void;
  currentUser: CurrentUser;
  bulkInviteEnabled: boolean;
  addons: AddonsT.ScreeningType[];
  createCandidateInvitation?: any;
  manualBulkOrderEnabled?: boolean;
  manualBulkCall?: (params: ManualBulkCallParams) => void;
  manualBulkUploadData?: BulkUploadT.ManualBulkUploadData;
  invites?: Invite[];
};

const createInvitation = ({
  selection,
  emails,
  csv,
  call = () => {},
  uploadCall = () => {},
  setGeoError = () => {},
  setPermissionError = () => {},
  currentUser,
  bulkInviteEnabled,
  addons,
  createCandidateInvitation,
  manualBulkOrderEnabled,
  manualBulkCall = () => {},
  manualBulkUploadData = {} as ManualBulkUploadData,
  invites,
}: CreateInvitationParams) => {
  const { account } = currentUser;

  const geoRequired = account?.geos_required;
  const segmentationEnabled = account?.segmentation_enabled;
  const hierarchyPresent = account?.hierarchy_present;

  if (
    !checkPermissions({
      currentUser,
      setGeoError,
      setPermissionError,
      geoRequired,
      geo: selection.geo,
      segmentationEnabled,
    })
  ) {
    return;
  }

  const payload = createPayload({
    emails,
    selection,
    payment_profile: selection.payment_profile,
    invites,
    segmentationEnabled,
    hierarchyPresent,
  });

  if (bulkInviteEnabled) {
    handleBulkInvite({
      csv,
      currentUser,
      payload,
      addons,
      uploadCall,
      selectedPackage: selection.package, // TODO
    });
  } else if (manualBulkOrderEnabled) {
    handleManualBulkOrder({
      manualBulkUploadData,
      geo: selection.geo,
      node: selection.node,
      pkg: selection.package,
      currentUser,
      manualBulkCall,
      payload,
    });
  } else {
    createCandidateInvitation
      ? call(createCandidateInvitation)
      : call({ payload, bulkInviteEnabled });
  }
};

export default createInvitation;
