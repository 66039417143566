import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { getDashboardUrl, updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import { useManualBulkOrder, useUploadCSV } from 'api/packages';
import { useCreateCandidate } from 'api/invitations';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { useFlag } from '@dashboard-experience/react-flagr';
import { isAddOnsEnabled } from 'components/Packages/shared/utils';
import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import { toastError } from 'actions';
import { useDispatch } from 'react-redux';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import { INVITE_PAGE_ADD_SCREENINGS } from 'Flags';
import { Footer, StepContainer } from '../ui';
import { getGeoWorkLocation, getWorkLocation } from './ReviewAndSubmitUtils';
import { namespace } from '../locales';
import { InfoSection } from './InfoSection';
import { SelectedPackageInfoSection } from './SelectedPackageInfoSection';
import { CandidateInfoSection } from './CandidateInfoSection';
import { StyledInfoText } from './CandidateInfoSection/CandidateInfoSection.styles';
import { PricingSection } from './PricingSection';
import {
  StyledLink,
  StyledOrderCertifiedCheckbox,
  StyledOrderCertifiedText,
  StyledSubtitle,
} from './ReviewAndSubmitStep.styles';
import { CustomizationsInfoSection } from './CustomizationsInfoSection';
import createInvitation, {
  CreateInvitationParams,
} from '../utils/createInvitation';
import { SubmitModal } from './SubmitModal';
import ReviewAndSubmitSubtitle from './ReviewAndSubmitSubtitle';

const ReviewAndSubmitStep: React.FC<StepProps> = () => {
  const isAddOnsFlagEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';
  const { t } = useTranslation(namespace, { keyPrefix: 'reviewAndSubmit' });
  const toastDispatch = useDispatch();

  const [orderCertified, setOrderCertified] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const history = useHistory();
  const currentUser = useUser();
  const {
    account: { segmentation_enabled },
  } = currentUser;

  const {
    geo,
    location,
    manualOrderType,
    infoMethod,
    infoSource,
    localeType,
    selectedPackage,
    newlyCreatedPackage,
    node,
    program,
    paymentProfile,
    emails,
    invites,
    manualBulkUploadData,
    csv,
    addedScreeningTypeWithPrices,
    update,
  } = useBetterOrderExperienceContext();

  const workLocationValue = !segmentation_enabled
    ? getGeoWorkLocation(geo)
    : getWorkLocation(location);

  const { call: createCandidateCall, result: createCandidateResult } =
    useCreateCandidate();
  const { uploadCall, uploadResult } = useUploadCSV();
  const { call: manualBulkCall, result: manualBulkResult } =
    useManualBulkOrder();

  const hasCandidateInfo =
    manualOrderType === 'MULTIPLE' || infoSource === 'CANDIDATE';

  const showPrices =
    accountHasPermission(currentUser, 'show_package_price') &&
    currentUser?.account?.package_price_state !== 'disabled via partner' &&
    localeType !== 'INTERNATIONAL';

  const { contextId } = useContext(UIContext);

  const closeModal = useCallback(() => {
    setShowSuccessModal(false);
  }, []);

  const goToAddOns = useCallback(() => {
    const path = '/order-experience/add-ons';

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const goToGetStarted = useCallback(() => {
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleSelectedPackageEdit = useCallback(() => {
    const path = '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [contextId, history]);

  const handleOrderCertifiedClick = useCallback(() => {
    setOrderCertified(!orderCertified);
  }, [orderCertified]);

  const handleBack = useCallback(() => {
    const requiresFMCSACompliance =
      selectedPackage?.requires_fmcsa_compliance || false;

    const hasManageAddOnsPermission = hasPermission(
      currentUser,
      'manage_order_add_ons',
    );

    const addOnsEnabled = isAddOnsEnabled(
      isAddOnsFlagEnabled,
      requiresFMCSACompliance,
      hasManageAddOnsPermission,
    );

    let path = '';

    if (manualOrderType === 'MULTIPLE') {
      path = '/order-experience/bulk-upload';
    } else {
      path = addOnsEnabled
        ? '/order-experience/add-ons'
        : '/order-experience/select-your-package';
    }
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
  }, [
    contextId,
    currentUser,
    history,
    isAddOnsFlagEnabled,
    manualOrderType,
    selectedPackage?.requires_fmcsa_compliance,
  ]);

  const submitOrder = useCallback(() => {
    setIsSubmitting(true);
    const manualBulkOrderEnabled = manualOrderType === 'MULTIPLE';
    const bulkInviteEnabled: boolean = !!csv.fileStorageKey;

    if (infoSource === 'MYSELF' && !manualBulkOrderEnabled) {
      const path = '/order-experience/manual-entry';
      if (contextId) {
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      } else {
        history.push(path);
      }
      return;
    }

    const packageForSelection = newlyCreatedPackage?.slug
      ? newlyCreatedPackage
      : selectedPackage;

    const selection = {
      node,
      geo,
      program,
      country: location.country,
      city: location.city,
      state: location.state,
      package: { ...packageForSelection, aliases_enabled: 'off' }, // TODO alias
      payment_profile: paymentProfile,
    };

    const addons = addedScreeningTypeWithPrices.map(screeningType => {
      return screeningType.screening.type;
    });

    createInvitation({
      selection,
      emails,
      csv,
      call: createCandidateCall,
      uploadCall,
      currentUser,
      bulkInviteEnabled,
      addons,
      manualBulkOrderEnabled,
      manualBulkCall,
      manualBulkUploadData,
      invites,
    } as CreateInvitationParams);
  }, [
    addedScreeningTypeWithPrices,
    contextId,
    createCandidateCall,
    csv,
    currentUser,
    emails,
    geo,
    history,
    infoSource,
    invites,
    location.city,
    location.country,
    location.state,
    manualBulkCall,
    manualBulkUploadData,
    manualOrderType,
    newlyCreatedPackage,
    node,
    paymentProfile,
    program,
    selectedPackage,
    uploadCall,
  ]);

  const handleSuccessfulSubmit = useCallback(() => {
    setIsSubmitting(false);
    setShowSuccessModal(true);
    localStorage.removeItem('betterOrderExperience');
    update({});
  }, [update]);

  useEffect(() => {
    if (uploadResult.isError) {
      setIsSubmitting(false);
      toastDispatch(toastError(`ERROR: ${uploadResult?.error?.message}`));
      return;
    }

    if (uploadResult.isSuccess) {
      handleSuccessfulSubmit();
    }
  }, [handleSuccessfulSubmit, toastDispatch, uploadResult]);

  useEffect(() => {
    if (createCandidateResult.isError) {
      setIsSubmitting(false);
      toastDispatch(
        toastError(`ERROR: ${createCandidateResult?.error?.message}`),
      );
      createCandidateResult.reset();
      return;
    }

    if (createCandidateResult.isSuccess) {
      handleSuccessfulSubmit();

      createCandidateResult.reset();
    }
  }, [createCandidateResult, handleSuccessfulSubmit, toastDispatch]);

  useEffect(() => {
    if (manualBulkResult.isError) {
      setIsSubmitting(false);
      toastDispatch(toastError(`ERROR: ${manualBulkResult?.error?.message}`));
      return;
    }

    if (manualBulkResult.isSuccess) {
      handleSuccessfulSubmit();

      manualBulkResult.reset();
    }
  }, [handleSuccessfulSubmit, manualBulkResult, toastDispatch]);

  const submitButtonDisabled = !orderCertified || isSubmitting;

  useEffect(() => {
    if (uploadResult.isSuccess) {
      setShowSuccessModal(true);
    }

    if (createCandidateResult.isSuccess) {
      setShowSuccessModal(true);
      createCandidateResult.reset();
    }

    if (manualBulkResult.isSuccess) {
      setShowSuccessModal(true);
      manualBulkResult.reset();
    }
  }, [createCandidateResult, manualBulkResult, uploadResult.isSuccess]);

  const bulkOrderHistoryUrl = hasPermission(
    currentUser,
    'read_account_invoices',
  )
    ? getDashboardUrl(`/billing/bulk-history`)
    : getDashboardUrl(`/bulk-order-history`);

  return (
    <StepContainer data-testid='review-and-submit-step-container'>
      <h4>{t('header')}</h4>
      <StyledSubtitle>
        {t('subtitle')}{' '}
        <ReviewAndSubmitSubtitle
          infoSource={infoSource}
          manualOrderType={manualOrderType}
          infoMethod={infoMethod}
          bulkHistoryUrl={bulkOrderHistoryUrl}
        />
      </StyledSubtitle>

      {showSuccessModal && (
        <SubmitModal
          currentUser={currentUser}
          handleClose={closeModal}
          contextId={contextId}
          open={showSuccessModal}
          csv={csv}
          manualOrderType={manualOrderType}
        />
      )}
      {hasCandidateInfo && (
        <>
          <StyledInfoText>{t(`infoSection.candidateInfoText`)}</StyledInfoText>
          <InfoSection section='candidateInfo' onEditClick={goToGetStarted}>
            <CandidateInfoSection />
          </InfoSection>
        </>
      )}
      {workLocationValue && (
        <InfoSection section='workLocation' onEditClick={goToGetStarted}>
          {workLocationValue}
        </InfoSection>
      )}
      <InfoSection
        section='selectedPackage'
        onEditClick={handleSelectedPackageEdit}
      >
        <SelectedPackageInfoSection
          selectedPackage={selectedPackage}
          newCreatedPackageName={newlyCreatedPackage?.name}
        />
      </InfoSection>
      {addedScreeningTypeWithPrices.length > 0 && (
        <InfoSection section='customizations' onEditClick={goToAddOns}>
          <CustomizationsInfoSection
            addOns={addedScreeningTypeWithPrices}
            showPrices={showPrices}
          />
        </InfoSection>
      )}
      {showPrices && <PricingSection />}
      <StyledOrderCertifiedCheckbox
        id='review-and-submit-order-legal-check'
        data-testid='order-certified-checkbox'
        checked={orderCertified}
        onChange={handleOrderCertifiedClick}
        labelText={
          <StyledOrderCertifiedText>
            {manualOrderType === 'MULTIPLE'
              ? `${t('manualBulkOrderCertified')}`
              : `${t('orderCertified')}`}
            <StyledLink
              data-testid='checkr-services-agreement-link'
              href='https://checkr.com/customer-agreement'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('checkrServiceAgreement')}
            </StyledLink>
          </StyledOrderCertifiedText>
        }
      />
      <Footer>
        <M.Button
          data-testid='review-step-back-btn'
          kind='secondary'
          type='button'
          onClick={handleBack}
        >
          {t('footerButtons.goBack')}
        </M.Button>
        <M.Button
          type='button'
          data-testid='review-submit-order-btn'
          disabled={submitButtonDisabled}
          onClick={submitOrder}
        >
          {t('footerButtons.submitOrder')}
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default ReviewAndSubmitStep;
