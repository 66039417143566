import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { M } from '@dashboard-experience/mastodon';
import { StyledFlex, StyledCheckbox } from '../../AddonsStep.styles';
import { StyledToggleTip } from './MotorVehicleRecordsSection.styles';

type Props = {
  onChange: (e: Event) => void;
  checked: boolean;
};

const CommercialMotorVehicle: React.FC<Props> = ({ onChange, checked }) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.mvrSection.commercialMvr',
  });
  const toggleTipText: string[] = t('toggletip', { returnObjects: true });

  return (
    <StyledFlex>
      <StyledCheckbox
        data-testid='commercial-mvr-checkbox'
        id='commercial-mvr-checkbox'
        name='commercial-mvr-checkbox'
        labelText='Requires commercial MVR'
        checked={checked}
        onChange={onChange}
      />
      <div data-testid='commercial-mvr-tooltip'>
        <StyledToggleTip align='bottom' tabIndex={0}>
          <M.ToggletipButton>
            <M.Icon icon='HelpFilled' />
          </M.ToggletipButton>
          <M.ToggletipContent>
            <div>
              {toggleTipText.map((text: string, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={index}>{text}</p>
              ))}
            </div>
          </M.ToggletipContent>
        </StyledToggleTip>
      </div>
    </StyledFlex>
  );
};

export default CommercialMotorVehicle;
