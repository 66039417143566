/* eslint-disable no-shadow */
import { M, colors } from '@dashboard-experience/mastodon';
import React, { useState, useEffect, useCallback } from 'react';

import { useFlag } from '@dashboard-experience/react-flagr';

import { scrollToTop } from 'utils';

import camelize from 'camelize-ts';
import decamelize from 'decamelize';
import ReactFilestack from 'filestack-react';

import {
  oauthAssetsFilestackKey as filestackKey,
  filestackOptions,
  SHOW_COMMUNICATIONS_SETTINGS_KEY,
} from 'Constants';

import {
  AddressSection,
  AdverseActionEmailCC,
  ConfirmModal,
  FileInputGroup,
  FileInputGroupButtons,
  InputGroupTopAlignedOld as InputGroup,
  InputLabel,
  SubmitInputGroup,
  LogoPlaceholder,
  StyledTitleWrapper,
  StyledTitle,
  StyledSeparator,
} from 'components';

import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import { useFilestackUploadResponse } from 'hooks';
import { CompanyAddress, PostalAddress } from 'types';
import DrivingJurisdictionsContainer from 'components/MotorVehicle/DrivingJurisdictionsContainer';

import { useManageDrivingJurisdictionAccessCodes } from 'components/MotorVehicle/hooks';
import {
  useDeleteAccount,
  useUpdateAccountSettings,
  useUploadError,
} from '../../api/accountSettings/hooks';

import SettingsSchema from './Schema';

type GenericObject = { [key: string]: any };

type AccountSettingsProps = {
  t: (arg0: string) => Promise<string>;
};

const updateSettingsState =
  (setSettingsState: React.Dispatch<React.SetStateAction<any>>) =>
  (key: string, newValue: any) => {
    setSettingsState((prevState: GenericObject) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

const isAccountExists = (account: GenericObject) => {
  return Object.keys(account).length > 0;
};

const getSettings = (account: GenericObject) => {
  const settings = SettingsSchema() as GenericObject;

  if (isAccountExists(account)) {
    Object.keys(settings).forEach(s => {
      settings[s].value = account[s];
    });
  }

  return settings;
};

const sortInputs = (account: GenericObject) => {
  const settings = camelize(getSettings(account));
  const inputs = Object.keys(settings);

  return inputs
    .sort((a, b) => (settings[a].index < settings[b].index ? -1 : 1))
    .map(i => ({ [i]: settings[i] }));
};

const AccountSettingsOld: React.FC<AccountSettingsProps> = ({
  t,
}: GenericObject) => {
  const [modal, setModal] = useState<{
    open: boolean;
    header: string;
    message: string;
    callback: () => void;
  }>({
    open: false,
    header: '',
    message: '',
    callback: () => {},
  });

  const initialPostalAddress: PostalAddress = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
  };

  const initialcompanyAddress: CompanyAddress = {
    name: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
  };

  const [settingsState, setSettingsState] = useState({
    adverseActionEmail: '',
    adverseActionEmailCC: false,
    company: initialcompanyAddress,
    billingEmail: '',
    complianceContactEmail: '',
    logoUri: '',
    name: '',
    postalAddress: initialPostalAddress,
    suppressEmailInvites: false,
    suppressSmsNotifications: false,
    supportEmail: '',
    supportPhone: '',
    technicalContactEmail: '',
    uriName: '',
    disableSettings: false,
  });

  const currentUser = useUser();
  const {
    adverseActionEmailCC,
    company,
    logoUri,
    postalAddress,
    suppressEmailInvites,
    suppressSmsNotifications,
    disableSettings,
  } = settingsState;
  const { account } = currentUser;
  const { id, authorized } = account;

  const { call, result } = useUpdateAccountSettings();
  const processing: any = result.isLoading;
  const [errorMsg, setErrorMsg] = useState<any>({});

  const { callUploadError } = useUploadError();
  const { deleteCall } = useDeleteAccount();

  useEffect(() => {
    setSettingsState(settings => {
      const newSettings: any = { ...settings };

      if (isAccountExists(account)) {
        const settings = getSettings(account);

        Object.keys(settings).forEach(s => {
          newSettings[camelize(s)] = account[s];
        });

        if (account.logo_uri && account.logo_uri !== 'false') {
          newSettings.logoUri = account.logo_uri;
        }

        if (account.suppress_sms_notification) {
          newSettings.suppressSmsNotifications = true;
        }

        if (account.suppress_email_invitation) {
          newSettings.suppressEmailInvites = true;
        }

        if (account.adverse_action_email_cc) {
          newSettings.adverseActionEmailCC = account.adverse_action_email_cc;
        }

        if (account.postal_address) {
          newSettings.postalAddress = account.postal_address;
        }

        if (account.company) {
          newSettings.company = account.company;
        }
      }

      newSettings.disableSettings = !hasPermission(
        currentUser,
        'manage_account_settings',
      );
      return newSettings;
    });
  }, [account, currentUser]);

  const setSettings = useCallback(
    (
      key: string,
      value: string | boolean | string[] | PostalAddress | CompanyAddress,
    ) => {
      updateSettingsState(setSettingsState)(key, value);
    },
    [],
  );

  const updateAddress = (
    newAddress: PostalAddress | CompanyAddress,
    addressType: 'postalAddress' | 'company',
    name: string,
    setSettings: (
      type: 'postalAddress' | 'company',
      address: PostalAddress | CompanyAddress,
    ) => void,
  ) => {
    newAddress.name = name;
    setSettings(addressType, newAddress);
  };

  const handlePostalAddress = useCallback(
    (newAddress: PostalAddress) => {
      updateAddress(
        newAddress,
        'postalAddress',
        settingsState.name,
        setSettings,
      );
    },
    [settingsState.name, setSettings],
  );

  const handleCompanyAddress = useCallback(
    (newAddress: CompanyAddress) => {
      updateAddress(newAddress, 'company', settingsState.name, setSettings);
    },
    [settingsState.name, setSettings],
  );

  const hideModal = useCallback(() => {
    setModal({
      open: false,
      header: '',
      message: '',
      callback: () => {},
    });
  }, [modal]);

  const showModal = useCallback(
    (header: string, message: string, callback: () => void) => {
      setModal({
        open: true,
        header,
        message,
        callback,
      });
      scrollToTop();
    },
    [modal],
  );

  const confirmDelete = useCallback(() => {
    deleteCall(id);
    hideModal();
  }, [deleteCall, hideModal, id]);

  const toggleEmailSetting = (current: boolean) => (_: undefined) => {
    onChange('suppressEmailInvites')({ target: { value: !current } });
  };

  const toggleSmsSetting = (current: boolean) => (_: undefined) => {
    onChange('suppressSmsNotifications')({ target: { value: !current } });
  };

  const onChange = useCallback(
    (key: string) => (event: any) => setSettings(key, event.target.value),
    [setSettings],
  );

  const onFileDelete = useCallback(
    () => setSettings('logoUri', ''),
    [setSettings],
  );

  const onFileUpload = useFilestackUploadResponse({
    filestackKey,
    errorCallback: callUploadError,
    successCallback: file => {
      const staticUrl = file.url;

      return onChange('logoUri')({ target: { value: staticUrl } });
    },
  });

  const confirmDeleteAccount = useCallback(() => {
    showModal(
      'Delete Account',
      "Please confirm that you'd like to delete this account.",
      confirmDelete,
    );
  }, []);

  const confirmNameChange = useCallback(() => {
    updateAccountSettings();
    hideModal();
  }, [account, settingsState, hideModal]);

  const changeName = () => {
    showModal(
      'Change Account Name',
      'Please confirm that you would like to change the name of this account.',
      confirmNameChange,
    );
  };

  const updateAccountSettings = useCallback(() => {
    call(
      { changedSettings: settingsState, account },
      {
        onError: err => {
          setErrorMsg(err.response.data.account);
        },
      },
    );
  }, [account, call, settingsState]);

  const {
    saveAccessCodes,
    setAccessCodesInput,
    accessCodesInput,
    setExistingAccessCodes,
  } = useManageDrivingJurisdictionAccessCodes(
    account?.company?.account_resource_id,
  );

  const onSave = useCallback(async () => {
    await saveAccessCodes();
    setErrorMsg({});

    if (account.name !== settingsState.name) {
      return changeName();
    }

    return updateAccountSettings();
  }, [account, call, settingsState, saveAccessCodes]);

  const filestackUpload = useCallback(
    ({ onPick }: GenericObject) => {
      return (
        <M.Button kind='primary' style={{ margin: '8px' }} onClick={onPick}>
          {t('buttons.upload')}
        </M.Button>
      );
    },
    [t],
  );

  const newCommunicationSettingsEnabled =
    useFlag(SHOW_COMMUNICATIONS_SETTINGS_KEY)?.variantKey === 'on' || false;

  const inputs = Object.assign({}, ...sortInputs(account));

  return (
    <>
      {/* @ts-ignore */}
      <ConfirmModal
        open={modal.open}
        hideModal={hideModal}
        callback={modal.callback}
        header={modal.header}
        message={modal.message}
      />
      <div data-testid='wrap-contact-form'>
        {Object.keys(inputs).map((key: string, i: number) => {
          const inputError: any =
            errorMsg && errorMsg[decamelize(key)]
              ? errorMsg[decamelize(key)].join(' ')
              : false;

          return (
            <InputGroup key={i.toString()}>
              <InputLabel htmlFor={key}>{t(`inputs.${key}`)}</InputLabel>
              <M.Input
                placeholder={t(`inputs.${key}`)}
                disabled={inputs[key].disabled || disableSettings}
                id={key}
                invalid={!!inputError}
                invalidText={inputError}
                onChange={onChange(key)}
                type={inputs[key].type}
                value={(settingsState as any)[key] || ''}
              />
            </InputGroup>
          );
        })}
      </div>
      <AddressSection
        companyAddress={company}
        disabled={disableSettings}
        handleCompanyAddress={handleCompanyAddress}
        handlePostalAddress={handlePostalAddress}
        postalAddress={postalAddress}
      />
      <AdverseActionEmailCC
        setting={adverseActionEmailCC}
        setSettings={setSettings}
      />
      {newCommunicationSettingsEnabled && (
        <div data-testid='notification-section'>
          <InputGroup key='communicationTypes'>
            <InputLabel htmlFor='communicationTypes'>
              {t('accountSettings.candidateCommunicationSettings.heading')}
            </InputLabel>
            <p>
              {t('accountSettings.candidateCommunicationSettings.message')}
              <M.Link
                style={{ color: colors.uiNavy600 }}
                className='text-muted'
                target='_blank'
                rel='noopener noreferrer'
                href={t(
                  'accountSettings.candidateCommunicationSettings.helpCenterLink',
                )}
              >
                <u>Learn more</u>
              </M.Link>
            </p>
            <M.Checkbox
              onClick={toggleEmailSetting(suppressEmailInvites)}
              name='suppressEmailInvites'
              labelText={t(
                'accountSettings.candidateCommunicationSettings.suppressEmailInvites.label',
              )} // "suppress email invitations to candidates when issuing invitations using the Checkr API. "
              disabled={false}
              hideLabel={false}
              id='suppressEmailInvites'
              checked={suppressEmailInvites}
            />
            <M.Checkbox
              onClick={toggleSmsSetting(suppressSmsNotifications)}
              name='suppressSmsNotifications'
              labelText={t(
                'accountSettings.candidateCommunicationSettings.suppressSmsNotifications.label',
              )} // "suppress sms notifications to candidates when issuing invitations using the Checkr API. "
              disabled={false}
              hideLabel={false}
              id='suppressSmsNotifications'
              checked={suppressSmsNotifications}
              data-testid='suppress-sms-notifications'
            />
          </InputGroup>
        </div>
      )}
      <br />
      {filestackKey && (
        <div data-testid='section-logo'>
          <InputGroup>
            <InputLabel>{t('headings.accountLogo')}</InputLabel>
          </InputGroup>
          <FileInputGroup>
            {logoUri ? (
              <img src={logoUri} alt='Logo' width={200} data-testid='logo' />
            ) : (
              <LogoPlaceholder data-testid='icon-placeholder' />
            )}
            {!disableSettings && (
              <FileInputGroupButtons data-testid='filestack-btn-wrap'>
                <ReactFilestack
                  actionOptions={filestackOptions}
                  apikey={filestackKey}
                  customRender={filestackUpload}
                  onSuccess={onFileUpload}
                />
                <M.Button
                  kind='primary'
                  onClick={onFileDelete}
                  data-testid='btn-remove'
                >
                  {t('buttons.delete')}
                </M.Button>
              </FileInputGroupButtons>
            )}
          </FileInputGroup>
        </div>
      )}
      <>
        <StyledSeparator />
        <StyledTitleWrapper>
          <StyledTitle>
            {t('accountSettings.drivingJurisdictions.heading')}
          </StyledTitle>
        </StyledTitleWrapper>
        <M.GridRow>
          <M.GridCol lg={10} style={{ padding: 0 }}>
            <div data-testid='default-driving-jurisdiction-section'>
              <p
                style={{
                  color: colors.uiTextSecondaryLight,
                  marginBottom: '1rem',
                }}
              >
                {t('accountSettings.drivingJurisdictions.description')}
              </p>

              <DrivingJurisdictionsContainer
                accountId={account?.company?.account_resource_id}
                accessCodesInput={accessCodesInput}
                setAccessCodesInput={setAccessCodesInput}
                setExistingAccessCodes={setExistingAccessCodes}
              />
            </div>
          </M.GridCol>
        </M.GridRow>
      </>
      <SubmitInputGroup>
        {processing ? (
          <span>
            <M.LoadingInline />
          </span>
        ) : (
          <M.Button
            kind='achievement'
            disabled={!id || disableSettings}
            onClick={onSave}
            data-testid='btn-save'
          >
            {t('buttons.save_account')}
          </M.Button>
        )}
        {!authorized && (
          <M.Button
            kind='danger'
            disabled={!id || disableSettings}
            onClick={confirmDeleteAccount}
            style={{ margin: '8px' }}
          >
            Delete my account
          </M.Button>
        )}
      </SubmitInputGroup>
    </>
  );
};

export default AccountSettingsOld;
