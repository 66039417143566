export const initialState = {
  addedScreeningTypes: [],
  additionalProperties: {},
  newPackageName: '',
  newPackageNameInvalid: true,
  basePackage: {
    name: '',
    id: '',
    screenings: [],
  },
  selectedGeo: 'domestic',
  error: '',
};

export const actionTypes = {
  SCREENING_ADDED: 'SCREENING_ADDED',
  SCREENING_REMOVED: 'SCREENING_REMOVED',
  SET_PACKAGE_NAME: 'SET_PACKAGE_NAME',
  SET_BASE_PACKAGE: 'SET_BASE_PACKAGE',
  SET_SELECTED_GEO: 'SET_SELECTED_GEO',
  CLEAR_BASE_PACKAGE_AND_ADDONS: 'CLEAR_BASE_PACKAGE_AND_ADDONS',
  SET_ERROR: 'SET_ERROR',
  SKIP_BASE_PACKAGE_SELECTION: 'SKIP_BASE_PACKAGE_SELECTION',
  SET_ALIASES_ENABLED: 'SET_ALIAS_ENABLED',
  SET_ALIASES_PRICE: 'SET_ALIASES_PRICE',
};

export const SKIPPED_NAME = 'SKIP_NAME_DO_NOT_SUBMIT_00q5wq58';

export const getEmptyPackageShell = () => {
  return {
    name: SKIPPED_NAME,
    screenings: [],
  };
};

export const isPackageNameInvalid = (name: string) =>
  !name.trim().length || !/^[a-zA-Z0-9\s]*$/.test(name);

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.SCREENING_ADDED:
      return {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.includes(
          action.payload.screeningType,
        )
          ? state.addedScreeningTypes
          : state.addedScreeningTypes.concat(action.payload.screeningType),
        additionalProperties: action.payload.additionalProperties
          ? {
              ...state.additionalProperties,
              [action.payload.screeningType]: {
                ...(state.additionalProperties[action.payload.screeningType] ||
                  {}),
                ...action.payload.additionalProperties,
              },
            }
          : state.additionalProperties,
      };
    case actionTypes.SCREENING_REMOVED: {
      let newState = {
        ...state,
        addedScreeningTypes: state.addedScreeningTypes.filter(
          (screeningType: any) =>
            screeningType !== action.payload.screeningType,
        ),
      };
      delete newState.additionalProperties[action.payload.screeningType];
      // removes adverse media search any time intl crim search v2 is removed
      if (
        action.payload.screeningType.includes(
          'international_criminal_search_v2',
        )
      ) {
        newState = {
          ...newState,
          addedScreeningTypes: newState.addedScreeningTypes.filter(
            (screeningType: any) =>
              screeningType !== 'international_adverse_media_search',
          ),
        };
      }
      return newState;
    }
    case actionTypes.SET_PACKAGE_NAME:
      return {
        ...state,
        newPackageName: action.payload.name,
        newPackageNameInvalid: isPackageNameInvalid(action.payload.name),
        error: '',
      };
    case actionTypes.SET_BASE_PACKAGE:
      return {
        ...state,
        basePackage: action.payload.package,
      };
    case actionTypes.SET_ALIASES_ENABLED:
      return {
        ...state,
        aliasesEnabled: action.payload.aliasesEnabled,
      };
    case actionTypes.SET_ALIASES_PRICE:
      return {
        ...state,
        aliasPrice: action.payload.aliasPrice,
        basePackage: {
          ...state.basePackage,
          request_aliases_hard_cap_threshold: 4,
        },
      };
    case actionTypes.SKIP_BASE_PACKAGE_SELECTION:
      return {
        ...state,
        basePackage: getEmptyPackageShell(),
      };
    case actionTypes.SET_SELECTED_GEO:
      return {
        ...state,
        selectedGeo: action.payload.selectedGeo,
      };
    case actionTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case actionTypes.CLEAR_BASE_PACKAGE_AND_ADDONS:
      return {
        ...state,
        addedScreeningTypes: [],
        additionalProperties: {},
        newPackageName: '',
        basePackage: {
          name: '',
          id: '',
          screenings: [],
        },
        error: '',
      };
    default:
      return state;
  }
};
