import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { ScreeningProductType } from '../AddonsStep.enums';
import { StyledFlex } from '../AddonsStep.styles';
import type { AddonsT } from '../..';
import { InternationalCriminalSearchesSection } from './InternationalCriminalSearchesSection';
import { InternationalVerificationsSection } from './InternationalVerificationsSection';
import { StyledLink } from './InternationalScreeningsList.styles';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addedScreeningTypes: AddonsT.ScreeningType[];
  addOnPrices: AddonsT.AddonPrice[];
  includedScreenings: AddonsT.ScreeningType[];
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  setSelectedAdditionalProperties: (
    additionalProperties: AddonsT.AdditionalProperties,
  ) => void;
};

const InternationalScreeningsList: React.FC<Props> = props => {
  const { addedScreeningTypes, includedScreenings } = props;
  const { t } = useTranslation(namespace);

  const isAdded = useCallback(
    (screeningType: ScreeningProductType) => {
      return (
        addedScreeningTypes.filter(screening => screening === screeningType)
          .length > 0
      );
    },
    [addedScreeningTypes],
  );

  // Checks if screening is included in the selected package
  const isIncluded = useCallback(
    (screeningType: ScreeningProductType) => {
      return (
        includedScreenings.filter(screening => screening === screeningType)
          .length > 0
      );
    },
    [includedScreenings],
  );

  return (
    <StyledFlex flexDirection='column' data-testid='screenings-list-container'>
      <StyledLink href='https://checkr.com/pricing/international'>
        {t('addOns.international.link')}
      </StyledLink>
      <InternationalCriminalSearchesSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
      />
      <InternationalVerificationsSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
      />
    </StyledFlex>
  );
};

export default InternationalScreeningsList;
