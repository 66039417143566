import { toastError, toastSuccess } from 'actions';
import { addPackage } from 'api/packages';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

// TODO: this hook was copied from the packages api directory
// many of these props are used for flows other than ordering BGCs
// we should be able to slowly remove the props we don't need
type useCreatePackageParams = {
  accountId: string;
  toggleShowAddScreenings?: Function;
  saveForNextTime?: boolean;
  onSuccess?: Function | null;
  onError?: Function;
  trackError?: Function;
};

// eslint-disable-next-line import/prefer-default-export
export const useCreatePackage = ({
  accountId,
  toggleShowAddScreenings,
  saveForNextTime = true,
  onSuccess,
  onError,
  trackError,
}: useCreatePackageParams) => {
  const dispatch = useDispatch();
  const request = (params: URLSearchParams) => addPackage(accountId, params);

  const [call, result] = useMutation(request, {
    onSuccess: (data: { [key: string]: unknown }) => {
      if (onSuccess) {
        onSuccess(data);
      } else if (toggleShowAddScreenings) {
        toggleShowAddScreenings();
        saveForNextTime && dispatch(toastSuccess('Package saved'));
      }
    },
    onError: (error: any) => {
      if (onError) {
        onError(error);
      } else {
        const errorData = error?.response?.data;
        let errorMessage: string = '';
        if (errorData.error) {
          errorMessage = errorData.error;
        } else {
          errorMessage = errorData.errors.join(', ');
        }

        if (
          Array.isArray(errorData?.errors) &&
          errorData?.errors[0]?.toLowerCase() === 'slug has already been taken'
        ) {
          errorMessage = 'Please choose a unique name for your new package';
        }
        if (trackError) {
          trackError(error);
        }
        dispatch(toastError('Failed to create package', errorMessage));
      }
    },
  });

  return {
    call,
    result,
  };
};
