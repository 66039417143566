import React, { useState, useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { BillingEntity } from 'types/Billing';
import PaymentProfileModal from 'components/Packages/OrderBackgroundCheck/GetStarted/PaymentProfileModal';
import {
  StyledLearnMoreText,
  StyledLearnMoreIcon,
  StyledDescription,
  StyledSectionMargin,
} from './BillingSection.styles';
import { namespace } from '../../locales';
import { PaymentProfileDropdown } from '../Dropdowns';

type Props = {
  payment_profiles: BillingEntity[];
  selectedPaymentProfile: BillingEntity;
  setSelectedPaymentProfile: (paymentProfile: BillingEntity) => void;
};

const BillingSection: React.FC<Props> = ({
  payment_profiles,
  selectedPaymentProfile,
  setSelectedPaymentProfile,
}) => {
  const [showPaymentProfileModal, setShowPaymentProfileModal] = useState(false);
  const { t } = useTranslation(namespace, {
    keyPrefix: 'getStarted.payment',
  });

  const handlePaymentProfileModalClose = useCallback(() => {
    setShowPaymentProfileModal(false);
  }, []);

  const handlePaymentProfileModalOpen = useCallback(() => {
    setShowPaymentProfileModal(true);
  }, []);

  return (
    <div data-testid='get-started-billing-section'>
      {showPaymentProfileModal && (
        <PaymentProfileModal
          showPaymentProfileModal={showPaymentProfileModal}
          handleClose={handlePaymentProfileModalClose}
        />
      )}
      <h4 data-testid='get-started-payment-profile-title'>
        {t('title')}{' '}
        <StyledLearnMoreText
          data-testid='get-started-payment-profile-learn-more-icon'
          onClick={handlePaymentProfileModalOpen}
        >
          <StyledLearnMoreIcon size='16' icon='InformationFilled' />
        </StyledLearnMoreText>
      </h4>
      <StyledDescription data-testid='get-started-payment-profile-description'>
        {t('description')}
      </StyledDescription>
      {payment_profiles.length > 0 ? (
        <PaymentProfileDropdown
          payment_profiles={payment_profiles}
          selectedPaymentProfile={selectedPaymentProfile}
          setSelectedPaymentProfile={setSelectedPaymentProfile}
        />
      ) : (
        <M.InlineNotification
          inline='classic'
          hideActionButton
          data-testid='get-started-payment-profile-not-loaded-message'
          kind='warning'
          title={t('error.title')}
          subtitle={t('error.description')}
        />
      )}
      <StyledSectionMargin />
    </div>
  );
};

export default BillingSection;
