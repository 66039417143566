import type { BulkUploadT } from '../BulkUploadStep';

export type ManualBulkUploadRow = {
  first_name: string;
  last_name: string;
  middle_name?: string;
  date_of_birth: string;
  ssn: string;
  zip: string;
  phone?: string;
  email?: string;
};

export const CsvHeaders: Array<keyof ManualBulkUploadRow> = [
  'first_name',
  'last_name',
  'middle_name',
  'date_of_birth',
  'ssn',
  'zip',
  'phone',
  'email',
];

export const CsvReg: {
  [key: string]: RegExp;
} = {
  first_name: /^(?=.*[A-Za-zÀ-ÿ])[A-Za-zÀ-ÿ'"`0-9. -]+$/i,
  last_name: /^(?=.*[A-Za-zÀ-ÿ])[A-Za-zÀ-ÿ'"`0-9. -]+$/i,
  middle_name: /^[a-zÀ-ÿA-Z0-9 -]*$/i,
  date_of_birth: /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/i,
  ssn: /^(?!000|666|9\d{2})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/i,
  zip: /^\d{5}$/i,
  phone: /^(?!(\+|00)\d{1,4}\s?)?[\d\s-]*$/i,
  email: /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})*$/i,
};

export const removeErrorRows = (
  data: BulkUploadT.ManualBulkUploadData,
): BulkUploadT.ManualBulkUploadData => {
  const newData = data.parsedData.filter(
    (_, index) => !data.rowsWithError.includes(index + 1),
  );

  return {
    fileName: `${data.fileName.slice(0, -4)}-no-errors.csv`,
    fileSize: data.fileSize,
    parsedData: newData,
    rowsWithError: [],
    isValid: true,
  };
};

export const getCSVContent = (array: any[]): string => {
  if (!array.length) {
    return '';
  }

  const header = Object.keys(array[0]);

  return [
    header.join(','), // header row first
    ...array.map(row =>
      header.map(fieldName => JSON.stringify(row[fieldName])).join(','),
    ),
  ]
    .join('\r\n')
    .replace(/['"]+/g, '');
};

export const showLinesWithError = (arr: number[]) => {
  const uiLines = arr.map(item => item + 1);
  if (arr.length > 9) {
    return `${uiLines.slice(0, 9).join(', ')}, and ${uiLines.length - 9} more`;
  }
  return uiLines.join(', ');
};

export const removeDuplicates = (arr: any[]) => {
  return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
};

export const getRowsWithErrors = (data: ManualBulkUploadRow[]): number[] => {
  const rowsWithErrors: number[] = [];

  data.forEach((row, index) => {
    for (const [key, value] of Object.entries(row)) {
      if (key in CsvReg && !CsvReg[key].test(value as string)) {
        rowsWithErrors.push(index + 1);
      }
    }
  });

  return removeDuplicates(rowsWithErrors);
};
