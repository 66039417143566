import React from 'react';
import { PackageCardProps } from 'components/Signup/types';
import { M } from '@dashboard-experience/mastodon';
import useAmplitudeExperiment from 'components/Signup/hooks/useAmplitudeExperiment';

import { Container } from '../styles';
import BasicPackageCard from './BasicPackageCard';
import CompletePackageCard from './CompletePackageCard';
import EssentialPackageCard from './EssentialPackageCard';
import { HeaderHeightProvider } from '../../PackageCard/PackageCardState';

const DomesticPackages = ({
  selectedPackage,
  setSelectedPackage,
}: PackageCardProps) => {
  const { loading: experimentLoading, variant: ab13Variant } =
    useAmplitudeExperiment();

  const cardProps = {
    selectedPackage,
    setSelectedPackage,
    showCompleteText:
      ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v2' ||
      ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v3',
  };

  if (experimentLoading) {
    return <M.LoadingBlock />;
  }

  if (
    ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v1' ||
    ab13Variant === 'ab-13-mvt-on-signup-packages-page-test-v3'
  ) {
    return (
      <HeaderHeightProvider>
        <Container data-testid='domestic-packages-container'>
          <CompletePackageCard {...cardProps} />
          <EssentialPackageCard {...cardProps} />
          <BasicPackageCard {...cardProps} />
        </Container>
      </HeaderHeightProvider>
    );
  }

  return (
    <HeaderHeightProvider>
      <Container data-testid='domestic-packages-container'>
        <BasicPackageCard {...cardProps} />
        <EssentialPackageCard {...cardProps} />
        <CompletePackageCard {...cardProps} />
      </Container>
    </HeaderHeightProvider>
  );
};

export default DomesticPackages;
