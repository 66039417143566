import { toast } from 'actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Notifications from 'state/notifications';
import { Params } from 'state/notifications/entity';

/**
 * Get a list of notifications
 * @returns An array of all notifications in the store
 */
export function useEntries() {
  const notifications: Notifications.State.Type = useSelector(
    (state: any) => state.notifications,
  );
  return Notifications.State.entries(notifications);
}

/**
 * Hook for managing certain toasts which need to avoid having duplicates (as measured by the key specified; regardless of the content within the toast itself).
 * This is an opt-in system which should not impact any other kinds of toasts.
 * @param {string} key - used as the uniqueness check for any toasts generated with the same hook
 * @returns Function - the function to call to generate the toast
 */
export function useUniqueToast(key: string) {
  const dispatch = useDispatch();

  return useCallback(
    (toastParams: Params) => dispatch(toast({ ...toastParams, key })),
    [dispatch, key],
  );
}
