import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, M } from '@dashboard-experience/mastodon';
import {
  DRUG_AND_HEALTH_TABS,
  FIRST_TABS,
  HELP_TABS,
  I9_TABS,
  SECOND_TABS,
} from 'components/DashboardWrapper/tabs';
import NavElement from './nav-element';
import PredefinedNavElement from './predefined-nav-element';
import NavElementWithSubmenu from './nav-element-with-submenu';
import NavDropdownComponent from './nav-dropdown';
import { Divider, UL, ListElement, FullWidthActionMenu } from './styles';

interface StyledProps {
  isOpen: boolean;
}

const HeaderContainer = styled.div`
  height: 56px;
  background-color: ${colors.uiNavy800};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0 20px;
  width: 100%;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
`;

const Menu = styled.div<StyledProps>`
  position: fixed;
  top: 56px;
  left: ${({ isOpen }) => (isOpen ? '0' : '-264px')};
  width: 264px;
  background-color: ${colors.uiNavy700};
  box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
  transition: left 0.4s ease-in-out;
  overflow-y: auto;
  z-index: 1000;
  height: calc(100vh - 56px);

  @media (max-width: 1056px) {
    overflow: visible;
  }
`;

const Logo = styled.div`
  width: 86px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  margin: 0 auto;

  svg {
    width: 86px;
    height: 20px;
    fill: white;
  }
`;

const Hamburger = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  cursor: pointer;
  z-index: 4;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const NavContainer = styled.div`
  background-color: ${colors.bgSecondaryLight};
  border-right: 1px solid ${colors.uiGrey200};
  z-index: 4;

  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
`;

export const LinksSection = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
  height: 512px;
  padding: 32px 12px;
`;

type HeaderProps = {
  user: any;
  pathname: string;
  handleAccountSwitch: (selected: any) => void;
  handleLogout: () => void;
  isCollapsed: boolean;
};

const Header: React.FC<HeaderProps> = ({
  user,
  pathname,
  handleAccountSwitch,
  handleLogout,
  isCollapsed,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleToggleNav = useCallback(() => {
    setMenuOpen(prev => !prev);
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  return (
    <>
      <HeaderContainer>
        <Hamburger onClick={handleToggleNav}>
          {isMenuOpen ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.8333 15H2.5V16.25H15.8333V15ZM11.6667 11.25H2.5V12.5H11.6667V11.25ZM11.6667 7.5H2.5V8.75H11.6667V7.5ZM15.8333 3.75H2.5V5H15.8333V3.75ZM17.2604 6.22475L13.2829 10.2022L17.2604 14.1797L18.1442 13.2958L15.0506 10.2022L18.1442 7.10864L17.2604 6.22475Z'
                fill='white'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
            >
              <path
                d='M2.5 3.75H17.5V5H2.5V3.75ZM2.5 15H17.5V16.25H2.5V15ZM2.5 7.5H17.5V8.75H2.5V7.5ZM2.5 11.25H17.5V12.5H2.5V11.25Z'
                fill='white'
              />
            </svg>
          )}
        </Hamburger>
        <Logo>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='86'
            height='20'
            viewBox='0 0 86 20'
            fill='none'
          >
            <g clipPath='url(#clip0_7945_123597)'>
              <path
                d='M84.1016 5.11731C82.2461 5.11731 80.957 5.68372 79.9219 6.95325L79.2383 5.19543H76.7383V19.59H80.6445V12.1095C80.6445 9.76575 81.5039 8.78919 83.7109 8.78919H85.3906V5.11731H84.1016Z'
                fill='white'
              />
              <path
                d='M75.0586 5.19531H70.5664L66.25 11.582V0H62.3633V19.5898H66.25V12.6953L70.8594 19.5898H75.5664L70.1758 12.0508L75.0586 5.19531Z'
                fill='white'
              />
              <path
                d='M54.1406 16.3477C52.0508 16.3477 50.4492 14.6094 50.4492 12.3828C50.4492 10.1563 52.0312 8.45703 54.1406 8.45703C55.6055 8.45703 56.9141 9.21875 57.5586 10.6055L61.1133 9.375C60 6.5625 57.3828 4.78516 54.1797 4.78516C49.8047 4.78516 46.5039 8.02734 46.5039 12.3437C46.5039 16.6602 49.8242 20 54.1406 20C57.4023 20 60.0977 18.1445 61.1719 15.2344L57.5586 13.9844C57.0117 15.4492 55.6445 16.3477 54.1406 16.3477Z'
                fill='white'
              />
              <path
                d='M38.0664 4.78516C33.7891 4.78516 30.6055 8.04688 30.6055 12.3828C30.6055 16.6992 33.8281 19.9805 38.0664 19.9805C41.0352 19.9805 43.5352 18.3984 44.7852 15.8594L41.0938 14.8828C40.4883 15.8789 39.3945 16.5039 38.0664 16.5039C36.1719 16.5039 34.8828 15.4297 34.5508 13.457H45.4492C45.4883 13.0859 45.5273 12.6953 45.5273 12.3242C45.5273 8.20312 42.5 4.78516 38.0664 4.78516ZM34.6484 10.8203C35.0977 9.12109 36.3281 8.10547 38.0664 8.10547C39.8047 8.10547 41.0742 9.0625 41.6211 10.8203H34.6484Z'
                fill='white'
              />
              <path
                d='M15.8594 19.5898H19.7461V12.0313C19.7461 9.6875 21.0352 8.28125 22.9687 8.28125C24.7266 8.28125 25.4883 9.35547 25.4883 11.5039V19.5898H29.375V10.8398C29.375 7.16797 27.3437 4.84375 24.082 4.84375C22.3633 4.84375 20.8203 5.48828 19.7266 6.52344V0H15.8594'
                fill='white'
              />
              <path
                d='M7.65625 16.3475C5.56641 16.3475 3.96484 14.6093 3.96484 12.3827C3.96484 10.1561 5.54687 8.45691 7.65625 8.45691C9.12109 8.45691 10.4297 9.21863 11.0742 10.6053L14.6289 9.37488C13.5156 6.56238 10.8984 4.78503 7.69531 4.78503C3.30078 4.78503 0 8.02722 0 12.3436C0 16.66 3.32031 19.9999 7.63672 19.9999C10.8984 19.9999 13.5937 18.1444 14.668 15.2343L11.0547 13.9843C10.5078 15.4491 9.16016 16.3475 7.65625 16.3475Z'
                fill='white'
              />
            </g>
            <defs>
              <clipPath id='clip0_7945_123597'>
                <rect width='86' height='20' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </Logo>
        <div style={{ width: '20px' }} />
      </HeaderContainer>

      <Menu isOpen={isMenuOpen}>
        <NavContainer>
          {user.multi_account_enabled &&
            user.multi_accounts &&
            user.multi_accounts.length > 0 && (
              <>
                <NavDropdownComponent
                  id='select-mam-account'
                  onChange={handleAccountSwitch}
                  defaultValue={user.multi_accounts.find(
                    (account: any) => account.account_id === user?.account?.id,
                  )}
                  items={user.multi_accounts}
                  navCollapsed={isCollapsed}
                />
                <Divider />
              </>
            )}
          <LinksSection>
            <UL>
              {Object.entries(FIRST_TABS).map(([titleKey, tab]) => (
                <PredefinedNavElement
                  key={titleKey}
                  titleKey={titleKey}
                  tab={tab}
                  currentPath={pathname}
                  navCollapsed={isCollapsed}
                />
              ))}
            </UL>
            <Divider />
            <UL>
              {Object.entries(I9_TABS).map(([titleKey, tab]) => (
                <PredefinedNavElement
                  key={titleKey}
                  titleKey={titleKey}
                  tab={tab}
                  currentPath={pathname}
                  navCollapsed={isCollapsed}
                />
              ))}
              <NavElementWithSubmenu
                tabName='drug_and_health'
                navCollapsed={isCollapsed}
                submenuTabs={DRUG_AND_HEALTH_TABS}
                currentPath={pathname}
              />
              {Object.entries(SECOND_TABS).map(([titleKey, tab]) => (
                <PredefinedNavElement
                  key={titleKey}
                  titleKey={titleKey}
                  tab={tab}
                  currentPath={pathname}
                  navCollapsed={isCollapsed}
                />
              ))}
            </UL>
            <Divider />
            <UL>
              <NavElementWithSubmenu
                tabName='help'
                navCollapsed={isCollapsed}
                submenuTabs={HELP_TABS}
              />
              <ListElement>
                <FullWidthActionMenu
                  trigger={
                    <NavElement
                      navCollapsed={isCollapsed}
                      text={user?.email || 'Current User'}
                      tabName='mail'
                    />
                  }
                  position='right-top'
                  positionMenuAbsolutely
                >
                  <M.ActionMenuItem title='Logout' onClick={handleLogout} />
                </FullWidthActionMenu>
              </ListElement>
            </UL>
          </LinksSection>
        </NavContainer>
      </Menu>
    </>
  );
};

export default Header;
