import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';

const LocationSectionHeader: React.FC = () => {
  const { t } = useTranslation('better_order_experience');

  return (
    <>
      <h4 data-testid='get-started-work-location-title'>
        {t('getStarted.workLocation.title')}
      </h4>
      <p className='p2'>{t('getStarted.workLocation.description')}</p>
      <p className='p2'>
        {t('getStarted.workLocation.linkDescription')}{' '}
        <M.Link
          data-testid='get-started-work-location-link'
          href='https://checkr.com/background-check#background-check-laws-by-state'
          target='_blank'
          size='lg'
          rel='noopener noreferrer'
        >
          {t('getStarted.workLocation.linkText')}
        </M.Link>
        .
      </p>
    </>
  );
};

export default LocationSectionHeader;
