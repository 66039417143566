import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { updateParentWindowUrl } from 'utils';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import { INVITE_PAGE_ADD_SCREENINGS } from 'Flags';
import { isAddOnsEnabled } from 'components/Packages/shared/utils';
import querystring from 'querystring';
import UIContext from 'context/UI';
import { useUser } from 'context/CurrentUser';
import { usePackages, useRecommendedPackages } from 'api/packages';
import {
  FetchPackageType,
  PostPackageType,
  hasPermission,
  useDebouncedCallback,
} from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import { Footer, StepContainer } from '../ui';
import { STEPS } from '../utils/constants';
import { parsePackagesResponse } from './SelectPackageUtils';
import ConditionalLoadingRender from './ui/ConditionalLoadingRender';
import { namespace } from '../locales';
import type { SelectPackageT } from '..';
import SelectablePackagesList, {
  packagesPerPage,
} from './SelectablePackagesList';
import {
  StyledHeader,
  StyledSearchContainer,
} from './styles/SelectPackageStyles';
import RecommendedPackages from './RecommendedPackages/RecommendedPackages';
import { RECOMMENDED_PACKAGES_FLAG } from '../../../Flags';

const defaultParams: SelectPackageT.Params = {
  page: 1,
  per_page: packagesPerPage,
  name: '',
  program_ids: [],
  localeType: 'DOMESTIC',
};

const isEmpty = (object: {}) => Object.values(object).length < 1;

const SelectPackageStep: React.FC<StepProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  const { contextId } = useContext(UIContext);
  const basePath = `${namespace}:selectPackage`;

  const currentUser = useUser();
  const { account } = currentUser;
  const {
    id: accountId,
    show_package_price: showPackagePrice,
    package_price_state: packagePriceState,
    hierarchy_present,
  } = account;
  const showRecommendedPackages =
    useFlag(RECOMMENDED_PACKAGES_FLAG)?.variantKey === 'on';

  const history = useHistory();
  const {
    location: { pathname },
  } = history;

  const {
    node,
    infoSource,
    manualOrderType,
    update,
    selectedPackage,
    program,
    localeType,
  } = useContext(BetterOrderExperienceContext);

  const isAddOnsFlagEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';
  const requiresFMCSACompliance = Boolean(
    selectedPackage?.requires_fmcsa_compliance,
  );
  const hasManageAddOnsPermission = hasPermission(
    currentUser,
    'manage_order_add_ons',
  );

  const addOnsEnabled = isAddOnsEnabled(
    isAddOnsFlagEnabled,
    requiresFMCSACompliance,
    hasManageAddOnsPermission,
  );

  const startingParams = {
    ...defaultParams,
    program_ids: program ? [program.id] : [],
  };

  const [params, setParams] = useState<SelectPackageT.Params>(startingParams);
  const [apiParams, setApiParams] = useState(
    new URLSearchParams(querystring.stringify({ ...params })),
  );
  const [searchParam, setSearchParam] = useState(params.name);

  const packagesResponse = usePackages(accountId, apiParams);

  const { count, packages, packagesLoading } = parsePackagesResponse(
    packagesResponse as unknown as SelectPackageT.QueryResult<FetchPackageType>,
    localeType === 'DOMESTIC',
  );
  const { data, isLoading: recommendedPackagesLoading } =
    useRecommendedPackages(accountId);
  const recommendedPackages = (data ?? []) as FetchPackageType[];

  const handlePageNumberChange = useCallback(
    (pageIndex: number) => {
      setParams({ ...params, page: pageIndex + 1 });
    },
    [params, setParams],
  );

  const debouncedSearch = useDebouncedCallback(
    value => {
      setParams({ ...params, page: 1, name: value });
    },
    350,
    [accountId],
  );

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchParam(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  const showPrice: boolean =
    showPackagePrice && packagePriceState !== 'disabled via partner';
  const isManualBulkOrder =
    infoSource === 'MYSELF' && manualOrderType === 'MULTIPLE';

  const continueDisabled = useMemo(
    () => isEmpty(selectedPackage),
    [selectedPackage],
  );

  const handleBack = useCallback(() => {
    const path = '/order-experience/get-started';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.GET_STARTED);
  }, [contextId, history, setCurrentStep]);

  const getNextPath = useCallback(() => {
    if (isManualBulkOrder) {
      return STEPS.UPLOAD_CANDIDATE_INFO.path;
    }
    if (addOnsEnabled) {
      return STEPS.ADD_ONS.path;
    }
    return STEPS.REVIEW_AND_SUBMIT.path;
  }, [addOnsEnabled, isManualBulkOrder]);

  const handleContinue = useCallback(() => {
    // if infosource is myself and the manual type is multiple, take them to the bulk upload step
    const path = getNextPath();

    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.ADD_ONS);
  }, [contextId, getNextPath, history, setCurrentStep]);

  const isSelectedPackageVisible = packages?.some(selectedPackage =>
    recommendedPackages?.some(
      (pkg: { slug: string }) => pkg.slug === selectedPackage?.slug,
    ),
  );

  useEffect(() => {
    !isSelectedPackageVisible &&
      update({ selectedPackage: {} as PostPackageType });
  }, [isSelectedPackageVisible, update]);

  useEffect(() => {
    const newParams: SelectPackageT.Params = { ...params };

    setApiParams(new URLSearchParams(querystring.stringify(newParams)));

    if (contextId) {
      updateParentWindowUrl({
        contextId,
        search: newParams,
      });
    }
  }, [contextId, params, pathname, setApiParams]);

  // TODO: If/When Recommended Packages are enabled, tests that look for the header will need to be updated
  const showHeader = useMemo(() => {
    return isManualBulkOrder || !showRecommendedPackages;
  }, [isManualBulkOrder, showRecommendedPackages]);

  return (
    <StepContainer data-testid='select-package-step-container'>
      {showRecommendedPackages && (
        <h4 data-testid='select-packages-title'>{t(`${basePath}.title`)}</h4>
      )}
      {showHeader && (
        <StyledHeader className='p2' data-testid='select-your-package-header'>
          {isManualBulkOrder
            ? t(`${basePath}.manualBulkHeaderDescription`)
            : t(`${basePath}.headerDescription`)}
        </StyledHeader>
      )}
      {showRecommendedPackages && (
        <ConditionalLoadingRender shouldRender={!recommendedPackagesLoading}>
          <RecommendedPackages
            fetchedRecommendedPackages={recommendedPackages}
            showPackagePrice={showPrice}
            isManualBulkOrder={isManualBulkOrder}
            selectedPackage={selectedPackage}
            update={update}
          />
          <p className='bold'>{t(`${basePath}.otherPackagesTitle`)}</p>
        </ConditionalLoadingRender>
      )}
      <StyledSearchContainer
        value={searchParam}
        type='text'
        onChange={handleSearchChange}
        data-testid='package-search'
        placeholder={t(`${basePath}.searchPlaceholder`)}
      />
      <ConditionalLoadingRender shouldRender={!packagesLoading}>
        <SelectablePackagesList
          fetchedPackages={packages}
          count={count}
          hierarchy_present={hierarchy_present}
          accountId={accountId}
          node={node}
          localeType={localeType}
          params={params}
          handlePaginationClick={handlePageNumberChange}
          update={update}
          selectedPackage={selectedPackage}
          isManualBulkOrder={isManualBulkOrder}
          showPrice={showPrice}
        />
      </ConditionalLoadingRender>
      <Footer>
        <M.Button
          type='button'
          kind='secondary'
          data-testid='select-package-back-btn'
          onClick={handleBack}
        >
          {t(`${basePath}.backButton`)}
        </M.Button>
        <M.Button
          type='button'
          data-testid='select-package-continue-btn'
          disabled={continueDisabled}
          onClick={handleContinue}
        >
          {t(`${basePath}.continueButton`)}
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default SelectPackageStep;
