import React from 'react';
import {
  getScreeningType,
  isInternationalMotorVehicleReport,
  getIncludeDrivingHistorySetting,
} from 'components/Packages/PackageScreenings';
import { Screening } from 'types';
import { ScreeningSetting } from '@dashboard-experience/utils';
import {
  StyledScreeningListItem,
  StyledScreeningSettings,
  StyledScreeningType,
} from './styles/PackageCard.styles';
import { getScreeningSetting } from '../utils/PackageScreeningUtils';
import type { AddonsT } from '../AddonsStep';

const PackageScreeningItem: React.FC<{
  screening: Screening | AddonsT.PostPackageScreeningType;
  screeningSettings?: ScreeningSetting[];
  showScreeningSettings?: boolean;
  compact?: boolean;
}> = ({
  screening = { type: null } as unknown as Screening,
  screeningSettings = [],
  showScreeningSettings = true,
  compact = false,
}) => {
  const screeningType = getScreeningType(screening.type);

  const screeningSetting = getScreeningSetting(
    screening as unknown as Screening,
    screeningSettings,
  );
  return (
    <StyledScreeningListItem key={`${screening.type}`} compact={compact}>
      <StyledScreeningType data-testid={`screening-type-${screening.type}`}>
        {screeningType}{' '}
        {showScreeningSettings && (
          <StyledScreeningSettings>{screeningSetting}</StyledScreeningSettings>
        )}
        {showScreeningSettings &&
          isInternationalMotorVehicleReport(screening.type) && (
            <StyledScreeningSettings>
              {`${getIncludeDrivingHistorySetting(screening)}`}
            </StyledScreeningSettings>
          )}
      </StyledScreeningType>
    </StyledScreeningListItem>
  );
};

export default PackageScreeningItem;
