import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'context/CurrentUser';
import { namespace } from 'modules/candidate/locales';
import { useLastReportTask } from 'modules/case-management/ui/TasksPage/hooks';
import getFields from './get-fields';
import withReportContentContainer from '../report-content-container';
import { buildColumnConfig } from '../utils';
import { ExpandablePanel, KeyValueList } from '../styles';
import { SectionProps } from '../types';

const GeneralDetails: React.FC<SectionProps> = ({ report, statusType }) => {
  const user = useUser();
  const task = useLastReportTask({ report });
  const fields = getFields({ report, task });
  const { t } = useTranslation();

  const title = t(`${namespace}:report.overview.general-details.title`);

  const reportInformation = buildColumnConfig({
    fields,
    report,
    statusType,
    user,
  });

  return fields.length > 0 ? (
    <ExpandablePanel
      data-pendo-id='report-information-panel'
      initialExpanded
      title={title}
      style={{ marginTop: '2rem' }}
    >
      <KeyValueList
        items={reportInformation}
        data-testid='overview-report-information'
      />
    </ExpandablePanel>
  ) : null;
};

export default withReportContentContainer(GeneralDetails);
