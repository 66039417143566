// eslint-disable-next-line import/prefer-default-export
export const STEPS = {
  GET_STARTED: {
    name: 'getStarted',
    path: '/order-experience/get-started',
    position: 1, // Not to be confused with the step number in the label/title of the UI
  },
  SELECT_YOUR_PACKAGE: {
    name: 'selectPackage',
    path: '/order-experience/select-your-package',
    position: 2,
  },
  ADD_ONS: {
    name: 'addOns',
    path: '/order-experience/add-ons',
    position: 3,
  },
  UPLOAD_CANDIDATE_INFO: {
    name: 'bulkUpload',
    path: '/order-experience/bulk-upload',
    position: 3,
  },
  REVIEW_AND_SUBMIT: {
    name: 'reviewAndSubmit',
    path: '/order-experience/review-and-submit',
    position: 4,
  },
  MANUAL_ENTRY: {
    name: 'manualEntry',
    path: '/order-experience/manual-entry',
    position: 5,
  },
};
