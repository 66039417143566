import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

// FLEXBOX COMPONENT
type FlexProps = {
  flexDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  columnGap?: '1rem';
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
};

// eslint-disable-next-line import/prefer-default-export
export const StyledFlex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  column-gap: ${props => props.columnGap || '0'};
  align-items: ${props => props.alignItems || 'flex-start'};
  vertical-align: ${props => props.verticalAlign || 'middle'};
  width: 100%;
`;

export const StyledFlexPositionRelative = styled(StyledFlex)`
  position: relative;
  margin-bottom: 24px;
`;

export const StyledCheckbox = styled(M.Checkbox)`
  flex: none;
`;

export const StyledScreeningContainer = styled(StyledFlex)<{
  flexDirection?: string;
}>`
  padding: 0 1rem 1rem 1rem;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};

  :not(:last-child) {
    margin-bottom: 1rem;
    border-bottom: 1px solid ${colors.brandSlate2};
  }
  :last-child {
    padding-bottom: 0;
  }
`;

export const StyledAdditionalPropContainer = styled(StyledScreeningContainer)`
  padding-left: 38px;
  flex-direction: column;
  width: 100%;
`;
