import { GenericObject } from '@dashboard-experience/utils';
import {
  AccountsClient as client,
  ReportsClient as rpclient,
  Client,
} from 'api';
import { Packages } from 'types';
import { PATH } from './constants';

type Params = GenericObject & { with_cc?: boolean };

export type ListParams = {
  accountId: string;
  params?: Params;
  useCache?: boolean;
};

type UploadParams = {
  addons?: string[];
  fileDisplayName: string;
  geo_id?: string;
  invited_from?: string;
  node?: string;
  package: string;
  permissible_purpose?: string;
  program?: string;
  work_locations?: Array<string>;
};

type ValidateCSVParams = {
  geo_id: string;
  fileDisplayName: string;
};

export type UploadCSVPayload = {
  accountId: string;
  uploaded_list_type: string;
  raw_csv_file_s3_path: string;
  upload_params: UploadParams;
};

export type ValidateCSVPayload = {
  accountId: string;
  uploaded_list_type: string;
  raw_csv_file_s3_path: string;
  upload_params: ValidateCSVParams;
};

/**
 * @param params
 */
export const list = ({
  accountId,
  params,
  useCache,
}: ListParams): Promise<Packages> => {
  // else get report from api
  return client.get(`${accountId}/${PATH}`, {
    params,
  });
};

/**
 * listPackages does not use any caching, and always requests the latest data
 * @param accountId
 * @param params
 */
export const listPackages = (
  accountId: string,
  params: URLSearchParams,
): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/${PATH}`, {
    params,
  });
};

export const addPackage = (
  accountId: string,
  params: URLSearchParams,
): Promise<{ [key: string]: any }> => {
  return client.post(`${accountId}/${PATH}`, params);
};

export const editPackage = (
  accountId: string,
  packageId: string,
  params: URLSearchParams,
): Promise<{ [key: string]: any }> => {
  return client.put(`${accountId}/${PATH}/${packageId}`, params);
};

export const fetchPackage = (
  accountId: string,
  packageId: string,
): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/${PATH}/${packageId}`);
};

export const deletePackage = (
  accountId: string,
  packageId: string,
): Promise<{ [key: string]: any }> => {
  return client.delete(`${accountId}/${PATH}/${packageId}`);
};

export const fetchPackageSubtype = (
  accountId: string,
): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/package_subtypes`);
};

export const fetchPackageSubtypeGlobal = (
  screeningType: string,
): Promise<{ [key: string]: any }> => {
  return Client.get(`/package_subtypes/${screeningType}/available_services`);
};

export const fetchProgressivePackages = (
  reportId: string,
): Promise<{ [key: string]: any }> => {
  return rpclient.get(`${reportId}/upgradeable_packages`);
};

export const fetchRecommendedPackages = (
  accountId: string,
): Promise<{ [key: string]: any }> => {
  return client.get(`${accountId}/recommended_packages`);
};

export const uploadCSV = (
  accountId: string,
  params: UploadCSVPayload,
): Promise<GenericObject> => {
  return client.post(`${accountId}/csv_uploads`, params);
};

export const validateCSV = (
  params: ValidateCSVPayload,
): Promise<GenericObject> => {
  return client.post(`/bulk_csv_validations`, params);
};

export const applyManualBulkOrder = (
  package_resource_id: string,
  params: {},
) => {
  return Client.post(`apply/bulk/${package_resource_id}`, params);
};

export const fetchAutoStartReportEligibility = (
  accountId: string,
  candidateId: string,
  packageId: string,
) => {
  return client.get(
    `${accountId}/candidates/${candidateId}/packages/${packageId}/eligibility`,
  );
};
