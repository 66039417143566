/* eslint-disable import/prefer-default-export */
import React, { FC } from 'react';
import { BannerProps, AnalyticsStates } from './types';
import { ToggleBanner, ToggleStates } from '../Beta/ToggleBanner';

export const AnalyticsBanner: FC<BannerProps> = ({
  lookerName,
  state,
  toggle,
}) => {
  const translationKey = `analytics.banner.${lookerName}`;
  const buttonPendoId = `analytics-${lookerName}-${state}`;
  const testId = `${lookerName}-toggle-banner`;

  return (
    <ToggleBanner
      iconAlt='version'
      toggle={toggle}
      toggleState={
        state === AnalyticsStates.LEGACY
          ? ToggleStates.OPT_OUT
          : ToggleStates.DEFAULT
      }
      bannerTestId={testId}
      buttonPendoId={buttonPendoId}
      translationKey={translationKey}
    />
  );
};
