const assessmentVariantRegistrations = [
  {
    created_at: '2024-11-16T19:10:51Z',
    current_variant: {
      adjudication: null,
      assessment_value: 'eligible',
      id: '92246257595a66843e78e3bc',
    },
    display_label: 'GA Alcohol Test',
    id: '914eafe9d068863d341d7888',
    report_id: 'a8dbd7cb983a01931228d872',
    updated_at: '2024-11-16T19:10:51Z',
  },
  {
    created_at: '2024-11-17T19:10:34Z',
    current_variant: {
      adjudication: {
        decision: 'accepted',
        user: 'spock.firstmate@enterprise.com',
      },
      assessment_value: 'review',
      id: '92246257595a66843e78e3bd',
    },
    display_label: 'VA Driver Test',
    id: '914eafe9d068863d341d7889',
    report_id: 'a8dbd7cb983a01931228d872',
    updated_at: '2024-11-17T19:10:34Z',
  },
];

export default assessmentVariantRegistrations;
