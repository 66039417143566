import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledLearnMoreText = styled.span`
  color: ${colors.uiNavy600};
`;

export const StyledLearnMoreIcon = styled(M.Icon)`
  color: ${colors.uiNavy600};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandNavy4};
`;

export const StyledSectionMargin = styled.div`
  margin-top: 2.5rem;
`;
