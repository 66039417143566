import { LocationDescriptor } from 'history';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Namespace } from 'modules/assess/api';
import { useCriminal } from 'modules/assess/ui/hooks';
import { NamespaceRoute, RootRoute } from 'modules/assess/ui/router/context';
import Missing from 'modules/assess/ui/router/missing';
import Rulesets from './rulesets';

const namespace = Namespace.criminal;

export const Criminal: React.FC = () => {
  const enabled = useCriminal();
  const match = useRouteMatch();
  const root = useContext(RootRoute);
  const { path: base } = match;

  const home: LocationDescriptor = {
    pathname: root,
  };

  if (!enabled) {
    return <Missing />;
  }

  return (
    <NamespaceRoute.Provider value={namespace}>
      <Switch>
        <Route path={`${base}/rulesets`}>
          <Rulesets />
        </Route>
        <Route path={`${base}/:missing`}>
          <Missing />
        </Route>
        <Redirect to={home} />
      </Switch>
    </NamespaceRoute.Provider>
  );
};

export default Criminal;
