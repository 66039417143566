/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Base from 'modules/assess/models/assignables/base';
import { useUser } from 'context/CurrentUser';
import { usePackages } from 'api/packages';
import { useFetchAllNodes } from 'api/nodes';
import { useList } from 'api/geos';
import { useJobRoles } from 'api/jobRoles';
import { useGetStates } from 'api/location';
import { toastError } from 'actions';
import { Ruleset } from 'modules/assess/models/rulesets';
import {
  AssignmentV2,
  SelectedAssignments,
} from 'modules/assess/models/rulesets/assignment';
import Selector from './Selector';
import {
  createAssignmentCombinations,
  getInitialItem,
  mapStateToItems,
  mapToItems,
} from '../assign/hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > h5 {
    color: ${colors.textIconBlackPrimary};
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 0.16px;
    margin-bottom: 0 !important;
  }

  & > p {
    color: ${colors.textIconBlackSecondary78};
    font-size: 0.75rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 0 !important;
  }
`;

const StyledListItem = styled(M.ListItem)`
  color: ${colors.textIconBlackSecondary78};
  font-size: 0.75rem !important;
  font-weight: 400;
`;

const StyledLink = styled(M.Link)`
  color: ${colors.linkDefaultNavy600};
  font-size: 0.75rem !important;
  text-decoration: none;
`;

const Label = styled.p`
  color: ${colors.textIconBlackSecondary78};
  font-size: 0.75rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 500px;
  margin-top: 0 !important;
`;

const Divider = styled(M.Divider)`
  margin: 16px 0 16px 0 !important;
`;

type StateData = { [key: string]: string };

export interface Items {
  account: string[];
  geo: string[];
  segment: string[];
  package: string[];
  role: string[];
  state: string[];
}
type Props = {
  currentItems: SelectedAssignments;
  mode?: 'edit' | 'publish';
  rulesets?: Array<Ruleset.Type>;
  setAssignmentPayload?: React.Dispatch<React.SetStateAction<AssignmentV2[]>>;
  setCurrentItems: React.Dispatch<React.SetStateAction<SelectedAssignments>>;
  setIsTouched?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Edit: React.FC<Props> = ({
  currentItems,
  mode,
  rulesets,
  setAssignmentPayload,
  setCurrentItems,
  setIsTouched,
}) => {
  const { t } = useTranslation('assess');
  const translations: any = t('ruleset.apply', {
    returnObjects: true,
  });
  const publishMode = mode === 'publish';
  const [isAccountDefault, setIsAccountDefault] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(!!publishMode);
  const [statesList, setStatesList] = useState<string[]>([]);
  const [items, setItems] = useState<Items>({
    account: [],
    geo: [],
    segment: [],
    package: [],
    role: [],
    state: [],
  });

  const { account } = useUser();
  const accountId = account?.id || '';

  const existingAccountDefault = rulesets?.find(
    (ruleset: Ruleset.Type) =>
      Array.isArray(ruleset.assignments) &&
      ruleset.assignments.length === 1 &&
      ruleset.assignments[0].id === 'ACCOUNT_DEFAULT_ID',
  );

  const accountDefaultLabel = t(
    `assess:ruleset:apply:selectors:default:label`,
    {
      existing_default: existingAccountDefault?.name,
    },
  );

  const handleGetStatesSuccess = (data: StateData | null) => {
    if (data) {
      const states = Object.values(data);
      const stateItems = mapStateToItems(states);
      setItems(prevItems => ({ ...prevItems, state: stateItems }));
      setStatesList(states);
    }
  };

  const handleGetStatesError = (error: any) => {
    toastError(error);
  };

  const { data: packages, isLoading: packagesIsLoading } = usePackages(
    accountId,
    new URLSearchParams({}),
  );

  const {
    nodes,
    count,
    isLoading: nodesIsLoading,
  } = useFetchAllNodes({ account });

  const { data: geos, isLoading: geosIsLoading } = useList({ accountId });

  const { data: jobRoles, isLoading: jobRolesIsLoading } = useJobRoles({
    accountId,
  });

  useGetStates(handleGetStatesSuccess, handleGetStatesError);

  useEffect(() => {
    if (
      !packagesIsLoading &&
      !nodesIsLoading &&
      !geosIsLoading &&
      !jobRolesIsLoading
    ) {
      setShowLoader(false);
      setIsAccountDefault(currentItems.account.length > 0);
    }
  }, [
    currentItems.account.length,
    geosIsLoading,
    jobRolesIsLoading,
    nodesIsLoading,
    packagesIsLoading,
  ]);

  useEffect(() => {
    if (packages && packages.length > 0) {
      const packageItems = mapToItems(packages);
      setItems(prevItems => ({ ...prevItems, package: packageItems }));
    }
  }, [packages]);

  useEffect(() => {
    if (geos && geos?.geos.length > 0) {
      const geoItems = mapToItems(geos?.geos);
      setItems(prevItems => ({ ...prevItems, geo: geoItems }));
    }
  }, [geos]);

  useEffect(() => {
    if (jobRoles && jobRoles?.job_roles.length > 0) {
      const jobRolesItems = mapToItems(jobRoles?.job_roles);
      setItems(prevItems => ({ ...prevItems, role: jobRolesItems }));
    }
  }, [jobRoles]);

  useEffect(() => {
    if (nodes && count > 0) {
      const nodeItems = mapToItems(nodes);
      setItems(prevItems => ({ ...prevItems, segment: nodeItems }));
    }
    // Disabling: adding nodes to the dependency array causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const showFullDescriptionHandler = () => {
    setShowFullDescription(!showFullDescription);
  };

  const updateAssignmentPayload = useCallback(
    (updatedItems: SelectedAssignments) => {
      const assignmentCombos = createAssignmentCombinations(updatedItems);
      setAssignmentPayload && setAssignmentPayload(assignmentCombos);
      setIsTouched && setIsTouched(true);
    },
    [setAssignmentPayload, setIsTouched],
  );

  const onChange = useCallback(
    (assignmentType: Base.Kind) => (e: any) => {
      const findAndMapAssignments = (
        assignables: any[],
        list: any[],
        type: Base.Kind,
        metadataKeys: any[],
      ) => {
        return assignables.map(assignable => {
          const item = list.find(
            item =>
              item.id?.toString() === assignable?.id ||
              item?.short_name === assignable?.id,
          );
          const metadata = metadataKeys.reduce((acc, key) => {
            acc[`${type.toLowerCase()}_${key}`] = item?.[key];
            return acc;
          }, {});
          return {
            id: item?.id,
            name: item?.name,
            type,
            [type.toLowerCase()]: item,
            metadata,
          };
        });
      };
      const assignmentTypeConfig: {
        [key: string]: {
          list: any[];
          type: Base.Kind;
          metadataKeys: string[];
        };
      } = {
        [Base.Kind.GEO]: {
          list: geos?.geos || [],
          type: Base.Kind.GEO,
          metadataKeys: ['name', 'state'],
        },
        [Base.Kind.ROLE]: {
          list: jobRoles?.job_roles || [],
          type: Base.Kind.ROLE,
          metadataKeys: ['name'],
        },
        [Base.Kind.SEGMENT]: {
          list: nodes || [],
          type: Base.Kind.SEGMENT,
          metadataKeys: ['name'],
        },
        [Base.Kind.PACKAGE]: {
          list: Array.isArray(packages) ? packages : [],
          type: Base.Kind.PACKAGE,
          metadataKeys: ['name'],
        },
        [Base.Kind.STATE]: {
          list: statesList || [],
          type: Base.Kind.STATE,
          metadataKeys: ['name'],
        },
      };
      const config = assignmentTypeConfig[assignmentType as string];
      if (config) {
        const selectedItems = e?.selectedItems;
        const newAssignment = findAndMapAssignments(
          selectedItems,
          config.list,
          config.type,
          config.metadataKeys,
        );
        setCurrentItems(prevState => {
          const updatedItems = {
            ...prevState,
            [config.type]: [...newAssignment],
          };
          updateAssignmentPayload(updatedItems);
          return updatedItems;
        });
      }
    },
    [
      geos?.geos,
      jobRoles?.job_roles,
      nodes,
      packages,
      statesList,
      setCurrentItems,
      updateAssignmentPayload,
    ],
  );

  const handleAccountDefaultChange = () => () => {
    setCurrentItems(prevState => {
      const updatedItems = {
        ...prevState,
        account: isAccountDefault
          ? []
          : [
              {
                id: 'ACCOUNT_DEFAULT_ID',
                name: 'ACCOUNT_DEFAULT_ID',
                type: Base.Kind.ACCOUNT,
                metadata: {},
              },
            ],
      };

      updateAssignmentPayload(updatedItems);

      setIsAccountDefault(!isAccountDefault);

      return updatedItems;
    });
  };

  return (
    <div data-testid='assess-ruleset-apply-edit-section'>
      <h4 data-testid='assess-ruleset-apply-edit-header'>
        {publishMode
          ? translations.sub_headers.edit.title
          : translations.headers.edit}
      </h4>
      <Container>
        <SubHeader>
          <h5 data-testid='assess-ruleset-apply-edit-subheader'>
            {publishMode ? '' : translations.sub_headers.edit.title}
          </h5>
          <p data-testid='assess-ruleset-apply-edit-subheader-description'>
            {translations.sub_headers.edit.short_description}
            {showFullDescription ? (
              <>
                <M.UnorderedList nested>
                  <StyledListItem data-testid='long_description_bullet1'>
                    {translations.sub_headers.edit.bullet_1}
                  </StyledListItem>
                  <StyledListItem data-testid='long_description_bullet2'>
                    {translations.sub_headers.edit.bullet_2}
                  </StyledListItem>
                </M.UnorderedList>
                <StyledLink onClick={showFullDescriptionHandler} href='#'>
                  {translations.sub_headers.edit.see_less}
                </StyledLink>
              </>
            ) : (
              <StyledLink onClick={showFullDescriptionHandler} href='#'>
                {translations.sub_headers.edit.see_more}
              </StyledLink>
            )}
          </p>
        </SubHeader>
        {showLoader ? (
          <M.LoadingSpinner data-testid='loading-spinner' />
        ) : (
          <>
            {items.role.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.role}
                initialSelectedItems={getInitialItem(
                  currentItems,
                  Base.Kind.ROLE,
                )}
                items={items.role}
                labels={{
                  label: translations.selectors.role.label,
                  long_label: translations.selectors.role.long_label,
                  single_label: translations.selectors.role.single_label,
                  plural_label: translations.selectors.role.plural_label,
                }}
                onChange={onChange(Base.Kind.ROLE)}
                testId='assess-ruleset-apply-role-selector'
              />
            )}
            {items.package.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.package}
                initialSelectedItems={getInitialItem(
                  currentItems,
                  Base.Kind.PACKAGE,
                )}
                items={items.package}
                labels={{
                  label: translations.selectors.package.label,
                  long_label: translations.selectors.package.long_label,
                  single_label: translations.selectors.package.single_label,
                  plural_label: translations.selectors.package.plural_label,
                }}
                onChange={onChange(Base.Kind.PACKAGE)}
                testId='assess-ruleset-apply-package-selector'
              />
            )}
            {items.segment.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.segment}
                initialSelectedItems={getInitialItem(
                  currentItems,
                  Base.Kind.SEGMENT,
                )}
                items={items.segment}
                labels={{
                  label: translations.selectors.node.label,
                  long_label: translations.selectors.node.long_label,
                  single_label: translations.selectors.node.single_label,
                  plural_label: translations.selectors.node.plural_label,
                }}
                onChange={onChange(Base.Kind.SEGMENT)}
                testId='assess-ruleset-apply-node-selector'
              />
            )}
            {items.geo.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.geo}
                initialSelectedItems={getInitialItem(
                  currentItems,
                  Base.Kind.GEO,
                )}
                items={items.geo}
                labels={{
                  label: translations.selectors.geo.label,
                  long_label: translations.selectors.geo.long_label,
                  single_label: translations.selectors.geo.single_label,
                  plural_label: translations.selectors.geo.plural_label,
                }}
                onChange={onChange(Base.Kind.GEO)}
                testId='assess-ruleset-apply-geo-selector'
              />
            )}
            {items.state.length > 0 && (
              <Selector
                currentSelectedItems={currentItems.state}
                initialSelectedItems={getInitialItem(
                  currentItems,
                  Base.Kind.STATE,
                )}
                items={items.state}
                labels={{
                  label: translations.selectors.state.label,
                  long_label: translations.selectors.state.long_label,
                  single_label: translations.selectors.state.single_label,
                  plural_label: translations.selectors.state.plural_label,
                }}
                onChange={onChange(Base.Kind.STATE)}
                testId='assess-ruleset-apply-state-selector'
              />
            )}
          </>
        )}
      </Container>
      <Divider />
      <M.Toggle
        data-testid='assess-ruleset-apply-default-toggle'
        id='assess-ruleset-apply-default-toggle'
        labelA={<Label>{accountDefaultLabel}</Label>}
        labelB={<Label>{accountDefaultLabel}</Label>}
        onToggle={handleAccountDefaultChange()}
        toggled={isAccountDefault}
      />
    </div>
  );
};

export default Edit;
