import React from 'react';
import { getScreeningType } from 'components/Packages/PackageScreenings';
import { getAdditionalPropertiesLabel } from 'components/AddScreenings/shared/utils';
import { BulletPoint } from 'components/AddScreenings/Domestic/shared/SummaryStyles';
import { employerCurrentOrLast10Years } from 'components/AddScreenings/Domestic/List/VerificationScreenings/EmploymentVerification';
import { StyledScreeningListItem } from 'components/BetterOrderExperience/SelectPackageStep/styles/PackageCard.styles';
import { useTranslation } from 'react-i18next';
import {
  StyledFlexWithMargin,
  ScreeningType,
  StyledFlex,
  StyledPriceLabel,
} from './CustomizationScreeningItem.styles';
import type { AddonsT } from '../..';
import { namespace } from '../../../locales';

const CustomizationScreeningItem: React.FC<{
  additionalProperties: AddonsT.AdditionalProperties;
  addedScreeningTypeWithPrices: AddonsT.ScreeningTypeWithPrices;
}> = ({ additionalProperties, addedScreeningTypeWithPrices }) => {
  const { t } = useTranslation(namespace);

  const { screening } = addedScreeningTypeWithPrices;

  const screeningTypeLabel = getScreeningType(screening.type);

  const additionalPropertyLabels = getAdditionalPropertiesLabel(
    screening.type,
    additionalProperties,
  );

  return (
    <>
      <StyledScreeningListItem data-testid='customization-screening-item'>
        <StyledFlex>
          <ScreeningType>{screeningTypeLabel}</ScreeningType>
          <StyledPriceLabel>
            {addedScreeningTypeWithPrices.price}
          </StyledPriceLabel>
        </StyledFlex>
      </StyledScreeningListItem>
      {additionalPropertyLabels.map((label: string) => (
        <StyledFlexWithMargin key={label}>
          <BulletPoint />
          {label === employerCurrentOrLast10Years.label
            ? t('orderSummary.mostRecent')
            : label}
        </StyledFlexWithMargin>
      ))}
    </>
  );
};

export default CustomizationScreeningItem;
