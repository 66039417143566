import React, { useEffect, useState } from 'react';
import { StyledBreadcrumbItemContainer } from './Breadcrumbs.styles';
import { Step } from './Breadcrumbs.types';

type BreadcrumbItemProps = {
  title: string;
  stepNumber: number;
  stepName: string;
  currentStep: Step;
};

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  title,
  stepNumber,
  stepName,
  currentStep,
}) => {
  const [visited, setVisited] = useState(false);
  const isCurrentPage = stepName === currentStep?.name;

  useEffect(() => {
    if (stepNumber > currentStep?.position) {
      setVisited(false);
    } else {
      setVisited(true);
    }
  }, [currentStep?.position, stepNumber]);

  useEffect(() => {
    if (isCurrentPage) setVisited(true);
  }, [isCurrentPage]);

  return (
    <StyledBreadcrumbItemContainer
      data-testid={`bread-crumb-${title.replace(' ', '-')}`}
      visited={visited}
      isCurrentPage={isCurrentPage}
    >
      {title}
    </StyledBreadcrumbItemContainer>
  );
};

export default BreadcrumbItem;
