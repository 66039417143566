import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledUploadBox = styled.div`
  align-items: center;
  background-color: ${colors.bgPrimaryLight};
  border-radius: 6px;
  border: 1px solid ${colors.borderPrimaryLight};
  display: flex;
  min-width: 20rem;
  max-width: 30rem;
  padding: 1rem 1.5rem 1rem 1rem;
  position: relative;
`;

export const StyledUploadedBox = styled(StyledUploadBox)`
  padding: 0.5rem 1.5rem 0.5rem 1rem;
`;

export const StyledUploadBoxLabel = styled.div`
  font-size: 0.875rem;
  color: ${colors.uiTextPrimaryLight};
  line-height: 1.25rem;
  font-weight: 700;

  .file-size {
    font-weight: 400;
    color: ${colors.uiTextSecondaryLight};
    margin-left: 0.5rem;
  }
`;

export const StyledUploadBoxDetails = styled.div`
  font-size: 0.875rem;
  color: ${colors.uiTextSecondaryLight};
  line-height: 1.25rem;
  margin-top: 0.25rem;
`;

export const StyledUploadIcon = styled.div`
  align-items: center;
  background-color: ${colors.bgSecondaryLight};
  border-radius: 6px;
  border: 1px dashed ${colors.borderPrimaryLight};
  color: ${colors.grey600};
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 1rem;
  width: 48px;
`;

export const StyledRemoveIcon = styled.div`
  color: ${colors.grey600};
  margin-right: 1rem;

  svg {
    vertical-align: bottom;
  }
`;

export const StyledRemoveButton = styled(M.Button)`
  margin-left: auto !important;
  color: ${colors.uiButtonDestructiveLightDefault} !important;
`;

export const StyledUploadInput = styled(M.FileInput)`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
`;
