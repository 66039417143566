import { M, colors } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useUpdateAccountSettings } from 'components';
import { useUpdateSettings } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { SHOW_REPORT_SUMMARY } from 'Flags';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { REPORT_SETTING_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { useGetUserAccountSettings } from '../../api/accountSettings/hooks';

const StyledForm = styled(M.Form)`
  color: ${colors.uiTextPrimaryLight};

  p {
    max-width: 659px;
  }
`;

const StyledCheckboxBox = styled.div`
  .cds--checkbox-wrapper {
    margin-top: 0.75rem;
  }
`;

const ReportSettings: React.FC = () => {
  const user = useUser();
  const trackEvent = useTrackEvent();
  const { account } = user;
  const showReportSummarySetting =
    useFlag(SHOW_REPORT_SUMMARY)?.variantKey === 'on';

  const { accountSettings, isLoading, isFetching } = useGetUserAccountSettings(
    user,
    showReportSummarySetting,
  );
  const updateSettings = useUpdateSettings();

  const { hide_report_summary } = accountSettings;

  const initialShowReportSummary = !hide_report_summary;

  const [showReportSummary, setShowReportSummary] = useState(
    initialShowReportSummary,
  );

  useEffect(() => {
    setShowReportSummary(initialShowReportSummary);
  }, [initialShowReportSummary]);

  const { call: updateAccountCall } = useUpdateAccountSettings();

  const toggleReportSummary = useCallback(() => {
    const newValue = !showReportSummary;
    setShowReportSummary(newValue);
    trackEvent(
      REPORT_SETTING_EVENT_NAMES.REPORT_SETTINGS_ACCOUNT_SETTINGS_TAB_SHOW_REPORT_SUMMARY_CLICKED,
      {
        'Show Report Summary Clicked': !newValue,
      },
    );
    const changedSettings = { hide_report_summary: !newValue };
    updateSettings({ hideReportSummary: !newValue });
    updateAccountCall({ changedSettings, account });
  }, [
    showReportSummary,
    trackEvent,
    updateSettings,
    updateAccountCall,
    account,
  ]);

  return (
    <StyledForm id='report-settings' data-testid='report-settings-form'>
      <h3>Manage Report View</h3>
      {isLoading || isFetching ? (
        <M.LoadingInline description='Loading...' />
      ) : (
        <StyledCheckboxBox>
          <M.Checkbox
            onChange={toggleReportSummary}
            name='showReportSummary'
            labelText='Show report summary'
            disabled={false}
            hideLabel={false}
            id='showReportSummaryAccountSetting'
            checked={showReportSummary}
            data-testid='show-report-summary-checkbox'
          />
        </StyledCheckboxBox>
      )}
    </StyledForm>
  );
};

export default ReportSettings;
