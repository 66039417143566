import React, { useCallback, useMemo } from 'react';
import includes from 'lodash/includes';
import * as Countries from 'i18n-iso-countries';
import type { GetStartedT } from 'components/BetterOrderExperience';
import { StyledCountriesDropdown } from './Dropdown.styles';

const COUNTRIES_NOT_AVAILABLE = ['AF'];

export const countryItemToString = (item: string) => {
  const countryName = Countries.getName(item, 'en') || item || '';

  if (includes(COUNTRIES_NOT_AVAILABLE, item)) {
    return `${countryName} (Unavailable)`;
  }

  return countryName;
};

type Props = {
  countries: string[];
  selectedLocation: GetStartedT.WorkLocation;
  setSelectedLocation: (location: GetStartedT.WorkLocation) => void;
  disabled?: boolean;
};

const CountryDropdown: React.FC<Props> = ({
  countries = ['US'],
  selectedLocation,
  setSelectedLocation,
  disabled = false,
}) => {
  const sortedCountries = useMemo(() => {
    return countries.sort((a, b) => {
      return countryItemToString(a as string).localeCompare(
        countryItemToString(b as string),
      );
    });
  }, [countries]);

  const handleCountryChange = useCallback(
    ({ selectedItem }) => {
      setSelectedLocation({
        country: selectedItem,
        state: undefined,
        city: undefined,
      });
    },
    [setSelectedLocation],
  );

  return (
    <StyledCountriesDropdown
      data-testid='countries-dropdown'
      id='countries-dropdown'
      items={sortedCountries}
      disabled={disabled}
      itemToString={countryItemToString}
      onChange={handleCountryChange}
      selectedItem={selectedLocation.country ?? ''}
      titleText=''
      label='Country'
    />
  );
};

export default CountryDropdown;
