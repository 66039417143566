import React from 'react';
import { useTranslation } from 'react-i18next';
import { M } from '@dashboard-experience/mastodon';
import { namespace } from 'components/BetterOrderExperience/locales';

type Props = {
  infoSource: string;
  manualOrderType?: string;
  infoMethod?: string;
  bulkHistoryUrl: string;
};

const ReviewAndSubmitSubtitle: React.FC<Props> = ({
  infoSource,
  manualOrderType,
  infoMethod,
  bulkHistoryUrl,
}) => {
  const { t } = useTranslation(namespace, { keyPrefix: 'reviewAndSubmit' });
  const isCandidateInvite =
    infoSource === 'CANDIDATE' && infoMethod === 'MANUAL';
  const isCandidateBulkInvite =
    infoSource === 'CANDIDATE' && infoMethod === 'UPLOAD';
  const isBulkManualOrder =
    infoSource === 'MYSELF' && manualOrderType === 'MULTIPLE';

  if (isCandidateInvite) {
    return <>{t('candidateSubtitle')}</>;
  }
  if (isCandidateBulkInvite || isBulkManualOrder) {
    return (
      <>
        {t('uploadSubtitle')}{' '}
        <M.Link
          data-testid='upload-subtitle-link'
          href={bulkHistoryUrl}
          target='_blank'
          size='lg'
        >
          {t('subtitleLinkText')}
        </M.Link>
        . {isCandidateBulkInvite && t('candidateBulkUploadSubtitle')}
      </>
    );
  }

  return <> {t('defaultSubtitle')}</>;
};

export default ReviewAndSubmitSubtitle;
