import React, { useMemo } from 'react';
import PostHireContainer from 'containers/PostHire/PostHireContainer';
import CandidateInformation from 'components/Report/CandidateInformation/CandidateInformation';
import SecondaryPanelInvitations from 'modules/adjudication/ui/report/actions/secondary-panel-invitations';
import { useRouteMatch } from 'react-router-dom';
// TODO: migrate Invitations to module
import Invitation from 'components/Candidate/Invitation';
import { useBetaEnabled } from 'providers/Beta';
import { CANDIDATE_REDESIGN, SHOW_REPORT_SUMMARY } from 'Flags';
import { useGetInvitationIdFromRoute } from 'modules/candidate/utilities';
import { useSettings } from 'containers/Report';
import { useFlag } from '@dashboard-experience/react-flagr';
import ReportProgressSummary from './report-progress-summary';

type MatchParams = {
  candidateId: string;
  reportId: string;
  invitationId: string;
};

const Information: React.FC = () => {
  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);
  const showReportSummaryFlag =
    useFlag(SHOW_REPORT_SUMMARY)?.variantKey === 'on';

  const reportMatch = useRouteMatch<MatchParams>(
    '/candidates/:candidateId/reports/:reportId',
  );

  const reportId = reportMatch?.params?.reportId || '';
  const invitationId = useGetInvitationIdFromRoute();

  const { hideReportSummary } = useSettings();

  const shouldRenderReportProgressSummary = useMemo(() => {
    return (
      (candidateRedesignEnabled && reportId && !showReportSummaryFlag) ||
      (candidateRedesignEnabled &&
        reportId &&
        showReportSummaryFlag &&
        !hideReportSummary)
    );
  }, [
    candidateRedesignEnabled,
    reportId,
    showReportSummaryFlag,
    hideReportSummary,
  ]);

  return (
    <div data-testid='candidate-information-container'>
      {shouldRenderReportProgressSummary && (
        <ReportProgressSummary reportId={reportId} />
      )}
      {!candidateRedesignEnabled && <CandidateInformation />}
      {candidateRedesignEnabled && (
        <SecondaryPanelInvitations invitationId={invitationId} />
      )}
      {!candidateRedesignEnabled && <PostHireContainer />}
      <Invitation invitationId={invitationId} />
    </div>
  );
};

export default Information;
