import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBetterOrderExperienceContext } from 'pages/BetterOrderExperience/context';
import {
  getDisplayPrice,
  getServiceAndPassthroughFees,
} from 'components/BetterOrderExperience/utils/PricingUtils';
import { hasPermission } from '@dashboard-experience/utils';
import { useUser } from 'context/CurrentUser';
import LearnMoreLink from 'components/AddScreenings/shared/LearnMoreLink';
import ThirdPartyFees from 'components/AddScreenings/shared/ThirdPartyFees';
import { isPackageNameInvalid } from 'components/Packages/CreatePackage/CreatePackageReducer';
import { buildPostBodyWithAddOns } from 'components/AddScreenings/shared/utils';
import { usePackagePrice } from 'components/AddScreenings/hooks/usePackagePrice';
import { namespace } from '../../locales';
import {
  StyledSection,
  StyledSummaryContainer,
  StyledBoldLinePrice,
  StyledSubtotalHeading,
  StyledCheckbox,
  StyledSaveForNextTimeContainer,
  StyledSavePackageNameContainer,
  StyledTextInput,
} from './OrderSummary.styles';
import type { AddonsT } from '..';
import SelectedPackageSection from './Sections/SelectedPackageSection';
import CustomizationsSection from './Sections/CustomizationsSection';

const OrderSummary: React.FC<{
  newPackageName: string | undefined;
  setNewPackageName: (name: string) => void;
  saveForNextTime: boolean;
  setSaveForNextTime: (save: boolean) => void;
  addedScreeningTypeWithPrices: AddonsT.ScreeningTypeWithPrices[];
  additionalProperties: AddonsT.AdditionalProperties;
}> = ({
  newPackageName,
  setNewPackageName,
  saveForNextTime,
  setSaveForNextTime,
  addedScreeningTypeWithPrices,
  additionalProperties,
}) => {
  const { t } = useTranslation(namespace);
  const currentUser = useUser();
  const canManagePackages = hasPermission(currentUser, 'manage_packages');

  const [newPackageNameInvalid, setNewPackageNameInvalid] = useState(false);

  const { selectedPackage, update } = useBetterOrderExperienceContext();

  const addedScreeningTypes = addedScreeningTypeWithPrices.map(
    s => s.screening.type,
  );

  const prices = addedScreeningTypeWithPrices.map(p => {
    if (p.price === 'Price varies') {
      return 0;
    }
    if (typeof p.price === 'string') {
      return parseFloat(p.price.replace('$', '')) * 100;
    }
    return p.price;
  });

  const addedScreeningTypePrices = prices.reduce(
    (price, sum) => price + sum,
    0,
  );

  const queryPackage = buildPostBodyWithAddOns({
    basePackage: {
      ...selectedPackage,
    },
    addedScreeningTypes,
    additionalProperties,
    packageName: selectedPackage.name,
    setSlug: true,
    isPrivate: false,
  });

  const pendingAddOnPricesQuery = usePackagePrice(
    [
      'pending-addon-on-prices',
      selectedPackage.name,
      addedScreeningTypes,
      additionalProperties,
      true,
    ],
    currentUser?.account,
    queryPackage,
  );

  const fees = useMemo(
    () =>
      getServiceAndPassthroughFees({
        addonPricesQueryData: pendingAddOnPricesQuery?.data,
      }),
    [pendingAddOnPricesQuery, selectedPackage],
  );

  const packagePrice = selectedPackage.price;

  const handlePackageNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewPackageName(e.target.value);
      setNewPackageNameInvalid(isPackageNameInvalid(e.target.value));
    },
    [setNewPackageName],
  );

  const handleSaveForNextTimeCheckboxClick = useCallback(() => {
    setSaveForNextTime(!saveForNextTime);
  }, [saveForNextTime]);

  useEffect(() => {
    const minTotalFees = fees.passthroughFeeMin + fees.serviceFeeMin;
    const maxTotalFees = fees.passthroughFeeMax + fees.serviceFeeMax;
    update({
      pricesSummary: {
        packagePrice,
        addedScreeningTypePrices,
        minTotalFees,
        maxTotalFees,
        subtotal: pendingAddOnPricesQuery?.data?.fixed_price,
        aliasPrice: 0,
      },
    });
  }, [
    addedScreeningTypePrices,
    fees.passthroughFeeMax,
    fees.passthroughFeeMin,
    fees.serviceFeeMax,
    fees.serviceFeeMin,
    packagePrice,
    pendingAddOnPricesQuery?.data?.fixed_price,
    update,
  ]);

  return (
    <StyledSummaryContainer>
      <h4 className='mb-0'>{t('orderSummary.header')}</h4>
      <SelectedPackageSection
        selectedPackage={selectedPackage}
        packagePrice={packagePrice}
      />

      {addedScreeningTypeWithPrices.length > 0 && (
        <CustomizationsSection
          addedScreeningTypeWithPrices={addedScreeningTypeWithPrices}
          additionalProperties={additionalProperties}
        />
      )}
      <StyledSection>
        <StyledBoldLinePrice className='bold-line-price'>
          <StyledSubtotalHeading>
            {t('orderSummary.subtotal')}
          </StyledSubtotalHeading>
          <StyledSubtotalHeading
            className='subtotal-heading'
            data-testid='summary-section-subtotal-price'
          >
            {selectedPackage.international_only ? (
              <LearnMoreLink
                linkText='Price varies'
                href='https://checkr.com/pricing/international'
                data-testid='learn-more-link-international-price-varies'
              />
            ) : (
              getDisplayPrice(pendingAddOnPricesQuery?.data?.fixed_price)
            )}
          </StyledSubtotalHeading>
        </StyledBoldLinePrice>
      </StyledSection>
      <ThirdPartyFees
        serviceFeeMin={fees.serviceFeeMin}
        serviceFeeMax={fees.serviceFeeMax}
        passthroughFeeMin={fees.passthroughFeeMin}
        passthroughFeeMax={fees.passthroughFeeMax}
      />
      {canManagePackages && (
        <StyledSaveForNextTimeContainer data-testid='save-for-next-time-container'>
          <StyledCheckbox
            data-testid='save-for-next-time-checkbox'
            id='save-for-next-time-checkbox'
            checked={saveForNextTime}
            labelText={t('orderSummary.saveForNextTime')}
            onChange={handleSaveForNextTimeCheckboxClick}
          />

          {saveForNextTime && (
            <StyledSavePackageNameContainer>
              <StyledTextInput
                data-testid='name-your-package-textinput'
                id='name-your-package-textinput'
                labelText={t('orderSummary.nameYourPackage')}
                value={newPackageName}
                invalid={newPackageNameInvalid}
                onChange={handlePackageNameChange}
                invalidText={t('orderSummary.invalidNameText')}
              />
            </StyledSavePackageNameContainer>
          )}
        </StyledSaveForNextTimeContainer>
      )}
    </StyledSummaryContainer>
  );
};

export default OrderSummary;
