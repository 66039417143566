import { M } from '@dashboard-experience/mastodon';
import React, { useCallback, useState } from 'react';
import { FetchPackageType } from '@dashboard-experience/utils';
import {
  StyledScreeningList,
  StyledScreeningListWrapper,
  StyledShowMoreLink,
} from './PackageChoiceCard.styles';
import { Screening } from '../../../../../types';
import AliasTag from '../../../../Packages/Alias/AliasTag';
import { filteredBasePackageScreenings } from '../../SelectPackageUtils';
import PackageScreeningItem from '../../PackageScreeningItem';

const SCREENINGS_CUTOFF = 7;

type ScreeningListProps = {
  showMoreMobile: boolean;
  packageData: FetchPackageType;
  idx: number;
};

const ScreeningListContainer: React.FC<ScreeningListProps> = ({
  showMoreMobile,
  packageData,
  idx,
}) => {
  const [showMore, setShowMore] = useState(false);

  const basePackageScreenings = filteredBasePackageScreenings(packageData);

  const handleLinkClick = useCallback((event: Event) => {
    event.stopPropagation();
    setShowMore(true);
  }, []);

  return (
    <StyledScreeningListWrapper showMoreMobile={showMoreMobile}>
      <StyledScreeningList data-testid={`screening-ul-${idx}`}>
        {basePackageScreenings
          ?.slice(0, showMore ? undefined : SCREENINGS_CUTOFF)
          .map(screening => (
            <PackageScreeningItem
              key={screening.type}
              screening={screening as Screening}
              screeningSettings={packageData?.screening_settings ?? []}
            />
          ))}
      </StyledScreeningList>
      {basePackageScreenings &&
        !showMore &&
        basePackageScreenings?.length > SCREENINGS_CUTOFF && (
          <StyledShowMoreLink visited={false} onClick={handleLinkClick}>
            Show more
            <M.Icon size='20' icon='ChevronDown' />
          </StyledShowMoreLink>
        )}
      <AliasTag basePackage={packageData} />
    </StyledScreeningListWrapper>
  );
};

export default ScreeningListContainer;
