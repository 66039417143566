import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import styled from 'styled-components';
import { useFetchAssessmentVariantRegitrations } from 'api/assessmentVariants/hooks';
import AssessmentDropdownItems from './all-assessments-dropdown-items';

type Props = {};

const AllAssessmentsButton = styled(M.Button)`
  & > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const DropdownTrigger = (number: number) => {
  return (
    <AllAssessmentsButton kind='tertiary'>
      <span>All roles ({number})</span>
      <M.Icon icon='ChevronDown' size='20' />
    </AllAssessmentsButton>
  );
};

const AssessmentsDropdown: React.FC<Props> = () => {
  const { data } = useFetchAssessmentVariantRegitrations();
  if (!data || data?.length < 1) return null;

  return (
    <M.ActionMenu
      trigger={DropdownTrigger(data?.length)}
      className='all-assessments-action-menu'
      position='bottom'
      size='l'
      shouldCloseOnItemSelect
    >
      <AssessmentDropdownItems options={data} />
    </M.ActionMenu>
  );
};

export default AssessmentsDropdown;
