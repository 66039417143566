/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledIframe = styled.iframe`
  width: 100%;

  &.adjudication {
    min-height: 7075px;
  }

  &.continuous {
    min-height: 2475px;
  }

  &.criminal {
    min-height: 3225px;
  }

  &.mvr {
    min-height: 3475px;
  }

  &.overview {
    min-height: 3775px;
  }

  &.verifications {
    min-height: 3385px;
  }
`;
