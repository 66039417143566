import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import {
  formatDateIfDate,
  GenericObject,
  getStatusTypeForReport,
  StatusTypes,
} from '@dashboard-experience/utils';
import { useReport } from 'containers/Report';
import { useUser } from 'context/CurrentUser';
import { DRIVER_LICENSE_HISTORY_DISPLAY } from 'Constants';
import AdditionalInfoPanel from './AdditionalInfoPanel';

type Props = {
  item?: GenericObject;
};

export const StatusIcon = styled(M.StatusIcon)`
  display: inline-flex !important;
  margin-right: 0.5rem !important;
  vertical-align: text-bottom;
`;

const DrivingExperience: React.FC<Props> = () => {
  const currentUser = useUser();
  const report = useReport();

  const license_history = report?.motor_vehicle_report?.license_history;
  const oldest_issue_date = license_history?.oldest_issue_date;

  const flagrEnabled =
    useFlag(DRIVER_LICENSE_HISTORY_DISPLAY)?.variantKey === 'true';

  if (!flagrEnabled || !license_history || !oldest_issue_date) {
    return null;
  }

  const decision = license_history?.assessment?.display?.decision;

  const title = (
    <>
      {decision && <StatusIcon icon={decision} />}
      <span className='heading-text-small bold'>Driving Experience</span>
    </>
  );

  const items = [
    {
      itemKey: 'Inferred license issue date',
      itemValue: formatDateIfDate(oldest_issue_date),
    },
  ];

  // @ts-ignore TODO: Update to consume Report type from Utils once is updated
  const userType = getStatusTypeForReport(report, currentUser);
  const assessEnabled = userType === StatusTypes.Assess;

  return (
    <M.Expandable
      title={title}
      initialExpanded={false}
      data-testid='mvr-driving-experience'
    >
      <M.KeyValueList items={items} />
      <AdditionalInfoPanel
        licenseReportOrCharge={license_history}
        // @ts-ignore TODO: Update to consume Report type from Utils once is updated
        report={report}
        assessEnabled={assessEnabled}
        currentUser={currentUser}
        panelType='driving-experience'
      />
    </M.Expandable>
  );
};

export default DrivingExperience;
