import {
  accountHasPermission,
  hasPermission,
} from '@dashboard-experience/utils';
import {
  AnalyticsEvent,
  PAYMENT_AND_BILLING_NAMES,
  SIDE_MENU_NAVIGATION_NAMES,
} from '../../utils';
import { getDashboardUrl } from '../../utils/URLHelper';
import {
  BETTER_DASHBOARD_ORDER_EXPERIENCE,
  BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY,
  FAIR_CHANCE_FLAGR_KEY,
  ORDER_QUEUING_SUPPORT_FLAG_KEY,
  PARS_UI_COMPONENTS,
  SHOW_CONTINUOUS_CHECKS_TAB,
  SHOW_HIDE_PACKAGES_MENU_FLAG_KEY,
} from '../../Flags';
import { HELP_CENTER_URL, I9_ALLOWED_USER_ROLES } from '../../Constants';

type FlagStates = { [key: string]: undefined | { variantKey: string } };

// Permission user to see a tab
type PermissionFunction = (user: any, flags: FlagStates) => boolean;

// returns the key (if any) to track on click
type TrackingFunction = (user: any, flags: any) => undefined | AnalyticsEvent;

// Different url depending on user, in case they have different subtabs for a view
// i.e. Screening -> /screening/geos or /screening/packages depending on user permissions
type UrlFunction = (user: any, flags: FlagStates) => string;

const always: PermissionFunction = () => true;

const canSeeFullDashboard = (user: any) =>
  accountHasPermission(user, 'see_full_dashboard');

const canViewScreeningPackages = (user: any) =>
  canSeeFullDashboard(user) &&
  hasPermission(user, 'manage_packages') &&
  accountHasPermission(user, 'self_serve_packages_enabled');

const canViewScreeningGeos = (user: any) =>
  (hasPermission(user, 'manage_geos') &&
    !accountHasPermission(user, 'segmentation_enabled')) ||
  user?.roles?.includes('internal_admin');

const restrictedAccounts: Array<string> = [
  'amazon',
  'amazon-dsp',
  'sparkdriver',
  'uber_eats',
  'uber',
  'uberblack',
];

const canViewCheckrXProducts = (user: any) => {
  // User can be null in the case of the FOX signup flow
  if (!user?.account) {
    return true;
  }

  // verifications team custom user roles with manage_post_hire_drug permission only should
  // not be able to see more products
  const hasOnlyPostHireDrugPermission =
    hasPermission(user, 'manage_post_hire_drug') &&
    user.permissions &&
    Object.keys(user.permissions).length === 2;

  if (hasOnlyPostHireDrugPermission) {
    return false;
  }

  return !restrictedAccounts.includes(user.account.uri_name);
};

export const canCreateManualOrders = (
  createManualOrder: boolean,
  manageInvitations: boolean,
  allowReportOrdering: boolean,
) => {
  return (createManualOrder || manageInvitations) && allowReportOrdering;
};

export type Tab = {
  url: string | UrlFunction; // url to go to when clicking the tab, function if it depends on user permission (i.e. subtabs)
  external?: boolean; // link to another site, i.e. internal dashboard
  tracking?: TrackingFunction; // Track tab clicks
  activePrefix?: string; // path prefix to decide if the tab should be rendered as currently active. If omitted, uses url
  authorized: PermissionFunction; // permission function to see the tab
  openNewTab?: boolean; // specifies where to open the linked url: In a new tab or in itself (default)
  icon?: boolean;
  dataPendoId?: boolean;
};

export type Tabs = { [key: string]: Tab };

export const FIRST_TABS: Tabs = {
  dashboard: {
    url: getDashboardUrl('/'),
    activePrefix: '/home',
    authorized: user => {
      return accountHasPermission(user, 'is_self_service_account');
    },
    tracking: () => {
      return SIDE_MENU_NAVIGATION_NAMES.HOME_NAVIGATION_CLICKED;
    },
  },
  case_management: {
    url: getDashboardUrl('/cm/tasks'),
    activePrefix: '/cm/tasks',
    authorized: user =>
      canSeeFullDashboard(user) &&
      accountHasPermission(user, 'case_management') &&
      (hasPermission(user, 'case_management_read_all') ||
        hasPermission(user, 'case_management_manage_all')),
    dataPendoId: true,
  },
  candidates: {
    url: getDashboardUrl('/candidates'),
    activePrefix: '/candidates',
    authorized: user => {
      return hasPermission(user, 'view_candidates_list');
    },
    dataPendoId: true,
  },
  order_background_check: {
    url: (user, flags) => {
      return flags[BETTER_DASHBOARD_ORDER_EXPERIENCE]?.variantKey === 'on'
        ? getDashboardUrl('/order-experience?from=nav')
        : getDashboardUrl('/order/get-started?from=nav');
    },
    activePrefix: '/order',
    authorized: (user, flags) => {
      const flagVerification =
        flags[ORDER_QUEUING_SUPPORT_FLAG_KEY]?.variantKey === 'on';
      const canOrderReportViaDashbord = accountHasPermission(
        user,
        'allow_dashboard_report_ordering',
      );
      const isAuthorized = accountHasPermission(user, 'authorized');
      const isPartner = accountHasPermission(user, 'is_partner_account');
      const createManualOrders = canCreateManualOrders(
        hasPermission(user, 'create_manual_orders'),
        hasPermission(user, 'manage_invitations'),
        accountHasPermission(user, 'allow_dashboard_report_ordering'),
      );
      const isFromGoodHire = user?.account?.referrer
        ? user?.account?.referrer.startsWith('gh')
        : false;
      return (
        canSeeFullDashboard(user) &&
        canOrderReportViaDashbord &&
        createManualOrders &&
        (!flagVerification || isAuthorized || (!isPartner && !isFromGoodHire))
      );
    },
    dataPendoId: true,
  },
  packages: {
    url: getDashboardUrl('/packages'),
    activePrefix: '/packages',
    authorized: (user, flags) =>
      flags[SHOW_HIDE_PACKAGES_MENU_FLAG_KEY]?.variantKey === 'on' &&
      (hasPermission(user, 'manage_packages') ||
        hasPermission(user, 'read_dashboard_homepage')) &&
      canSeeFullDashboard(user),
    dataPendoId: true,
  },
  continuous_services: {
    url: getDashboardUrl('/continuous_services'),
    activePrefix: '/continuous_services',
    authorized: (user, flags) => {
      const canViewContinuousServices = hasPermission(
        user,
        'read_dashboard_continuous_services',
      );
      const continuousMvrEnabled = accountHasPermission(
        user,
        'continuous_check_mvr',
      );
      const continuousCrimEnabled = accountHasPermission(
        user,
        'continuous_monitoring',
      );
      const continuousFacisEnabled = accountHasPermission(
        user,
        'continuous_facis',
      );
      const hasContinuousSettings =
        continuousMvrEnabled || continuousCrimEnabled || continuousFacisEnabled;
      const showContinuesTab =
        flags[SHOW_CONTINUOUS_CHECKS_TAB]?.variantKey === 'on';

      return (
        canSeeFullDashboard(user) &&
        canViewContinuousServices &&
        (hasContinuousSettings || showContinuesTab)
      );
    },
    dataPendoId: true,
  },
  adverse_actions: {
    url: getDashboardUrl('/adverse_actions'),
    activePrefix: '/adverse_actions',
    authorized: user =>
      canSeeFullDashboard(user) &&
      hasPermission(user, 'perform_adjudication') &&
      (!accountHasPermission(user, 'offline_adverse_action_enabled') ||
        accountHasPermission(user, 'adverse_action_pause')),
    dataPendoId: true,
  },
  assess: {
    url: getDashboardUrl('/assess'),
    activePrefix: '/assess',
    authorized: user =>
      canSeeFullDashboard(user) &&
      (user?.roles?.includes('admin') || // TODO - migrated verbatim, role checks should be removed
        user?.roles?.includes('internal_admin') ||
        hasPermission(user, 'assess_view')),
    dataPendoId: true,
  },
  fair_chance: {
    url: getDashboardUrl('/fair_chance'),
    activePrefix: '/fair_chance',
    authorized: (user, flags) =>
      flags[FAIR_CHANCE_FLAGR_KEY]?.variantKey === 'on' &&
      hasPermission(user, 'read_candidate_details'),
    dataPendoId: true,
  },
  analytics: {
    url: getDashboardUrl('/analytics/overview'),
    activePrefix: '/analytics',
    authorized: user =>
      canSeeFullDashboard(user) && hasPermission(user, 'read_analytics'),
    dataPendoId: true,
  },
  logs: {
    url: getDashboardUrl('/logs/api'),
    activePrefix: '/logs',
    authorized: user =>
      canSeeFullDashboard(user) &&
      (hasPermission(user, 'read_api_logs') ||
        hasPermission(user, 'read_webhook_logs')),
    dataPendoId: true,
  },
  screening: {
    url: user => {
      // tab url precedence migrated verbatim from dashboard
      if (canViewScreeningPackages(user)) {
        return getDashboardUrl('/screening/packages');
      }
      if (canViewScreeningGeos(user)) {
        return getDashboardUrl('/screening/geos');
      }
      return getDashboardUrl('/');
    },
    activePrefix: '/screening',
    authorized: user =>
      canViewScreeningPackages(user) || canViewScreeningGeos(user),
    dataPendoId: true,
  },
  account: {
    url: getDashboardUrl('/account/settings'),
    activePrefix: '/account',
    authorized: always,
    dataPendoId: true,
  },
  billing: {
    url: getDashboardUrl('/billing/invoices'),
    activePrefix: '/billing',
    tracking: (user, flags) => {
      const bulkInviteFlagrEnabled: boolean = !isNaN(
        flags[BULK_CANDIDATE_ORDER_INVITE_UPLOAD_FLAG_KEY]?.variantKey,
      );
      if (bulkInviteFlagrEnabled)
        return PAYMENT_AND_BILLING_NAMES.PAYMENT_AND_BILLING_PAGE_VIEWED;
      return undefined;
    },
    authorized: user =>
      hasPermission(user, 'update_billing') ||
      hasPermission(user, 'read_account_invoices'),
    dataPendoId: true,
  },
};

export const I9_TABS: Tabs = {
  i9: {
    url: getDashboardUrl('/i-9', { source: 'tab' }),
    activePrefix: '/i-9',
    authorized: (user, flags) =>
      I9_ALLOWED_USER_ROLES.some(r => user?.roles?.includes(r)),
    dataPendoId: true,
  },
};

export const DRUG_AND_HEALTH_TABS: Tabs = {
  random_testing_program: {
    url: getDashboardUrl('/random_testing_program'),
    activePrefix: '/random_testing_program',
    authorized: user =>
      user?.account?.health_screenings_random_testing_setup &&
      hasPermission(user, 'manage_post_hire_drug'),
    dataPendoId: true,
  },
  pars: {
    url: getDashboardUrl('/pars_program'),
    activePrefix: '/pars_program',
    authorized: (user, flags) =>
      flags[PARS_UI_COMPONENTS]?.variantKey === 'on' &&
      user?.account?.health_screenings_random_testing_setup &&
      hasPermission(user, 'manage_post_hire_drug'),
    dataPendoId: true,
  },
};

export const SECOND_TABS: Tabs = {
  pay: {
    url: getDashboardUrl('/checkr_pay'),
    activePrefix: '/checkr_pay',
    authorized: (user, flags) => canViewCheckrXProducts(user),
    tracking: () => {
      return SIDE_MENU_NAVIGATION_NAMES.PAY_NAVIGATION_CLICKED;
    },
    dataPendoId: true,
  },
  trust: {
    url: 'https://www.checkrtrust.com/?utm_campaign=Checkr%20Trust%20_%20Checkr%20Product%20Side%20Nav_%202024&utm_source=CheckrProductSideNav',
    external: true,
    authorized: (user, flags) => canViewCheckrXProducts(user),
    openNewTab: true,
    tracking: () => {
      return SIDE_MENU_NAVIGATION_NAMES.TRUST_NAVIGATION_CLICKED;
    },
    icon: true,
    dataPendoId: true,
  },
};

export const HELP_TABS: Tabs = {
  help_center: {
    url: `${HELP_CENTER_URL}`,
    external: true,
    authorized: always,
    icon: true,
    dataPendoId: true,
    openNewTab: true,
  },
  learning_center: {
    url: 'https://learn.checkr.com',
    external: true,
    authorized: always,
    icon: true,
    dataPendoId: true,
    openNewTab: true,
  },
  api_docs: {
    url: 'https://docs.checkr.com',
    external: true,
    authorized: always,
    icon: true,
    dataPendoId: true,
    openNewTab: true,
  },
  contact_us: {
    url: 'https://help.checkr.com/hc/en-us/requests/new',
    external: true,
    authorized: always,
    icon: true,
    dataPendoId: true,
    openNewTab: true,
  },
};
