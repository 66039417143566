import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Service as DrugTestScreening } from '@dashboard-experience/utils';
import DrugScreeningSetupModal from 'components/AddScreenings/Domestic/List/DrugAndHealthScreenings/DrugScreeningSetupModal'; // TODO: Move and refactor
import { namespace } from 'components/BetterOrderExperience/locales';
import {
  StyledAdditionalPropContainer,
  StyledFlex,
  StyledScreeningContainer,
} from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../ScreeningSectionItem';
import { DrugTestStatus, ScreeningProductType } from '../../AddonsStep.enums';
import type { AddonsT } from '../..';
import {
  StyledPendingSetupTag,
  StyledDrugTestDropdown,
} from './DrugAndHealthSection.styles';
import DrugTestSetupButtons from './DrugTestSetupButtons';

type Props = {
  id: string;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  price: string;
  isAdded: boolean;
  isIncluded: boolean;
  status: AddonsT.DrugTestStatusType;
  drugTestScreenings: DrugTestScreening[];
};

const DrugTest: React.FC<Props> = ({
  id,
  price,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  status,
  drugTestScreenings,
}) => {
  const [openSetupModal, setOpenSetupModal] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);

  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.drugAndHealth.drug',
  });

  const handleAddClick = useCallback(() => {
    if (!isAdded) {
      setInvalid(true);
      return;
    }
    onAddClick(ScreeningProductType.DRUG_SCREENING, price);
  }, [isAdded, onAddClick, price]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.DRUG_SCREENING);
  }, [onRemoveClick]);

  const getLabel = useCallback((drugTestScreening: DrugTestScreening) => {
    return drugTestScreening.label;
  }, []);

  const handleChange = useCallback(
    ({ selectedItem }) => {
      const additionalProperties = {
        selectedDrugScreen: selectedItem,
        subtype: selectedItem.name,
      };
      setInvalid(false);
      onAddClick(ScreeningProductType.DRUG_SCREENING, price, {
        drug_screening: additionalProperties,
      });
    },
    [onAddClick, price],
  );

  const handleClose = useCallback(() => {
    setOpenSetupModal(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenSetupModal(true);
  }, []);

  return (
    <StyledScreeningContainer>
      <StyledFlex flexDirection='column'>
        <DrugScreeningSetupModal
          open={openSetupModal}
          handleClose={handleClose}
        />
        <StyledFlex>
          <ScreeningSectionItem
            id={id}
            description={t('description')}
            advantages={t('advantages', { returnObjects: true })}
            disadvantages={t('considerations', {
              returnObjects: true,
            })}
            price={price}
            screeningName={t('name')}
            titleTag={
              status === DrugTestStatus.DISABLED ? (
                <StyledPendingSetupTag>{t('pendingTag')}</StyledPendingSetupTag>
              ) : null
            }
          />
          <DrugTestSetupButtons
            id={id}
            isAdded={isAdded}
            isIncluded={isIncluded}
            onAddClick={handleAddClick}
            onRemoveClick={handleRemoveClick}
            status={status}
            handleOpen={handleOpen}
          />
        </StyledFlex>
        {status === DrugTestStatus.ENABLED && (
          <StyledAdditionalPropContainer>
            <StyledDrugTestDropdown
              label={t('dropdownLabel')}
              data-testid='drug-screening-dropdown'
              items={drugTestScreenings ?? []}
              itemToString={getLabel}
              onChange={handleChange}
              disabled={isIncluded}
              invalid={invalid}
              invalidText={t('invalidText')}
            />
          </StyledAdditionalPropContainer>
        )}
      </StyledFlex>
    </StyledScreeningContainer>
  );
};

export default DrugTest;
