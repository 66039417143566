import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AddButtonContainer } from 'components/AddScreenings/shared/ListItemElements';
import { namespace } from 'components/BetterOrderExperience/locales';
import type { AddonsT } from '../..';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledScreeningContainer } from '../../AddonsStep.styles';
import { ScreeningSectionItem } from '../../DomesticScreeningsList/ScreeningSectionItem';
import ScreeningSectionItemButton from '../../DomesticScreeningsList/ScreeningSectionItem/ScreeningSectionItemButton';

type Props = {
  id: string;
  isAdded: boolean;
  isIncluded: boolean;
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  disabled?: boolean;
};

const InternationalEducationVerification: React.FC<Props> = ({
  id,
  isAdded,
  isIncluded,
  onAddClick,
  onRemoveClick,
  disabled = false,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.international.verifications.educationVerification',
  });

  const handleAddClick = useCallback(() => {
    onAddClick(
      ScreeningProductType.INTERNATIONAL_EDUCATION_VERIFICATION,
      t('priceVaries'),
    );
  }, [onAddClick, t]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick(ScreeningProductType.INTERNATIONAL_EDUCATION_VERIFICATION);
  }, [onRemoveClick]);

  return (
    <StyledScreeningContainer>
      <ScreeningSectionItem
        id={id}
        description={t('description')}
        advantages={t('advantages', { returnObjects: true })}
        disadvantages={t('considerations', {
          returnObjects: true,
        })}
        screeningName={t('name')}
        price=''
      />
      <AddButtonContainer>
        <ScreeningSectionItemButton
          id={id}
          onAddClick={handleAddClick}
          onRemoveClick={handleRemoveClick}
          added={isAdded}
          disabled={disabled}
          included={isIncluded}
        />
      </AddButtonContainer>
    </StyledScreeningContainer>
  );
};

export default InternationalEducationVerification;
