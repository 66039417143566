import { useLocation } from 'react-router-dom';
import { useReport } from 'containers/Report';
import { AssessmentVariantRegistration } from 'types/report/assessmentVariants';
import assessmentVariantRegistrations from './__mocks__/assessmentVariantRegistrations';

// [EADA-4970] Will add useQuery to this hook
// Until then we are mocking the data
// NOTE: this logic is gated by
// the assess_assessment_variants account setting
// which is not enabled for any prod accounts
export const useFetchAssessmentVariantRegitrations = (): any => {
  const report = useReport();

  const enabled = report?.account?.assess_assessment_variants;

  const placeholderData = enabled ? assessmentVariantRegistrations : undefined;

  return {
    isLoading: false,
    isError: false,
    data: placeholderData,
  };
};

export const useGetDisplayLabel = () => {
  const { search } = useLocation();
  const { data: assessmentVariants } = useFetchAssessmentVariantRegitrations();

  if (!assessmentVariants) return null;
  const assessmentParam = new URLSearchParams(search).get('assessment');

  const currentAssessmentVariant = assessmentVariants.find(
    (assessment: AssessmentVariantRegistration) =>
      assessment.id === assessmentParam,
  );
  return currentAssessmentVariant?.display_label;
};
