import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { StyledChoiceCardWrap } from '../GetStartedStep.styles';

export const StyledHeader = styled.h4`
  color: ${colors.coolBlack100};
  margin: 2.5rem 0 1rem !important;
`;

export const StyledRadioWrap = styled(StyledChoiceCardWrap)`
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

export const StyledChoiceCardGroup = styled.div`
  display: flex;
  margin: 1rem;

  @media all and (max-width: 480px) {
    flex-flow: column;
    gap: 1rem;
  }
`;
