/* eslint-disable import/prefer-default-export */
import { AmsAPI } from 'utils/APIClient';
import {
  UserDetailsResponse,
  UserVersionsResponse,
  VerifyEmailResponse,
} from './types';

export const fetchUserVersions = (
  accountId: string,
  userId: string,
  page: number,
  per_page: number,
): Promise<UserVersionsResponse> => {
  return AmsAPI.get(
    `/api/public/v2/accounts/${accountId}/users/${userId}/versions`,
    {
      params: { page, per_page },
    },
  );
};

export const fetchUserDetails = (
  accountId: string,
  userId: string,
): Promise<UserDetailsResponse> => {
  return AmsAPI.get(`/api/public/v2/accounts/${accountId}/users/${userId}`);
};

export const verifyUserEmail = (
  accountId: string,
  userId: string,
): Promise<VerifyEmailResponse> => {
  return AmsAPI.post(
    `/api/public/v2/accounts/${accountId}/users/${userId}/verify_email`,
  );
};
