import React from 'react';
import { FetchPackageType, PostPackageType } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import UnavailableForManualBulkTooltip from './ManualBulkTooltip';
import ConditionalWrapper from './ui/ConditionalWrapper';
import PackageCard from './PackageCard';
import {
  StyledHeader,
  StyledScreeningsHeader,
  StyledPriceHeader,
} from './styles/PackageCardList.styles';
import { isAvailableForManualBulk } from './SelectPackageUtils';
import PackageHeaderWithMobile from './ui/PackageHeaderWithMobile';
import { namespace } from '../locales';

export type Props = {
  packages: FetchPackageType[];
  update: Function;
  selectedPackage: PostPackageType;
  showPrice: boolean;
  isManualBulkOrder: boolean;
};

const PackageCardList: React.FC<Props> = ({
  packages,
  update,
  selectedPackage,
  showPrice,
  isManualBulkOrder,
}) => {
  const { t } = useTranslation();
  const basePath = `${namespace}:selectPackage`;

  return (
    <>
      <StyledHeader>
        <PackageHeaderWithMobile />
        <StyledScreeningsHeader>
          {t(`${basePath}.tableHeaders.screenings`)}
        </StyledScreeningsHeader>
        {showPrice && (
          <StyledPriceHeader
            alignRight
            data-testid='select-package-price-table-header'
          >
            {t(`${basePath}.tableHeaders.price`)}
          </StyledPriceHeader>
        )}
      </StyledHeader>
      {packages.map((basePackage, idx: number) => (
        <ConditionalWrapper
          key={basePackage.id}
          condition={!isAvailableForManualBulk(basePackage, isManualBulkOrder)}
          wrapper={UnavailableForManualBulkTooltip}
        >
          <PackageCard
            packageData={basePackage}
            idx={idx}
            isDisabled={
              !isAvailableForManualBulk(basePackage, isManualBulkOrder)
            }
            update={update}
            selectedPackage={selectedPackage}
            showPrice={showPrice}
          />
        </ConditionalWrapper>
      ))}
    </>
  );
};

export default PackageCardList;
