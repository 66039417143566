import React, { useEffect, useMemo, useCallback } from 'react';
import { BillingEntity } from 'types/Billing';
import parseAddressToString from 'utils/BillingAddressUtils';
import { StyledPaymentDropdown } from './Dropdown.styles';

type Props = {
  payment_profiles: BillingEntity[];
  selectedPaymentProfile: BillingEntity;
  setSelectedPaymentProfile: (paymentProfile: BillingEntity) => void;
};

const paymentProfileNameToString = (item: BillingEntity) => {
  const profileName = item?.name || '';
  const profileAddress = parseAddressToString(item?.address || null);
  if (profileAddress !== '') {
    return profileName.concat(' (', profileAddress, ')');
  }
  return profileName;
};

const PaymentProfileDropdown: React.FC<Props> = ({
  payment_profiles,
  selectedPaymentProfile,
  setSelectedPaymentProfile,
}) => {
  const currentProfile = useMemo(() => {
    return payment_profiles.find(
      profile => profile.id === selectedPaymentProfile.id,
    );
  }, [payment_profiles, selectedPaymentProfile.id]);

  const handlePaymentProfileChange = useCallback(
    ({ selectedItem }) => {
      setSelectedPaymentProfile(selectedItem);
    },
    [setSelectedPaymentProfile],
  );

  useEffect(() => {
    if (payment_profiles.length > 0 && !selectedPaymentProfile?.name) {
      // Set initial state to Main Account
      setSelectedPaymentProfile(payment_profiles[0]);
    }
  }, [
    payment_profiles,
    selectedPaymentProfile?.name,
    setSelectedPaymentProfile,
  ]);

  return (
    <StyledPaymentDropdown
      data-testid='payment-profile-dropdown-testid'
      selectedItem={currentProfile}
      items={payment_profiles}
      label='Saved payment profiles'
      itemToString={paymentProfileNameToString}
      onChange={handlePaymentProfileChange}
    />
  );
};

export default PaymentProfileDropdown;
