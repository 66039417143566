import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

const DropdownBase = styled(M.Dropdown)`
  width: 280px;

  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

export const StyledGeoDropdown = styled(M.ComboBox)`
  width: 280px;
  margin: 10px 20px 10px 0;

  @media all and (max-width: 480px) {
    width: 100%;
  }

  .cds--list-box__selection {
    display: none;
  }

  .cds--text-input {
    padding-right: 2.5rem;
  }
`;

export const StyledNodeDropdown = styled(M.NodeSelect)`
  margin-right: 20px !important;
  width: 280px;
  margin: 10px 0;
  flex-grow: 0 !important;

  label {
    display: none;
  }

  @media (max-width: 672px) {
    width: 100%;
  }
`;

export const StyledProgramDropdown = styled(DropdownBase)`
  margin: 0 20px 10px 0;
`;

export const StyledCountriesDropdown = styled(DropdownBase)`
  margin: 10px 0;
`;

export const StyledStatesDropdown = styled(DropdownBase)`
  margin: 10px 20px 10px 0;
`;

export const StyledCityDropdown = styled(DropdownBase)`
  margin: 10px 0;
`;

export const StyledPaymentDropdown = styled(DropdownBase)`
  margin: 10px 0;
  margin-bottom: 16px;
`;
