import React, { useCallback } from 'react';
import {
  accountHasPermissions,
  CurrentUser,
  hasPermission,
} from '@dashboard-experience/utils';
import { useUser } from 'modules/assess/ui/hooks';
import { ScreeningProductType } from '../AddonsStep.enums';
import MotorVehicleRecordSection from './MotorVehicleRecordsSection/MotorVehicleRecordsSection';
import VerificationSection from './VerificationSection/VerificationSection';
import CriminalSearchesSection from './CriminalSearchesSection/CriminalSearchesSection';
import DrugAndHealthSection from './DrugAndHealthSection/DrugAndHealthSection';
import FACISSection from './FACISSection/FACISSection';
import CivilSearchesSection from './CivilSearchesSection/CivilSearchesSection';
import CreditReportSection from './CreditReportSection/CreditReportSection';
import type { AddonsT } from '../..';
import { DomesticScreeningsListContainer } from './DomesticScreeningsList.styles';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  addedScreeningTypes: AddonsT.ScreeningType[];
  addOnPrices: AddonsT.AddonPrice[];
  includedScreenings: AddonsT.ScreeningType[];
  selectedAdditionalProperties: AddonsT.AdditionalProperties;
  setSelectedAdditionalProperties: (
    additionalProperties: AddonsT.AdditionalProperties,
  ) => void;
};

const DomesticScreeningsList: React.FC<Props> = props => {
  const { addedScreeningTypes, includedScreenings } = props;

  // Checks if screening is added via the add-ons step
  const currentUser = useUser();

  const hasManageAccountSettings = hasPermission(
    currentUser,
    'manage_account_settings',
  );

  const canOrderCredit = accountHasPermissions(currentUser, [
    'credit_report_setting',
    'credit_report_ready',
  ]);

  const showCreditReportScreening = hasManageAccountSettings || canOrderCredit;

  const isAdded = useCallback(
    (screeningType: ScreeningProductType) => {
      return (
        addedScreeningTypes.filter(screening => screening === screeningType)
          .length > 0
      );
    },
    [addedScreeningTypes],
  );

  // Checks if screening is included in the selected package
  const isIncluded = useCallback(
    (screeningType: ScreeningProductType) => {
      return (
        includedScreenings.filter(screening => screening === screeningType)
          .length > 0
      );
    },
    [includedScreenings],
  );

  return (
    <DomesticScreeningsListContainer data-testid='screenings-list-container'>
      <MotorVehicleRecordSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
      />
      <CriminalSearchesSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
      />
      <VerificationSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
      />
      {hasManageAccountSettings && (
        <DrugAndHealthSection
          isAdded={isAdded}
          isIncluded={isIncluded}
          currentUser={currentUser as CurrentUser}
          {...props}
        />
      )}
      <CivilSearchesSection
        isAdded={isAdded}
        isIncluded={isIncluded}
        {...props}
      />
      <FACISSection isAdded={isAdded} isIncluded={isIncluded} {...props} />
      {showCreditReportScreening && (
        <CreditReportSection
          isAdded={isAdded}
          isIncluded={isIncluded}
          {...props}
          status={currentUser?.account?.credit_report_setup}
        />
      )}
    </DomesticScreeningsListContainer>
  );
};

export default DomesticScreeningsList;
