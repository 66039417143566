/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledRecommendPackagesContainer = styled.div`
  display: flex;
  align-items: stretch;
  gap: 16px;
  flex-shrink: 0;
  margin: 0.75rem 0 1rem;

  .cds--popover-container {
    display: flex;
    flex: 1;
    align-items: stretch;

    & > div {
      width: 100%;
    }

    .button-reset {
      display: flex !important;
      height: 100%;
    }
  }
  @media all and (max-width: 1023px) {
    flex-direction: column;
  }
`;
