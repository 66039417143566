import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 25px;
`;

export const StyledPackageName = styled.div`
  font-weight: 400;
  margin-bottom: 0.75rem;
`;

export const StyledViewDetailsButton = styled(M.Link)`
  font-weight: 800;
  line-height: 16px;
  font-size: 12px;
  color: ${colors.brandNavy1};
  cursor: pointer;
  border: none !important;
  text-decoration: none !important;
`;

export const StyledSelectedPackage = styled.div`
  max-width: 85%;
  margin-right: 10px;
`;

export const StyledContainer = styled.div`
  line-height: 25px !important;
  font-size: 14px;
`;

export const StyledPackageTitle = styled.h5`
  margin-top: 6px !important;
`;
