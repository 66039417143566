import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';

export const StyledOrderCertifiedCheckbox = styled(M.Checkbox)`
  margin-top: 48px;
  margin-bottom: 18px;
`;

export const StyledOrderCertifiedText = styled.div`
  color: ${colors.uiTextPrimaryLight};
  line-height: 16px;
  font-size: 12px;
`;

export const StyledLink = styled(M.Link)`
  font-size: 12px;
`;

export const StyledSubtitle = styled.p`
  font-size: 16px !important;
  line-height: 24px !important;
`;
