/* ****************************************
ATTENTION: The UI-Platform team is deprecating the use of Redux for state management in favor of using React’s built in Context and component states. For Server state we are moving to React-Query instead of Redux. Please keep this in mind when adding to or creating new components.
See our State Management documentation here
https://checkr.atlassian.net/wiki/spaces/RD/pages/1687060509/State+Management
****************************************** */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Bowser from 'bowser';
import { useSearchState } from 'components/Search/search-context';

import { colors, M } from '@dashboard-experience/mastodon';
import {
  CurrentUser,
  GenericObject,
  getStatusTypeForUser,
  hasPermission,
  isInternal,
  StatusTypes,
} from '@dashboard-experience/utils';

import { updateParentWindowUrl } from 'utils';
import * as searchActions from 'actions/SearchActions';
import {
  AwaitingCredentialingNotification,
  BackToGoodhireBanner,
  OrderBackgroundCheckButton,
  PARAMS,
  SearchTable,
} from 'components/Search';

import { PageHeader } from 'components';

import { getSearchConfig } from 'components/Search/SearchTableContent/Config';
import UIContext from 'context/UI';
import {
  CUSTOMIZABLE_SEARCH_COLUMNS_FLAG_KEY,
  IS_IN_IFRAME,
  ORDER_REDESIGN_KEY,
} from 'Constants';
import ManualOrderButton from 'components/Search/ManualOrderButton';
import { useGetCandidates } from 'api/search';
import { CANDIDATE_SEARCH_EVENT_NAMES, useTrackEvent } from 'utils/analytics';
import { usePreference } from 'api/dashboardPreferences';
import useCandidateSearchFilterReport from './helpers/use-candidate-search-filter-reports';
import { ENABLE_ARCHIVE_REPORTS } from '../../Flags';
import Filters from '../../components/Search/Filters/Filters';
import filterReports from './helpers/filter-reports';

// Container to allow "Candidates" header and the "Order BGC" buttons to fit inline
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${IS_IN_IFRAME ? '719px' : '991px'}) {
    flex-direction: column;
  }
`;

const HeadingButtonsContainer = styled.div`
  display: inline-flex;
  margin-left: auto;
  margin-bottom: 3rem;

  .mastodon-button.secondary {
    margin-right: 1rem !important;
  }

  #mastodon & {
    .mastodon-button {
      max-width: fit-content;
      height: fit-content;

      &.primary {
        background-color: ${colors.brandAqua3};
        border-color: ${colors.brandAqua3};
        margin: 0;

        &:active,
        &:focus,
        &:hover {
          &:enabled:not(.disabled) {
            background-color: ${colors.brandAqua4};
            border-color: ${colors.brandAqua4};
          }
        }
      }
    }
  }

  @media (max-width: ${IS_IN_IFRAME ? '497px' : '575px'}) {
    flex-direction: column-reverse;

    #mastodon & {
      .mastodon-button.primary {
        margin-bottom: 1rem;
      }
    }
  }
  @media (max-width: ${IS_IN_IFRAME ? '719px' : '991px'}) {
    margin-top: 1.5rem;
    width: 100%;
    margin-bottom: 2rem;

    #order-background-check {
      svg {
        display: none;
      }
    }
  }
`;

// Container to dock the Pagination component over to the right side
const PaginationContainer = styled.div`
  .displayFlexJustified {
    display: flex;
    justify-content: flex-end;
  }
`;

// Warning to mobile users that they should use Desktop if possible
// iframes override window width in terms of media queries, so we need different breakpoints accordingly
const MobileWarning = styled.div`
  .mastodon-toast-notification {
    @media (min-width: ${IS_IN_IFRAME ? '720px' : '992px'}) {
      display: none;
    }
  }

  // stretch the toast to the full width, minus enough for the drop shadow to not get cut off by iframes

  .mastodon-toast-notification {
    margin-bottom: 1.5rem;
    margin-top: 0;
    width: 98%;
  }
`;
const {
  platform: { type: platformType },
} = Bowser.parse(window.navigator.userAgent);
const isDesktop = platformType === 'desktop';

const cancelToken = axios.CancelToken;

type SearchContainerProps = {
  contextId?: string;
  currentUser: CurrentUser;
  error?: string;
  searchParams: GenericObject;
  updatePageParam: Function;
};

const SearchContainer = ({
  contextId,
  currentUser,
  error,
  searchParams,
  updatePageParam,
}: SearchContainerProps) => {
  const [savedSearchConfig, saveSearchConfig] = usePreference('searchColumns');
  // CONFIG, FETCH DATA
  const customizableColumnsEnabled =
    useFlag(CUSTOMIZABLE_SEARCH_COLUMNS_FLAG_KEY)?.variantKey === 'on';
  // Load initial config from storage/default for user type
  const initialConfig = useMemo(
    () =>
      getSearchConfig(
        currentUser,
        customizableColumnsEnabled,
        savedSearchConfig,
      ),
    [currentUser, customizableColumnsEnabled, savedSearchConfig],
  );
  const [config] = useState(initialConfig);
  const userType = getStatusTypeForUser(currentUser);
  const [, setCancelSource] = useState<any | null>(null);
  const { isIframe } = useContext(UIContext);
  const orderRedesignKey = useFlag(ORDER_REDESIGN_KEY);

  // Save config when customized
  useEffect(() => {
    if (customizableColumnsEnabled) {
      saveSearchConfig(config);
    }
  }, [config, customizableColumnsEnabled, saveSearchConfig]);

  const { account } = currentUser;
  const isLimitedPartner = userType === StatusTypes.DSP;
  const assessEnabled = userType === StatusTypes.Assess;

  const history = useHistory();
  const { t } = useTranslation();
  const headerTitle = { label: t('headings.candidates') };
  const mobileWarningTitle = t('mobileWarning.title');
  const mobileWarningSubtitle = t('mobileWarning.subtitle');

  // TODO: Remove modifiedSearchParams and related code once the ENABLE_ARCHIVE_REPORTS (https://flagr-ui.checkrhq.net/#/flags/907) flag is deprecated and 'archived' is a default param
  const archiveReportFlag = useFlag(ENABLE_ARCHIVE_REPORTS);
  const archiveReportFlagEnabled = archiveReportFlag?.variantKey === 'on';

  useEffect(() => {
    if (account) {
      PARAMS.updateGeneralParams({
        assessEnabled,
        account,
      });
    }
  }, [account, assessEnabled]);

  const {
    call,
    result: { isLoading },
  } = useGetCandidates();

  const {
    state: { candidates },
  } = useSearchState();

  if (useCandidateSearchFilterReport() && candidates?.data) {
    candidates.data = filterReports(candidates.data, searchParams);
  }

  useEffect(() => {
    // Cancel in-flight search requests and generate a new cancel token before refetching
    const newCancelSource = cancelToken.source();
    const { token } = newCancelSource;
    setCancelSource((oldCancelSource: any | null) => {
      if (oldCancelSource) oldCancelSource.cancel();
      return newCancelSource;
    });

    // TODO: Update modifiedSearchParams and related code once the ENABLE_ARCHIVE_REPORTS (https://flagr-ui.checkrhq.net/#/flags/907) flag is deprecated and 'archived' is a default param
    let modifiedSearchParams = {};
    if (isInternal(currentUser)) {
      modifiedSearchParams = {
        archived: 'all',
        ...searchParams,
      };
    } else {
      switch (sessionStorage.getItem('resultsContentType')) {
        case 'true':
          modifiedSearchParams = {
            archived: 'true',
            ...searchParams,
          };
          break;
        case 'false':
          modifiedSearchParams = {
            archived: 'false',
            ...searchParams,
          };
          break;
        case 'all':
          modifiedSearchParams = searchParams;
          break;
        default:
          modifiedSearchParams = {
            archived: 'false',
            ...searchParams,
          };
      }
    }

    const params = archiveReportFlagEnabled
      ? modifiedSearchParams
      : searchParams;

    // Make sure params are established before making the search call
    // Hardcoded to use searchParams because we know that modifiedSearchParams will always include at least 1 thing
    if (Object.keys(searchParams).length) {
      call({
        params,
        account,
        cancelToken: token,
      });
    }

    if (contextId) {
      updateParentWindowUrl({
        contextId,
        search: params,
      });
    }
  }, [
    account,
    contextId,
    currentUser,
    searchParams,
    userType,
    setCancelSource,
    call,
    archiveReportFlagEnabled,
    archiveReportFlag,
  ]);

  // PAGINATION
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(0);
  /**
   * @name handlePageClick
   * @function
   * @memberOf SearchContainer
   * @description Callback for the pagination component's page buttons
   * @param {integer} pageIdx - The requested page's index
   */
  const handlePageClick = useCallback(
    pageIdx =>
      // this will re-fetch in the useEffect
      updatePageParam(pageIdx + 1),
    [updatePageParam],
  );

  const navigate = useCallback(
    path => {
      if (contextId)
        updateParentWindowUrl({
          path,
          contextId,
          reload: true,
        });
      history.push(path);
    },
    [contextId, history],
  );

  useEffect(() => {
    if (candidates) {
      setPageCount(Math.ceil(candidates.count / searchParams.per_page));
    }

    setPageIndex(searchParams.page - 1);
  }, [candidates, searchParams]);

  const trackEvent = useTrackEvent();

  // Needed for when useCandidateSearchFilterReport() = true
  if (!!candidates && candidates.count <= 25) {
    candidates.count = candidates?.data.length;
  }

  useEffect(() => {
    if (currentUser) {
      trackEvent(CANDIDATE_SEARCH_EVENT_NAMES.CANDIDATE_SEARCH_PAGE_LANDED);
    }
  }, [currentUser, trackEvent]);

  const showAwaitingCredentialingNotification =
    account?.pre_credentialed_invitations_allowed &&
    !account?.api_authorized &&
    !account?.authorized &&
    !account?.credentialed_at;

  if (!hasPermission(currentUser, 'view_candidates_list')) {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <div data-dd-privacy='allow'>
      {config && (
        <>
          {!isDesktop && (
            <MobileWarning>
              <M.ToastNotification
                kind='warning'
                title={mobileWarningTitle}
                subtitle={mobileWarningSubtitle}
                className={`displayFlexJustified ${isIframe ? 'iframe' : ''}`}
              />
            </MobileWarning>
          )}
          <HeadingContainer>
            <PageHeader style={{ marginBottom: 0 }} title={headerTitle} />
            <HeadingButtonsContainer className='btn-container'>
              {orderRedesignKey?.variantKey !== 'enabled' && (
                <ManualOrderButton
                  currentUser={currentUser}
                  navigate={navigate}
                />
              )}
              <OrderBackgroundCheckButton
                currentUser={currentUser}
                contextId={contextId}
                isLimitedPartner={isLimitedPartner}
              />
            </HeadingButtonsContainer>
          </HeadingContainer>

          <BackToGoodhireBanner />
          {showAwaitingCredentialingNotification && (
            <AwaitingCredentialingNotification candidates={candidates} />
          )}
          <Filters isLoading={isLoading} />
          <>
            {!!candidates && (
              <>
                <SearchTable
                  candidates={candidates}
                  config={config}
                  currentUser={currentUser}
                  contextId={contextId}
                />
                <PaginationContainer>
                  <M.Pagination
                    pageCount={pageCount}
                    onPageClick={handlePageClick}
                    selectedIndex={pageIndex}
                    className='displayFlexJustified'
                  />
                </PaginationContainer>
              </>
            )}
            {!candidates && isLoading && (
              <M.LoadingInline description='Loading...' />
            )}
          </>
        </>
      )}
      {(!config || !candidates) && error && (
        <M.InlineNotification kind='error' hideCloseButton title={error} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    contextId: state.ui.contextId,
    currentUser: state.currentUser.currentUser,
    error: state.search.error,
    searchParams: state.search.searchParams,
  };
};

export default connect(mapStateToProps, searchActions)(SearchContainer);
