import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

export const StyledScreeningLabel = styled.span`
  color: ${colors.brandNavy3};
  font-size: 14px;
  @media (max-device-width: 530px) {
    font-size: 12px !important;
  }
`;

export const StyledScreeningListLinePrice = styled.div`
  display: inline;
  margin-bottom: 2px;
  margin-top: 2px;

  @media (max-device-width: 400px) {
    flex-direction: column;
    margin-bottom: 8px;
  }
`;

export const StyledLinePriceSection = styled.span`
  display: inline-flex;
  width: 220px;
`;

export const StyledFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
export const StyledFlexWithMargin = styled.div`
  display: flex;
  padding: 2px;
  margin-left: 15px;
  font-size: 14px;
  color: ${colors.brandSlate6};
`;

export const StyledPriceLabel = styled.div`
  text-align: right;
`;

export const ScreeningType = styled.div`
  padding-left: 5px;
`;

export const StyledScreeningList = styled.ul`
  margin-top: 12px !important;

  li::marker {
    content: '✓ ' !important;
    color: ${colors.brandAqua3};
    font-size: 14px;
  }
`;
