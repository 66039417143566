import React, { useMemo } from 'react';
import { colors } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { useCreatePackageContext } from 'components/Packages/CreatePackage/Context';
import { useOrderBackgroundCheckContext } from 'components/Packages/OrderBackgroundCheck/Context';
import AddonScreeningCard from './AddonScreeningCard';
import AddonScreenings from '../enums/AddonScreenings';
import ScreeningType from '../enums/ScreeningType';
import { getDollarAmount } from '../shared/utils';

const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  background: #e6ecf4; // Old checkr blue
  width: 100%;
`;

const Title = styled.div`
  color: ${colors.bgPrimaryDark};

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
`;

const AddonsContainer = styled.div`
  display: flex;
  gap: 15px;
  justify-content: flex-start;

  @media all and (min-width: 1600px) {
    justify-content: center;
  }

  @media all and (max-width: 1350px) {
    gap: 10px;
    flex-wrap: wrap;
  }

  @media all and (max-width: 1250px) {
    flex-flow: column;
  }

  @media all and (max-width: 1200px) {
    flex-flow: row;
  }

  @media all and (max-width: 1000px) {
    flex-flow: column;
  }
`;

type PopularAddOnsProps = {
  onAddClick: Function;
  onRemoveClick: Function;
  addedScreenings: string[];
  disabledScreenings: string[];
  previouslyOrderedScreenings?: ScreeningType[];
  additionalProperties: any;
  allAddOnPricesQuery: any;
  basePackageScreenings: string[];
  usingAddChecks?: boolean;
  basePackage?: any;
};

const PopularAddOns: React.FC<PopularAddOnsProps> = ({
  onAddClick,
  onRemoveClick,
  addedScreenings,
  disabledScreenings,
  previouslyOrderedScreenings = [],
  additionalProperties,
  allAddOnPricesQuery,
  basePackageScreenings = [],
  usingAddChecks = false,
  basePackage,
}) => {
  const actionProps = {
    onAddClick,
    onRemoveClick,
    addedScreenings,
    disabledScreenings,
    additionalProperties,
    basePackageScreenings,
    previouslyOrderedScreenings,
  };
  const itemPrices = allAddOnPricesQuery || [];

  const existingEmploymentVerificationScreening: any =
    basePackageScreenings.find(
      (screening: any) =>
        screening.type === ScreeningType.employment_verification,
    );

  const getPriceEmploymentVerification = () => {
    if (existingEmploymentVerificationScreening) {
      return 'Price varies';
    }
    const lookbackYears =
      additionalProperties.employment_verification?.lookback_years || 7;
    const maximumAllowedEmployers =
      additionalProperties.employment_verification?.maximum_allowed_employers ||
      null;
    const itemPrice =
      itemPrices?.find(
        (item: any) =>
          item.product_type === ScreeningType.employment_verification &&
          item.lookback_years === lookbackYears &&
          item.maximum_allowed_employers === maximumAllowedEmployers,
      ) || [];
    if (itemPrices.length === 0) {
      return 'Loading price...';
    }
    if (!itemPrice.base_price) {
      return 'Price varies';
    }
    return getDollarAmount(itemPrice.base_price);
  };

  const getPriceByType = (screeningType: string) => {
    const itemPrice =
      itemPrices.find((item: any) => item.product_type === screeningType) || {};
    return isNaN(itemPrice.base_price)
      ? 'Loading price...'
      : getDollarAmount(itemPrice.base_price);
  };

  const { reducerState: createPackageState } = useCreatePackageContext();
  const { state: obcState } = useOrderBackgroundCheckContext();

  const getEmploymentVerificationSubtype = () => {
    const obcPackageScreening = obcState?.basePackage?.screenings?.find(obj => {
      return (
        obj?.type === 'employment_verification' && obj?.subtype === 'current'
      );
    });
    const basePackageScreening = basePackage?.screenings?.find(
      (obj: { type: string; subtype: string }) => {
        return (
          obj?.type === 'employment_verification' && obj?.subtype === 'current'
        );
      },
    );

    return obcPackageScreening || basePackageScreening;
  };

  const isProfessionalBasePackage = useMemo(
    () =>
      getEmploymentVerificationSubtype() ||
      createPackageState?.basePackage.name === 'Professional',
    [createPackageState, obcState, basePackage],
  );

  return (
    <Container>
      <Title>Popular {usingAddChecks ? 'checks' : 'searches'}</Title>
      <AddonsContainer>
        <AddonScreeningCard
          screeningName={AddonScreenings.MotorVehicleRecord}
          screeningType={ScreeningType.motor_vehicle_report}
          screeningPrice={getPriceByType(ScreeningType.motor_vehicle_report)}
          actionProps={actionProps}
        />
        <AddonScreeningCard
          screeningName={AddonScreenings.EducationVerification}
          screeningType={ScreeningType.education_verification}
          screeningPrice={getPriceByType(ScreeningType.education_verification)}
          actionProps={actionProps}
        />
        <AddonScreeningCard
          screeningName={AddonScreenings.EmploymentVerification}
          screeningType={ScreeningType.employment_verification}
          screeningPrice={getPriceEmploymentVerification()}
          actionProps={actionProps}
          isProfessionalPackage={isProfessionalBasePackage}
        />
      </AddonsContainer>
    </Container>
  );
};

export default PopularAddOns;
