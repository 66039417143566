import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

export const StyledInfoText = styled.p`
  color: var(--text-icon-black-primary, #1a2026);
  font-size: 16px !important;
  padding: 16px 0;
`;

export const StyledDownloadCSVLink = styled(M.Link)`
  cursor: pointer;
  display: block;
  line-height: 22px;
`;

export const StyledDescription = styled.div`
  font-weight: 400;
  margin-bottom: 0.75rem !important;
  margin-top: 7px !important;
`;
