import { toastSuccess, toastError } from 'actions';
import { AnyQueryKey, useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { URLSearchParams } from 'url';
import { useErrorNotifier } from 'hooks';
import {
  addPackage,
  editPackage,
  list,
  ListParams,
  listPackages,
  fetchPackage,
  deletePackage,
  fetchPackageSubtype,
  fetchRecommendedPackages,
  uploadCSV,
  UploadCSVPayload,
  fetchPackageSubtypeGlobal,
  fetchProgressivePackages,
  validateCSV,
  ValidateCSVPayload,
  applyManualBulkOrder,
  fetchAutoStartReportEligibility,
} from './actions';

/**
 * Will grab the packages from session storage if they are there, otherwise
 * will fetch them from the API. You may want to use `usePackages` instead.
 * @param params
 */
// eslint-disable-next-line import/prefer-default-export
export const useList = (params: ListParams) => {
  const key: AnyQueryKey = ['packages/list', { id: params?.accountId }];

  const request = () => list(params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: !!params.accountId,
    staleTime: 60000,
  });
};

type useAddPackageParams = {
  accountId: string;
  toggleShowAddScreenings?: Function;
  saveForNextTime?: boolean;
  onSuccess?: Function | null;
  onError?: Function;
  trackError?: Function;
};

export const useAddPackage = ({
  accountId,
  toggleShowAddScreenings,
  saveForNextTime = true,
  onSuccess,
  onError,
  trackError,
}: useAddPackageParams) => {
  const dispatch = useDispatch();
  const request = (params: URLSearchParams) => addPackage(accountId, params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      } else if (toggleShowAddScreenings) {
        toggleShowAddScreenings();
        saveForNextTime && dispatch(toastSuccess('Package saved'));
      }
    },
    onError: (error: any) => {
      if (onError) {
        onError(error);
      } else {
        const errorData = error?.response?.data;
        let errorMessage: string = '';
        if (errorData.error) {
          errorMessage = errorData.error;
        } else {
          errorMessage = errorData.errors.join(', ');
        }

        if (
          Array.isArray(errorData?.errors) &&
          errorData?.errors[0]?.toLowerCase() === 'slug has already been taken'
        ) {
          errorMessage = 'Please choose a unique name for your new package';
        }
        if (trackError) {
          trackError(error);
        }
        dispatch(toastError('Failed to create package', errorMessage));
      }
    },
  });

  return {
    call,
    result,
  };
};

export const useEditPackage = ({
  accountId,
  packageId,
  onError,
}: {
  accountId: string;
  packageId: string;
  onError?: Function;
}) => {
  const dispatch = useDispatch();
  const request = (params: URLSearchParams) =>
    editPackage(accountId, packageId, params);

  const [call, result] = useMutation(request, {
    onSuccess: () => {
      dispatch(toastSuccess('Package saved'));
    },
    onError: (error: any) => {
      if (onError) {
        onError(error);
      } else {
        const errorData = error?.response?.data;
        let errorMessage: string = '';
        if (errorData.error) {
          errorMessage = errorData.error;
        } else {
          errorMessage = errorData.errors.join(', ');
        }

        if (
          errorData?.errors[0]?.toLowerCase() === 'slug has already been taken'
        ) {
          errorMessage = 'Please choose a unique name for your new package';
        }

        dispatch(toastError('Failed to create package', errorMessage));
      }
    },
  });

  return {
    call,
    result,
  };
};

export const useDeletePackage = (refetchPackages: Function) => {
  const dispatch = useDispatch();
  const request = (param: any) =>
    deletePackage(param?.accountId, param?.packageId);

  const [deleteCall] = useMutation(request, {
    onSuccess: () => {
      refetchPackages();
      dispatch(toastSuccess('Package deleted'));
    },
    onError: (error: any) => {
      const errorData = error?.response?.data;
      let errorMessage: string = '';
      if (errorData.error) {
        errorMessage = errorData.error;
      } else {
        errorMessage = errorData.errors?.join(', ') ?? '';
      }

      dispatch(toastError('Unable to delete package', errorMessage));
    },
  });

  return {
    deleteCall,
  };
};

/**
 * Grabs the packages directly from the api
 * @param params
 */
export const usePackages = (accountId: string, params: URLSearchParams) => {
  const key: AnyQueryKey = [
    'packages/list',
    { accountId },
    { name: params.get('name') },
    { page: params.get('page') },
    { per_page: params.get('per_page') },
    { program_ids: params.get('program_ids') },
    params,
  ];

  const request = () => listPackages(accountId, params);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: accountId,
  });
};

export const useProgressivePackages = (reportId: string) => {
  const key: AnyQueryKey = ['progressivePackages', reportId];

  const request = () => fetchProgressivePackages(reportId);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: Boolean(reportId),
  });
};

export const usePackage = (accountId: string, packageId: string) => {
  const key: AnyQueryKey = ['package', packageId];

  const request = () => fetchPackage(accountId, packageId);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: Boolean(packageId),
  });
};

export const usePackageSubtypes = (accountId: string) => {
  const key: AnyQueryKey = ['packageSubtypes', accountId];
  const errorNotifier = useErrorNotifier();

  const request = () => fetchPackageSubtype(accountId);

  return useQuery(key, request, {
    onError: error => {
      errorNotifier(error, { title: 'Error retrieving drug screens' });
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(accountId),
  });
};

export const useRecommendedPackages = (accountId: string) => {
  const key: AnyQueryKey = ['recommendedPackages', accountId];

  const request = () => fetchRecommendedPackages(accountId);

  return useQuery(key, request, {
    refetchOnWindowFocus: false,
    enabled: Boolean(accountId),
  });
};

export const useAutoStartReportEligibility = (
  accountId: string,
  candidateId: string,
  packageId: string,
) => {
  const key: AnyQueryKey = [
    'useAutoStartReportEligibility',
    accountId,
    candidateId,
    packageId,
  ];
  const errorNotifier = useErrorNotifier();

  const request = () =>
    fetchAutoStartReportEligibility(accountId, candidateId, packageId);

  return useQuery(key, request, {
    onError: error => {
      errorNotifier(error, {
        title: 'Error checking report creation eligibility',
      });
    },
    refetchOnWindowFocus: false,
    enabled: Boolean(accountId),
  });
};

export const usePackageSubtypesGlobal = (screeningType: string) => {
  const key: AnyQueryKey = ['packageSubtypesGlobal', screeningType];
  const errorNotifier = useErrorNotifier();

  const request = () => fetchPackageSubtypeGlobal(screeningType);

  return useQuery(key, request, {
    onError: error => {
      errorNotifier(error, { title: 'Error retrieving package subtypes' });
    },
    refetchOnWindowFocus: false,
  });
};

export const useUploadCSV = () => {
  const request = (params: UploadCSVPayload) =>
    uploadCSV(params.accountId, params);

  const [uploadCall, uploadResult] = useMutation(request);

  return {
    uploadCall,
    uploadResult,
  };
};

type ParamsType = {
  validatePayload: ValidateCSVPayload;
  onSuccessCallback: () => void;
};

export const useValidateCSV = () => {
  const request = (params: ParamsType) => validateCSV(params.validatePayload);
  const dispatch = useDispatch();

  const [validateCall, validateResult] = useMutation(request, {
    onSuccess: (_, params: ParamsType) => {
      params.onSuccessCallback();
    },
    onError: (error: any) => {
      dispatch(
        toastError(
          'Unable to upload',
          `Correct the following error and try again: ${error?.response?.data.errors}`,
        ),
      );
    },
  });
  return {
    validateCall,
    validateResult,
  };
};

export const useManualBulkOrder = () => {
  const dispatch = useDispatch();
  const request = (params: {
    package_resource_id: string;
    bulk_apply_csv: string;
  }) => applyManualBulkOrder(params.package_resource_id, params);

  const [call, result] = useMutation(request, {
    onError: (error: any) => {
      dispatch(
        toastError('Unable to upload', `${error?.response?.data.errors}`),
      );
    },
  });

  return {
    call,
    result,
  };
};
