import React from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from 'components/BetterOrderExperience/locales';
import { M } from '@dashboard-experience/mastodon';
import { StyledFlex, StyledCheckbox } from '../../AddonsStep.styles';
import { StyledToggleTip } from '../MotorVehicleRecordsSection/MotorVehicleRecordsSection.styles'; // TODO (Alice) Move
import type { AddonsT } from '../..';

type Props = {
  onChange: (e: Event) => void;
  checked: boolean;
  screeningType: AddonsT.ScreeningType;
  disabled?: boolean;
};

const OutOfCountryHistory: React.FC<Props> = ({
  screeningType,
  onChange,
  checked,
  disabled = false,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.verificationSection.outOfCountry',
  });

  return (
    <StyledFlex>
      <StyledCheckbox
        data-testid={`${screeningType}-out-of-country-history-checkbox`}
        id={`${screeningType}-out-of-country-history-checkbox`}
        name='out-of-country-history-checkbox'
        labelText={t('label')}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div data-testid='out-of-country-history-tooltip'>
        <StyledToggleTip align='bottom' tabIndex={0}>
          <M.ToggletipButton>
            <M.Icon icon='HelpFilled' />
          </M.ToggletipButton>
          <M.ToggletipContent>
            <div>{t('toggleTip')}</div>
          </M.ToggletipContent>
        </StyledToggleTip>
      </div>
    </StyledFlex>
  );
};

export default OutOfCountryHistory;
