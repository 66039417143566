import React, { useContext } from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '@dashboard-experience/utils';
import { namespace } from 'components/BetterOrderExperience/locales';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import { INVITE_PAGE_ADD_SCREENINGS } from 'Flags';
import { isAddOnsEnabled } from 'components/Packages/shared/utils';
import { useUser } from 'context/CurrentUser';
import { STEPS } from 'components/BetterOrderExperience/utils/constants';
import BreadcrumbItem from './BreadcrumbItem';
import { StyledOrderedList } from './Breadcrumbs.styles';
import { Step } from './Breadcrumbs.types';

type Props = {
  currentStep: Step;
};

const Breadcrumbs: React.FC<Props> = ({ currentStep }) => {
  const currentUser = useUser();
  const { t } = useTranslation(namespace, { keyPrefix: 'breadcrumbs' });
  const { infoSource, selectedPackage, manualOrderType } = useContext(
    BetterOrderExperienceContext,
  );

  const isAddOnsFlagEnabled =
    useFlag(INVITE_PAGE_ADD_SCREENINGS)?.variantKey === 'enabled';
  const requiresFMCSACompliance = Boolean(
    selectedPackage?.requires_fmcsa_compliance,
  );
  const hasManageAddOnsPermission = hasPermission(
    currentUser,
    'manage_order_add_ons',
  );

  const addOnsEnabled = isAddOnsEnabled(
    isAddOnsFlagEnabled,
    requiresFMCSACompliance,
    hasManageAddOnsPermission,
  );

  const showUploadStep =
    infoSource === 'MYSELF' && manualOrderType === 'MULTIPLE';
  const showAddonsStep = addOnsEnabled && !showUploadStep;

  return (
    <StyledOrderedList>
      <BreadcrumbItem
        title={t('getStarted', { stepNumber: 1 })}
        stepNumber={1}
        stepName={STEPS.GET_STARTED.name}
        currentStep={currentStep}
      />
      <BreadcrumbItem
        title={t('selectPackage', { stepNumber: 2 })}
        stepNumber={2}
        stepName={STEPS.SELECT_YOUR_PACKAGE.name}
        currentStep={currentStep}
      />
      {showUploadStep && (
        <BreadcrumbItem
          title={t('upload', { stepNumber: 3 })}
          stepNumber={3}
          stepName={STEPS.UPLOAD_CANDIDATE_INFO.name}
          currentStep={currentStep}
        />
      )}
      {showAddonsStep && (
        <BreadcrumbItem
          title={t('addOns', { stepNumber: 3 })}
          stepNumber={3}
          stepName={STEPS.ADD_ONS.name}
          currentStep={currentStep}
        />
      )}
      <BreadcrumbItem
        title={t('reviewAndSubmit', {
          stepNumber: showAddonsStep || showUploadStep ? 4 : 3,
        })}
        stepNumber={showAddonsStep || showUploadStep ? 4 : 3}
        stepName='reviewAndSubmit'
        currentStep={currentStep}
      />
    </StyledOrderedList>
  );
};

export default Breadcrumbs;
