import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAnalyticsDashboard } from 'api/analytics';
import { M } from '@dashboard-experience/mastodon';
import { useFlag } from '@dashboard-experience/react-flagr';
import { usePreference } from 'api/dashboardPreferences';
import { ANALYTICS_REDESIGN } from 'Flags';
import { AnalyticsIframeProps, AnalyticsStates } from './types';
import { AnalyticsBanner } from './AnalyticsBanner';
import { StyledIframe } from './StyledComponents';

const NEW_VERSIONS = ['overview'];

export const shouldShowToggle = (lookerName: string, flagrEnabled: boolean) =>
  flagrEnabled && NEW_VERSIONS.includes(lookerName);

export const getVersion = (
  showNewVersionToggle: boolean,
  toggle: AnalyticsStates,
) =>
  showNewVersionToggle && toggle !== AnalyticsStates.LEGACY
    ? AnalyticsStates.V2
    : AnalyticsStates.LEGACY;

export const AnalyticsIframe: React.FC<AnalyticsIframeProps> = ({
  lookerName,
}) => {
  const [showLoader, setShowLoader] = useState(true);
  const redesignEnabled = useFlag(ANALYTICS_REDESIGN)?.variantKey === 'on';

  const [toggle, setToggle] = usePreference(`analytics:${lookerName}`);

  const updateToggle = useCallback(() => {
    setToggle(
      toggle === AnalyticsStates.LEGACY
        ? AnalyticsStates.V2
        : AnalyticsStates.LEGACY,
    );
  }, [toggle, setToggle]);

  const showNewVersionToggle = useMemo(
    () => shouldShowToggle(lookerName, redesignEnabled),
    [redesignEnabled, lookerName],
  );

  const version =
    showNewVersionToggle && toggle !== AnalyticsStates.LEGACY
      ? AnalyticsStates.V2
      : AnalyticsStates.LEGACY;

  useEffect(() => {
    setShowLoader(true);
  }, [version]);

  const {
    url: lookerUrl,
    isLoading: lookerUrlIsLoading,
    isError: lookerUrlIsError,
  } = useGetAnalyticsDashboard({
    lookerName,
    includePermutationArray: true,
    version,
  });

  const hideLoader = useCallback(() => {
    setShowLoader(false);
  }, [setShowLoader]);

  return lookerUrl && !lookerUrlIsLoading && !lookerUrlIsError ? (
    <div>
      {showNewVersionToggle && (
        <AnalyticsBanner
          state={version}
          toggle={updateToggle}
          lookerName={lookerName}
        />
      )}
      {showLoader && <M.LoadingInline description='Loading...' />}
      <StyledIframe
        src={lookerUrl}
        className={lookerName}
        data-testid='analytics-iframe'
        data-pendo-id={`${lookerName}-analytics-iframe`}
        onLoad={hideLoader}
      />
    </div>
  ) : null;
};
