import AssessGuidelines from './assess-guidelines';
import CandidateInformation from './candidate-information';
import OrderInformation from './order-information';
import OrderInvitationInformation from './order-invitation-information';
import GeneralDetails from './general-details';
import Nodes from './nodes';
import { SectionProps } from './types';

export { default as renderModal } from './render-modal';

const Sections: { [key: string]: React.FC<SectionProps> } = {
  GeneralDetails,
  CandidateInformation,
  OrderInformation,
  Nodes,
  AssessGuidelines,
  OrderInvitationInformation,
} as const;

export default Sections;
