import { StyledContainer } from 'components/Account/Users/UsersStyledComponents';
import PackageScreeningItem from 'components/BetterOrderExperience/SelectPackageStep/PackageScreeningItem';
import { getDisplayPrice } from 'components/BetterOrderExperience/utils/PricingUtils';
import React from 'react';
import { Screening } from 'types';
import { PostPackageType } from '@dashboard-experience/utils';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../../locales';
import {
  StyledSection,
  StyledFlex,
  StyledSectionName,
  StyledPackagePriceSpan,
} from '../OrderSummary.styles';

const SelectedPackageSection: React.FC<{
  selectedPackage: PostPackageType;
  packagePrice: number;
}> = ({ selectedPackage, packagePrice }) => {
  const { t } = useTranslation(namespace);

  return (
    <StyledSection>
      <StyledFlex>
        <StyledSectionName>{selectedPackage.name}</StyledSectionName>
        <StyledPackagePriceSpan>
          {selectedPackage.price === 0
            ? t('orderSummary.priceVaries')
            : getDisplayPrice(packagePrice)}
        </StyledPackagePriceSpan>
      </StyledFlex>
      {selectedPackage.screenings.map((screening, idx: number) => {
        const key = `screening-${idx}`;
        return (
          <StyledContainer key={key}>
            <PackageScreeningItem
              screening={screening as Screening}
              showScreeningSettings={false}
              compact
            />
          </StyledContainer>
        );
      })}
    </StyledSection>
  );
};

export default SelectedPackageSection;
