import React, { useCallback } from 'react';
import { M } from '@dashboard-experience/mastodon';
import Humanize from 'humanize-plus';
import { useTranslation } from 'react-i18next';
import { namespace } from '../locales';
import type { BulkUploadT } from '.';
import {
  StyledUploadedBox,
  StyledRemoveIcon,
  StyledUploadBoxLabel,
  StyledRemoveButton,
  StyledUploadBox,
  StyledUploadInput,
  StyledUploadIcon,
  StyledUploadBoxDetails,
} from './FileUploadInput.styles';

type Props = {
  onUpload: (file: File) => void;
  onDelete: () => void;
  manualBulkUploadData: BulkUploadT.ManualBulkUploadData;
};

const FileUploadInput: React.FC<Props> = ({
  onUpload,
  onDelete,
  manualBulkUploadData,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'bulkUploadStep',
  });

  const handleUpload = useCallback(
    event => onUpload(event.target.files[0]),
    [onUpload],
  );

  const handleDelete = useCallback(() => onDelete(), []);

  const truncatesString = (value: string) => {
    if (value.length <= 16) return value;

    return Humanize.truncate(value, 11) + value.slice(-6);
  };

  const formatBytes = (bytes: number) => {
    if (!+bytes) return '0B';

    const k = 1024;
    const sizes = ['B', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(0))}${sizes[i]}`;
  };

  return (
    <>
      {manualBulkUploadData.fileName ? (
        <StyledUploadedBox>
          <StyledRemoveIcon>
            <M.Icon icon='Csv' size='24' />
          </StyledRemoveIcon>
          <StyledUploadBoxLabel>
            {truncatesString(manualBulkUploadData.fileName)}
            <span className='file-size'>
              {formatBytes(manualBulkUploadData.fileSize)}
            </span>
          </StyledUploadBoxLabel>
          <StyledRemoveButton
            onClick={handleDelete}
            kind='tertiary'
            data-testid='delete-button'
          >
            {t('remove')}
          </StyledRemoveButton>
        </StyledUploadedBox>
      ) : (
        <StyledUploadBox>
          <StyledUploadInput
            accept={['.csv']}
            onChange={handleUpload}
            data-testid='file-upload-input'
          />
          <StyledUploadIcon>
            <M.Icon icon='DocumentAttachment' size='24' />
          </StyledUploadIcon>
          <div>
            <StyledUploadBoxLabel>{t('selectUpload')}</StyledUploadBoxLabel>
            <StyledUploadBoxDetails>{t('csvMax')}</StyledUploadBoxDetails>
          </div>
        </StyledUploadBox>
      )}
    </>
  );
};

export default FileUploadInput;
