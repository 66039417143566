/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useFlag } from '@dashboard-experience/react-flagr';
import { useTranslation } from 'react-i18next';
import courtWeights from 'components/AddScreenings/shared/svg/court-weights.svg';
import { namespace } from 'components/BetterOrderExperience/locales';
import { getPricesByScreeningType } from 'components/BetterOrderExperience/utils/PricingUtils';
import { USE_GLOBAL_WATCHLIST_SEARCH_IN_ADDONS } from 'Flags';
import type { AddonsT } from '../..';
import { ScreeningSection } from '../../DomesticScreeningsList/ScreeningSection';
import { ScreeningProductType } from '../../AddonsStep.enums';
import { StyledFlex } from '../../AddonsStep.styles';
import { hasAllBasicPackageScreenings } from '../../AddonsStep.utils';
import InternationalEmploymentVerification from './InternationalEmploymentVerification';
import InternationalEducationVerification from './InternationalEducationVerification';
import InternationalIdentityDocValidation from './InternationalIdentityDocValidation';
import InternationalRightToWork from './InternationalRightToWork';

type Props = {
  onAddClick: (
    screening: AddonsT.ScreeningType,
    price: string,
    additionalProperties?: AddonsT.AdditionalProperties,
  ) => void;
  onRemoveClick: (screening: AddonsT.ScreeningType) => void;
  isAdded: (screening: AddonsT.ScreeningType) => boolean;
  isIncluded: (screening: AddonsT.ScreeningType) => boolean;
};

const InternationalVerificationsSection: React.FC<Props> = ({
  onAddClick,
  onRemoveClick,
  isAdded,
  isIncluded,
}) => {
  const { t } = useTranslation(namespace, {
    keyPrefix: 'addOns.international.verifications',
  });

  const isInternationalEmploymentVerifIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_EMPLOYMENT_VERIFICATION,
  );

  const isInternationalEducationVerifIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_EDUCATION_VERIFICATION,
  );

  const isInternationalDocValidation = isIncluded(
    ScreeningProductType.INTERNATIONAL_IDENTITY_DOCUMENT_VALIDATION,
  );

  const isInternatioanlRTWIncluded = isIncluded(
    ScreeningProductType.INTERNATIONAL_RIGHT_TO_WORK,
  );

  const sectionOpen =
    isInternationalEmploymentVerifIncluded ||
    isInternationalEducationVerifIncluded ||
    isInternationalDocValidation ||
    isInternatioanlRTWIncluded;

  return (
    <ScreeningSection
      id='crim-searches'
      name={t('sectionName')}
      icon={courtWeights}
      open={sectionOpen}
    >
      <StyledFlex flexDirection='column'>
        <InternationalEmploymentVerification
          id={ScreeningProductType.INTERNATIONAL_EMPLOYMENT_VERIFICATION}
          isAdded={isAdded(
            ScreeningProductType.INTERNATIONAL_EMPLOYMENT_VERIFICATION,
          )}
          isIncluded={isInternationalEmploymentVerifIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
        <InternationalEducationVerification
          id={ScreeningProductType.INTERNATIONAL_EDUCATION_VERIFICATION}
          isAdded={isAdded(
            ScreeningProductType.INTERNATIONAL_EDUCATION_VERIFICATION,
          )}
          isIncluded={isInternationalEducationVerifIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
        <InternationalIdentityDocValidation
          id={ScreeningProductType.INTERNATIONAL_IDENTITY_DOCUMENT_VALIDATION}
          isAdded={isAdded(
            ScreeningProductType.INTERNATIONAL_IDENTITY_DOCUMENT_VALIDATION,
          )}
          isIncluded={isInternationalDocValidation}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
        <InternationalRightToWork
          id={ScreeningProductType.INTERNATIONAL_RIGHT_TO_WORK}
          isAdded={isAdded(ScreeningProductType.INTERNATIONAL_RIGHT_TO_WORK)}
          isIncluded={isInternatioanlRTWIncluded}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
        />
      </StyledFlex>
    </ScreeningSection>
  );
};

export default InternationalVerificationsSection;
