import { M } from '@dashboard-experience/mastodon';
import { ReportStatuses } from '@dashboard-experience/utils';
import { useGetReportExceptions } from 'api/reports';
import { useReport } from 'containers/Report';
import { CANDIDATE_REDESIGN } from 'Flags';
import { BetaState, useBetaState } from 'providers/Beta';
import { useCandidateExceptions } from 'providers/Candidate';
import React from 'react';
import { Exception } from 'types';
import { isResolvableExceptionType } from '../utils';
import CanceledScreeningsBanners from './canceled-screenings-exceptions/canceled-screenings-banners';
import ResolvedExceptionsBanners from './candidate-resolved-exceptions/resolved-exceptions-banners';
import ResolvableExceptionsBanners from './resolvable-exceptions/resolvable-exceptions-banners';

const ExceptionBanners = () => {
  const betaState = useBetaState(CANDIDATE_REDESIGN);
  const report = useReport();
  const { exceptions: reportExceptions = [] } = useGetReportExceptions(
    report?.id,
  );
  const candidateExceptions = useCandidateExceptions() || [];

  const exceptions = [...reportExceptions, ...candidateExceptions];

  if (!exceptions.length) {
    return null;
  }

  // None of these banners should be shown to people without the redesign flags
  if (betaState === BetaState.DISABLED) {
    return null;
  }

  const unresolvedExceptions: Exception[] = [];
  const resolvedExceptions: Exception[] = [];

  exceptions.forEach(exception => {
    if (!exception.verification_uri || !isResolvableExceptionType(exception))
      return;

    if (!exception.resolved_at) {
      unresolvedExceptions.push(exception);
    } else {
      resolvedExceptions.push(exception);
    }
  });

  if (!unresolvedExceptions.length && !resolvedExceptions.length) {
    return null;
  }

  const translateCanceledToSuspendedEnabled =
    report?.account?.translate_canceled_status_to_suspended;

  const isReportcomplete =
    (report.status === ReportStatuses.COMPLETE && report.includes_canceled) ||
    (report.status === ReportStatuses.SUSPENDED &&
      report.completed_at &&
      translateCanceledToSuspendedEnabled);

  // report can be "complete" with a status of suspended if account has
  // translate_canceled_status_to_suspended enabled
  const isReportPendingOrSuspended =
    report.status === ReportStatuses.PENDING ||
    (report.status === ReportStatuses.SUSPENDED && !report.completed_at);

  return (
    <M.Grid>
      <M.GridRow>
        {!!unresolvedExceptions.length && (
          <>
            {isReportcomplete && (
              <CanceledScreeningsBanners
                unresolvedExceptions={unresolvedExceptions}
                report={report}
              />
            )}
            {isReportPendingOrSuspended && (
              <ResolvableExceptionsBanners
                unresolvedExceptions={unresolvedExceptions}
              />
            )}
          </>
        )}
        {!!resolvedExceptions.length && (
          <ResolvedExceptionsBanners resolvedExceptions={resolvedExceptions} />
        )}
      </M.GridRow>
    </M.Grid>
  );
};
export default ExceptionBanners;
