import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import omit from 'lodash/omit';
import { M } from '@dashboard-experience/mastodon';
import { updateParentWindowUrl } from 'utils';
import UIContext from 'context/UI';
import BetterOrderExperienceContext from 'pages/BetterOrderExperience/context';
import { StepProps } from 'pages/BetterOrderExperience/BetterOrderExperience.types';
import AccountContext from 'pages/Account/AccountContext';
import {
  buildPostBodyWithAddOns,
  mapPackage,
} from 'components/AddScreenings/shared/utils';
import { PostPackageType } from '@dashboard-experience/utils';
import type { AddonsT } from '.';
import { Footer, StepContainer } from '../ui';
import { STEPS } from '../utils/constants';
import { DomesticScreeningsList } from './DomesticScreeningsList';
import { PopularAddOns } from './PopularAddOns';
import { useGetScreeningPrices } from '../hooks/useGetScreeningPrices';
import { StyledFlex, StyledFlexPositionRelative } from './AddonsStep.styles';
import { OrderSummary } from './OrderSummary';
import InternationalScreeningsList from './InternationalScreeningsList/InternationalScreeningsList';
import { useCreatePackage } from '../hooks/useCreatePackage';

const AddonsStep: React.FC<StepProps> = ({ setCurrentStep }) => {
  const [
    selectedAddedScreeningTypeWithPrices,
    setSelectedAddedScreeningTypeWithPrices,
  ] = useState<AddonsT.ScreeningTypeWithPrices[]>([]);
  const [selectedAdditionalProperties, setSelectedAdditionalProperties] =
    useState<AddonsT.AdditionalProperties>({});

  // TODO: Update disabled logic
  const disabled = false;
  const history = useHistory();
  const { contextId } = useContext(UIContext);
  const {
    selectedPackage,
    update,
    additionalProperties,
    addedScreeningTypeWithPrices,
    localeType,
    newPackageName: initialPackageName,
    saveForNextTime: initialSaveForNextTime,
  } = useContext(BetterOrderExperienceContext);

  const { account } = useContext(AccountContext);
  const { call: createPackage } = useCreatePackage({
    accountId: account.id ?? '',
    onSuccess: (data: { [key: string]: unknown }) =>
      update({
        newlyCreatedPackage: mapPackage(data) as PostPackageType,
      }),
  });
  const [newPackageName, setNewPackageName] = useState(initialPackageName);
  const [saveForNextTime, setSaveForNextTime] = useState(
    initialSaveForNextTime,
  );

  const handleNewPackageName = useCallback((name: string) => {
    setNewPackageName(name);
  }, []);

  const handleSaveForNextTime = useCallback((save: boolean) => {
    setSaveForNextTime(save);
  }, []);

  const isInternational = localeType === 'INTERNATIONAL';

  const createNewPackage = useCallback(
    (packageName: string, isPrivate: boolean) => {
      const addedScreeningTypes = selectedAddedScreeningTypeWithPrices.map(
        s => s.screening.type,
      );

      const body = buildPostBodyWithAddOns({
        basePackage: selectedPackage,
        addedScreeningTypes,
        additionalProperties: selectedAdditionalProperties,
        packageName,
        setSlug: !isPrivate, // Only send in slugs for 'Save for later' packages
        isPrivate,
      });

      createPackage(body);
    },
    [
      selectedAddedScreeningTypeWithPrices,
      selectedPackage,
      selectedAdditionalProperties,
      createPackage,
    ],
  );

  const addOnPrices = useGetScreeningPrices(account.id ?? '', !isInternational);

  const addedScreeningTypes = useMemo(() => {
    return selectedAddedScreeningTypeWithPrices.map(
      screening => screening.screening.type,
    );
  }, [selectedAddedScreeningTypeWithPrices]);

  const includedScreenings = useMemo(() => {
    if (
      !selectedPackage.screenings ||
      selectedPackage?.screenings?.length === 0
    )
      return [];
    return selectedPackage.screenings.map(
      screening => (screening as { type: string }).type,
    );
  }, [selectedPackage.screenings]);

  const handleBack = useCallback(() => {
    const path = '/order-experience/select-your-package';
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.SELECT_YOUR_PACKAGE);
  }, [contextId, history, setCurrentStep]);

  const handleContinue = useCallback(() => {
    // Only create new package if there are selected add-ons
    if (selectedAddedScreeningTypeWithPrices.length > 0) {
      if (saveForNextTime && newPackageName) {
        // Create package with custom name if provided
        createNewPackage(newPackageName, false);
        update({ newPackageName });
      } else {
        // Create package with default name if not saving for next time
        createNewPackage(`${selectedPackage.name} with addons`, true);
      }
    }

    const path = '/order-experience/review-and-submit';
    update({
      additionalProperties: selectedAdditionalProperties,
      addedScreeningTypeWithPrices: selectedAddedScreeningTypeWithPrices,
      saveForNextTime,
    });
    if (contextId) {
      updateParentWindowUrl({
        path,
        contextId,
        reload: true,
      });
    } else {
      history.push(path);
    }
    setCurrentStep(STEPS.REVIEW_AND_SUBMIT);
  }, [
    saveForNextTime,
    newPackageName,
    update,
    selectedAdditionalProperties,
    selectedAddedScreeningTypeWithPrices,
    contextId,
    setCurrentStep,
    createNewPackage,
    selectedPackage.name,
    history,
  ]);

  const onAddClick = useCallback(
    (
      screening: AddonsT.ScreeningType,
      price: string,
      additionalProperties?: AddonsT.AdditionalProperties,
    ) => {
      // Check if the screening has already been added
      const hasBeenAdded = selectedAddedScreeningTypeWithPrices?.some(
        addOn => addOn?.screening?.type === screening,
      );

      if (!hasBeenAdded) {
        const newAddonsSelection = {
          screening: { type: screening },
          price,
        };

        setSelectedAddedScreeningTypeWithPrices(addOns => [
          ...addOns,
          newAddonsSelection,
        ]);
      }

      if (additionalProperties)
        setSelectedAdditionalProperties({
          ...selectedAdditionalProperties,
          ...additionalProperties,
        });
    },
    [selectedAdditionalProperties, selectedAddedScreeningTypeWithPrices],
  );

  const onRemoveClick = useCallback(
    (screening: AddonsT.ScreeningType) => {
      setSelectedAddedScreeningTypeWithPrices(addOn =>
        addOn.filter(addon => addon.screening.type !== screening),
      );

      const newAdditionalProperties = omit(
        selectedAdditionalProperties,
        screening,
      );
      setSelectedAdditionalProperties(newAdditionalProperties);
    },
    [selectedAdditionalProperties],
  );

  useEffect(() => {
    // Set initial values from any persisted data
    if (additionalProperties)
      setSelectedAdditionalProperties(additionalProperties);
    if (addedScreeningTypeWithPrices)
      setSelectedAddedScreeningTypeWithPrices(addedScreeningTypeWithPrices);
  }, [addedScreeningTypeWithPrices, additionalProperties]);

  return (
    <StepContainer data-testid='addons-step-container'>
      <StyledFlexPositionRelative>
        <StyledFlex flexDirection='column'>
          {!isInternational && (
            <PopularAddOns
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              addOnPrices={addOnPrices}
              includedScreenings={includedScreenings as AddonsT.ScreeningType[]}
              selectedPackage={selectedPackage}
              additionalProperties={additionalProperties}
              addedScreeningTypes={addedScreeningTypes}
            />
          )}
          {!isInternational ? (
            <DomesticScreeningsList
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              addedScreeningTypes={addedScreeningTypes}
              addOnPrices={addOnPrices}
              includedScreenings={includedScreenings as AddonsT.ScreeningType[]}
              selectedAdditionalProperties={selectedAdditionalProperties}
              setSelectedAdditionalProperties={setSelectedAdditionalProperties}
            />
          ) : (
            <InternationalScreeningsList
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              addedScreeningTypes={addedScreeningTypes}
              addOnPrices={addOnPrices}
              includedScreenings={includedScreenings as AddonsT.ScreeningType[]}
              selectedAdditionalProperties={selectedAdditionalProperties}
              setSelectedAdditionalProperties={setSelectedAdditionalProperties}
            />
          )}
        </StyledFlex>
        <OrderSummary
          newPackageName={newPackageName}
          setNewPackageName={handleNewPackageName}
          saveForNextTime={saveForNextTime}
          setSaveForNextTime={handleSaveForNextTime}
          additionalProperties={selectedAdditionalProperties}
          addedScreeningTypeWithPrices={selectedAddedScreeningTypeWithPrices}
        />
      </StyledFlexPositionRelative>

      <Footer>
        <M.Button
          data-testid='addons-step-back-btn'
          type='button'
          kind='secondary'
          onClick={handleBack}
        >
          Go back
        </M.Button>

        <M.Button
          type='button'
          data-testid='addons-step-continue-btn'
          disabled={disabled}
          onClick={handleContinue}
        >
          Continue
        </M.Button>
      </Footer>
    </StepContainer>
  );
};

export default AddonsStep;
