import React, { useCallback } from 'react';
import AliasTag from 'components/Packages/Alias';
import { Screening } from 'types';
import { FetchPackageType, PostPackageType } from '@dashboard-experience/utils';
import { mapPackage } from 'components/AddScreenings/shared/utils';
import {
  StyledClickableChoiceCard,
  StyledFlex,
  StyledPackageNameCell,
  StyledPackageNameText,
  StyledPriceCell,
  StyledPriceCellMobile,
  StyledRadioButton,
  StyledScreeningListContainer,
  StyledScreeningUl,
} from './styles/PackageCard.styles';
import PackageScreeningItem from './PackageScreeningItem';
import { filteredBasePackageScreenings } from './SelectPackageUtils';
import PackagePrice from './ui/PackagePrice';

export type Props = {
  packageData: FetchPackageType;
  idx: number;
  isDisabled: boolean;
  update: Function;
  selectedPackage: PostPackageType;
  showPrice: boolean;
};

const PackageCard: React.FC<Props> = ({
  packageData,
  idx,
  isDisabled,
  update,
  selectedPackage,
  showPrice,
}) => {
  const isSelected = selectedPackage?.slug === packageData.slug;

  const basePackageScreenings = filteredBasePackageScreenings(packageData);

  const handleSelect = useCallback(
    e => {
      e.preventDefault();
      const newSelectedPackage = isSelected
        ? {}
        : (mapPackage(packageData) as unknown as PostPackageType);
      update({ selectedPackage: newSelectedPackage });
    },
    [isSelected, packageData, update],
  );

  const dataTestId = (idx: number): string => {
    return isSelected
      ? `selected-choice-card-${idx + 1}`
      : `choice-card-${idx + 1}`;
  };

  return (
    <StyledClickableChoiceCard
      key={packageData.id}
      data-testid={dataTestId(idx)}
      onClick={handleSelect}
      checked={isSelected}
      disabled={isDisabled}
    >
      <StyledFlex>
        <StyledPackageNameCell data-testid={`package-name-${idx + 1}`}>
          <StyledRadioButton
            data-testid={`package-button-${idx + 1}`}
            checked={selectedPackage?.slug === packageData.slug}
            onClick={handleSelect}
            disabled={isDisabled}
          />
          <StyledPackageNameText>{packageData.name}</StyledPackageNameText>
        </StyledPackageNameCell>
        {showPrice && (
          <StyledPriceCellMobile
            className='price'
            data-testid={`price-cell-mobile-${idx + 1}`}
          >
            <PackagePrice price={packageData.price} />
          </StyledPriceCellMobile>
        )}
        <StyledScreeningListContainer>
          <StyledScreeningUl
            className='screening-list'
            data-testid={`screening-ul-${idx + 1}`}
          >
            {basePackageScreenings?.map(screening => (
              <PackageScreeningItem
                key={screening.type}
                screening={screening as Screening}
                screeningSettings={packageData?.screening_settings ?? []}
              />
            ))}
            <AliasTag basePackage={packageData} />
          </StyledScreeningUl>
        </StyledScreeningListContainer>
      </StyledFlex>

      {showPrice && (
        <StyledPriceCell
          className='price'
          data-testid={`price-cell-${idx + 1}`}
        >
          <PackagePrice price={packageData.price} />
        </StyledPriceCell>
      )}
    </StyledClickableChoiceCard>
  );
};

export default PackageCard;
