import React, { useMemo } from 'react';
import { getParamFromUrl } from '@dashboard-experience/utils';
import CandidateProvider from 'providers/Candidate';
import { CandidateContent as CandidateContentMilestoneOne } from 'modules/candidate';
import { useBetaEnabled } from 'providers/Beta';
import { CANDIDATE_REDESIGN } from 'Flags';
import { useParams } from 'react-router-dom';
import withReportContainer from 'containers/Report/withReportContainer';
import { RealtimeProvider } from '../RealtimeProvider';
import CandidateContent from './CandidateContent';

type Props = {
  candidateId: string;
};

type Params = {
  reportId: string;
  invitationId: string;
};

export const CandidateComponent = () => {
  const candidateRedesignEnabled = useBetaEnabled(CANDIDATE_REDESIGN);
  const params = useParams() as Params;
  const match = { params };
  const type = params.reportId ? 'report' : 'invitation';

  const RedesignCandidateComponent = useMemo(
    () =>
      withReportContainer({
        component: CandidateContentMilestoneOne,
        type,
        loadingContainer: false,
        reportProvider: true,
      }),
    [type],
  );

  const CanidateRedesign = candidateRedesignEnabled
    ? RedesignCandidateComponent
    : CandidateContent;

  return <CanidateRedesign match={match} />;
};

// NOTE: components/Candidate is in the process of being deprecated
// in favor of modules/candidate. While the migration is in process,
// updates have to be made to both locations. For guidance, please
// reach out at #prod-dashboard-reports-page
export const CandidateContainer: React.FC<Props> = ({ candidateId }) => {
  const components = getParamFromUrl(window, 'components') || '';
  const componentsArray = useMemo(
    () => (components?.length ? components.split(',') : []),
    [components],
  );

  return (
    <CandidateProvider candidateId={candidateId} components={componentsArray}>
      <RealtimeProvider>
        <CandidateComponent />
      </RealtimeProvider>
    </CandidateProvider>
  );
};
