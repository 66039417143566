export enum RecommendedTagType {
  RECENTLY_CREATED = 'Recently created',
  RECENTLY_ORDERED = 'Recently ordered',
  MOST_POPULAR = 'Most popular',
  EMPTY = 'EMPTY',
}

export enum BLURBS {
  BASIC_PLUS = 'Simple and limited search',
  COMPLETE_CRIMINAL = 'Most thorough coverage',
  ESSENTIAL = 'In-depth criminal search, down to the county',
  ESSENTIAL_V2 = 'Most common criminal searches',
}
