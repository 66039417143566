import React, { FC, MouseEventHandler } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useTranslation } from 'react-i18next';
import { Banner, Column } from './StyledComponents';
import { Icon } from './Icon';

export enum ToggleStates {
  DEFAULT = 'default',
  OPT_OUT = 'opt_out',
}

export const Description: FC<{
  translationKey: string;
  learnMoreLink?: string;
}> = ({ translationKey, learnMoreLink }) => {
  const { t } = useTranslation();

  return (
    <Column>
      <div className='p3 bold'>{t(`${translationKey}.title`)}</div>
      <div className='p3'>
        {t(`${translationKey}.subtitle`)}{' '}
        {learnMoreLink && (
          <M.Link
            href={learnMoreLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            {t(`${translationKey}.learn_more`)}
          </M.Link>
        )}
      </div>
    </Column>
  );
};

export const Button: FC<{
  toggle: MouseEventHandler<HTMLButtonElement>;
  toggleState: ToggleStates;
  translationKey: string;
  buttonPendoId?: string;
}> = ({ toggle, toggleState, translationKey, buttonPendoId }) => {
  const { t } = useTranslation();

  const buttonKey =
    toggleState === ToggleStates.OPT_OUT
      ? `${translationKey}.opted_out_button`
      : `${translationKey}.button`;
  return (
    <M.Button kind='secondary' onClick={toggle} data-pendo-id={buttonPendoId}>
      {t(buttonKey)}
    </M.Button>
  );
};

export const ToggleBanner: FC<{
  translationKey: string;
  toggle: MouseEventHandler<HTMLButtonElement>;
  toggleState: ToggleStates;
  iconAlt?: string;
  learnMoreLink?: string;
  buttonPendoId?: string;
  bannerTestId?: string;
}> = ({
  translationKey,
  learnMoreLink,
  toggleState,
  toggle,
  iconAlt,
  buttonPendoId,
  bannerTestId,
}) => {
  return (
    <Banner data-testid={bannerTestId}>
      <Icon alt={iconAlt} />
      <Description
        translationKey={translationKey}
        learnMoreLink={learnMoreLink}
      />
      <Button
        toggle={toggle}
        buttonPendoId={buttonPendoId}
        translationKey={translationKey}
        toggleState={toggleState}
      />
    </Banner>
  );
};
