import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';

const getBreadcrumbColor = (visited: boolean, isCurrentPage: boolean) => {
  if (visited && isCurrentPage) {
    return colors.brandAqua4;
  }
  if (visited) {
    return colors.brandNavy3;
  }
  return colors.brandSlate5;
};

// eslint-disable-next-line import/prefer-default-export
export const StyledOrderedList = styled.ol`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 900px;
  margin: 32px 0;
`;

type BreadcrumbItemContainerProps = {
  visited: boolean;
  isCurrentPage: boolean;
};

export const StyledBreadcrumbItemContainer = styled.div<BreadcrumbItemContainerProps>`
  border-top: 3px solid
    ${props => (props.visited ? colors.brandAqua4 : colors.brandSlate4)};
  color: ${props => getBreadcrumbColor(props.visited, props.isCurrentPage)};
  font-size: 12px;
  font-weight: ${props => (props.isCurrentPage ? 'bold' : 'normal')};
  padding-top: 15px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
