import React from 'react';
import { SectionContainer, SectionHeader } from '../../shared';

const LogsSection: React.FC = () => {
  return (
    <SectionContainer data-testid='ats-logs-container'>
      <SectionHeader sectionKey='logs' header='Logs & Monitoring' />
    </SectionContainer>
  );
};

export default LogsSection;
