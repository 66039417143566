import React, { useCallback } from 'react';
import styled from 'styled-components';
import { M, colors } from '@dashboard-experience/mastodon';
import {
  useCreatePackageContext,
  CHECKMARK,
  EX,
  BASIC,
  ESSENTIAL,
  ListItem,
  BoldListItem,
  RadioButton,
  COMPLETE_CRIMINAL,
} from '../Context';

const SelectPackageContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.uiGrey200};
  background: ${colors.uiGrey0};
  border-radius: 4px;
  padding: 20px;
  position: relative;
  min-width: 1152px;
  overflow: hidden;
`;

const PackageTypeOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  right: 15px;
  margin-top: 15px;
  margin-left: 280px;
  min-width: 820px;
  min-height: 630px;
`;

const PackageTypeInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
`;

const List = styled.ul`
  margin-top: 244px;
  margin-left: 21px;
  width: 75%;
  position: absolute;
`;

const BoldHeader = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${colors.brandNavy3};
`;

const PackageTypeItem = styled.div`
  width: 260px;
  box-shadow: 0px 2px 15px 4px ${colors.brandSlate2};
  background: ${colors.uiGrey0};
  margin-bottom: 3px;
`;

const HighlightedPackageTypeItem = styled(PackageTypeItem)`
  border-color: ${colors.brandAqua3};
  border-width: medium;
  border-style: solid;
  border-radius: 1%;
  margin-bottom: 0px;
`;

const HighlightedPackageTypeItemSpacer = styled.div`
  height: 46px;
  background: ${colors.brandAqua3};
`;

const HighlightedHeaderText = styled(BoldHeader)`
  color: ${colors.uiGrey0};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Accordion = styled(M.Accordion)`
  #mastodon && {
    .cds--accordion__item--active > .cds--accordion__heading {
      .cds--accordion__arrow {
        transform: rotate(-90deg) scale(1.5) !important;
      }
    }

    .cds--accordion__title {
      margin: 0px !important;
      width: 180px !important;
      padding-right: 0rem !important;
    }

    .cds--accordion__heading {
      height: 64px;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
    }

    .cds--accordion__content {
      padding: 0px !important;
      margin: 0px !important;
    }

    .cds--accordion__item:last-child {
      margin-bottom: 0px;
      border-bottom: none;
    }
  }

  background-color: ${colors.uiGrey0};
  box-shadow: 0px 2px 15px 4px ${colors.brandSlate2};
  margin-top: 18px;
`;

const AccordionItem = styled(M.AccordionItem)`
  width: 100%;
  border-top: none;
  #mastodon && {
    &.cds--accordion__item {
      .cds--accordion__arrow {
        transform: rotate(-270deg) scale(1.5) !important;
        margin: 4px 0px 0px 0px !important;
      }
    }

    &.cds--accordion__item--active {
      .cds--accordion__arrow {
        transform: rotate(-90deg) scale(1.5) !important;
      }
    }
  }
`;

const FeeAccordionItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;

  * > div:first-child {
    padding-top: 0px !important;
  }
`;

const FeeAccordionItem = styled.div`
  width: 260px;
  text-align: center;
`;

const FeeAccordionItemBorder = styled.div`
  border-left: 1px solid ${colors.brandSlate2};
  height: 310px;
  align-self: center;
`;

const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.brandNavy3};
  margin-top: 3px;
  @media (max-device-width: 530px) {
    margin-top: 14px;
    font-size: 12px;
    .screening-icon {
      display: none;
    }
  }
`;

const PackageListItem = styled.div`
  font-size: 14px;
  padding-top: 15px;
  height: 47px;
  text-align: center;
  color: ${colors.brandSlate7};
`;

const CheckmarkIcon = styled.div`
  color: ${colors.brandAqua3};
  font-size: 16px;
`;

const ExIcon = styled.div`
  color: ${colors.uiOrange600};
  font-size: 16px;
`;

const PriceLabel = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${colors.brandAqua4};
  text-align: center;
  height: 59px;
`;

const TimeIcon = styled(M.Icon)`
  color: ${colors.brandAqua4};
  margin-right: 3px;
`;

const AverageTurnaround = styled.div`
  display: flex;
  font-size: 14px !important;
  padding-top: 16px;
  padding-bottom: 17px;
  font-weight: 500;
  height: 47px;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  color: ${colors.brandNavy3};
`;

const FeeInfo = styled.div`
  color: ${colors.brandSlate6};
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin: 0px 32px;
  padding: 20px 0px;
  line-height: 17px;

  &:not(:first-child) {
    border-top: 1px solid ${colors.brandSlate2};
  }
`;

const Blurb = styled.p`
  text-align: center;
  color: var(--text-icon-black-tertiary-65, rgba(26, 32, 38, 0.65)) !important;
  padding-top: 1rem;
  padding-bottom: 0.75rem;
  margin: 0 1rem;
  height: 4rem;
`;

const FeeInfoStrictHeight = styled(FeeInfo)`
  height: 160px;
`;

const Link = styled.a`
  color: ${colors.brandSlate6} !important;
  text-decoration: underline;
`;

const DomesticPackageSelector = () => {
  const {
    packageType,
    functions: { setPackageType, addInfoLinksClickedToReducerState },
  } = useCreatePackageContext();

  type handlerPropTypes = {
    target: {
      value: string;
    };
  };

  const handleBasicPackageType = useCallback(
    ({ target: { value } }: handlerPropTypes) =>
      value !== BASIC ? setPackageType(BASIC) : setPackageType(''),
    [setPackageType],
  );

  const handleCompletePackageType = useCallback(
    ({ target: { value } }: handlerPropTypes) =>
      value !== COMPLETE_CRIMINAL
        ? setPackageType(COMPLETE_CRIMINAL)
        : setPackageType(''),
    [setPackageType],
  );

  const handleEssentialPackageType = useCallback(
    ({ target: { value } }: handlerPropTypes) =>
      value !== ESSENTIAL ? setPackageType(ESSENTIAL) : setPackageType(''),
    [setPackageType],
  );

  const handleInfoLinksClicked = useCallback(
    infoLink => {
      addInfoLinksClickedToReducerState(infoLink);
    },
    [addInfoLinksClickedToReducerState],
  );

  const handleCountyCourtClick = useCallback(() => {
    handleInfoLinksClicked('County Court Records');
  }, [handleInfoLinksClicked]);

  return (
    <SelectPackageContainer data-testid='domestic-package-selector-container'>
      <List>
        <BoldListItem>Average turnaround</BoldListItem>
        <ListItem>Social Security number (SSN) trace</ListItem>
        <ListItem>Sex offender registry search</ListItem>
        <ListItem>Global watchlist search</ListItem>
        <ListItem>National criminal search</ListItem>
        <ListItem>State Criminal Search</ListItem>
        <ListItem>Federal Criminal Search</ListItem>
      </List>
      <PackageTypeOuterContainer>
        <PackageTypeInnerContainer>
          <PackageTypeItem>
            <RadioButton
              labelText={<BoldHeader>Basic+</BoldHeader>}
              onClick={handleBasicPackageType}
              value={packageType}
              checked={packageType === BASIC}
            />
            <Blurb className='p4'>Simple and limited search</Blurb>
            <PriceLabel>Starting at $29.99</PriceLabel>
            <AverageTurnaround>
              <TimeIcon icon='Time' size='20' /> less than 1 minute
            </AverageTurnaround>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <ExIcon>{EX}</ExIcon>
            </PackageListItem>
            <PackageListItem>
              <ExIcon>{EX}</ExIcon>
            </PackageListItem>
          </PackageTypeItem>
          <HighlightedPackageTypeItem>
            <HighlightedPackageTypeItemSpacer>
              <HighlightedHeaderText>MOST POPULAR</HighlightedHeaderText>
            </HighlightedPackageTypeItemSpacer>
            <RadioButton
              labelText={<BoldHeader>Essential</BoldHeader>}
              onClick={handleEssentialPackageType}
              value={packageType}
              checked={packageType === ESSENTIAL}
            />
            <Blurb className='p4'>Most common criminal searches</Blurb>
            <PriceLabel>Starting at $54.99</PriceLabel>
            <AverageTurnaround>
              <TimeIcon icon='Time' size='20' />
              {' 3-5 business days'}
            </AverageTurnaround>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <ExIcon>{EX}</ExIcon>
            </PackageListItem>
            <PackageListItem>
              <ExIcon>{EX}</ExIcon>
            </PackageListItem>
          </HighlightedPackageTypeItem>
          <PackageTypeItem>
            <RadioButton
              labelText={<BoldHeader>Complete</BoldHeader>}
              onClick={handleCompletePackageType}
              value={packageType}
              checked={packageType === COMPLETE_CRIMINAL}
            />
            <Blurb className='p4'>Most thorough coverage</Blurb>
            <PriceLabel>Starting at $89.99</PriceLabel>
            <AverageTurnaround>
              <TimeIcon icon='Time' size='20' /> 5-7 business days
            </AverageTurnaround>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
            <PackageListItem>
              <CheckmarkIcon>{CHECKMARK}</CheckmarkIcon>
            </PackageListItem>
          </PackageTypeItem>
        </PackageTypeInnerContainer>
        <Accordion>
          <AccordionItem
            data-testid='learn-more-about-fees-dropdown'
            title={<CategoryTitle>Learn more about fees</CategoryTitle>}
          >
            <FeeAccordionItemsContainer>
              <FeeAccordionItem>
                <FeeInfoStrictHeight>
                  If a national criminal search identifies potential records,
                  Checkr automatically completes criminal searches in relevant
                  counties for $15. Court fees for each county might apply.
                </FeeInfoStrictHeight>
                <FeeInfo>
                  Additional{' '}
                  <Link
                    href='https://checkr.com/pricing/passthrough-fees'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleCountyCourtClick}
                  >
                    county court fees
                  </Link>{' '}
                  might apply.
                </FeeInfo>
              </FeeAccordionItem>
              <FeeAccordionItemBorder />
              <FeeAccordionItem>
                <FeeInfoStrictHeight>
                  Includes county criminal searches based on the
                  candidate&apos;s last 7 years of address history, along with
                  any potential records identified in the national criminal
                  search. <br /> <br />
                  Includes county service fee.
                </FeeInfoStrictHeight>

                <FeeInfo>
                  Additional{' '}
                  <Link
                    href='https://checkr.com/pricing/passthrough-fees'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleCountyCourtClick}
                  >
                    county court fees
                  </Link>{' '}
                  might apply.
                </FeeInfo>
              </FeeAccordionItem>
              <FeeAccordionItemBorder />
              <FeeAccordionItem>
                <FeeInfoStrictHeight>
                  Includes county criminal searches based on the
                  candidate&apos;s last 7 years of address history, along with
                  any potential records identified in the national criminal
                  search.
                  <br /> <br />
                  Includes county service fee.
                </FeeInfoStrictHeight>
                <FeeInfo>
                  Additional{' '}
                  <Link
                    href='https://checkr.com/pricing/passthrough-fees'
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleCountyCourtClick}
                  >
                    county court fees
                  </Link>{' '}
                  and verification fees might apply.
                </FeeInfo>
              </FeeAccordionItem>
            </FeeAccordionItemsContainer>
          </AccordionItem>
        </Accordion>
      </PackageTypeOuterContainer>
    </SelectPackageContainer>
  );
};

export default DomesticPackageSelector;
