// Pages that show the hamburger menu on small screen sizes < 1200px
export const isSmallScreenRoute = (currentRoute: string) => {
  const smallScreenRoutes = [
    '/order/get-started',
    '/order/select-your-package',
    '/order/customize-with-addons',
    '/order/review-and-submit',
    '/order/manual-entry',
    '/order-experience/get-started',
    '/order-experience/select-your-package',
    '/order-experience/add-ons',
    '/order-experience/review-and-submit',
    '/order-experience/bulk-upload',
    '/order-experience/manual-entry',
  ];
  return smallScreenRoutes.includes(currentRoute);
};

// Routes in which both the side nav & hamburger menu are not visible
export const hideNavigation = (currentRoute: string) => {
  const hiddenNavigationRoutes = [
    '/package/get-started',
    '/package/select-package',
    '/package/customize',
  ];

  // Checks for /packages/:id/edit route
  if (currentRoute.includes('/packages')) {
    const editRegex = /^\/packages\/[^\/]+\/edit$/;
    if (editRegex.test(currentRoute)) {
      return true;
    }
    return false;
  }
  return hiddenNavigationRoutes.includes(currentRoute);
};
