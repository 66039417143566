import React, { useMemo, useState, useCallback, ReactNode } from 'react';
import { Geo, PostPackageType } from '@dashboard-experience/utils';
import { BillingEntity } from 'types/Billing';
import type {
  GetStartedT,
  AddonsT,
  BulkUploadT,
} from 'components/BetterOrderExperience';
import BetterOrderExperienceContext, { INITIAL_VALUES } from './context';

type Props = {
  children: ReactNode;
};

const Provider: React.FC<Props> = ({ children }) => {
  // GET STARTED
  const [infoSource, setInfoSource] = useState<GetStartedT.InfoSource>(
    INITIAL_VALUES.infoSource,
  );
  const [infoMethod, setInfoMethod] = useState<GetStartedT.InfoMethod>(
    INITIAL_VALUES.infoMethod,
  );
  const [paymentProfile, setPaymentProfile] = useState<BillingEntity>(
    INITIAL_VALUES.paymentProfile,
  );
  const [geo, setGeo] = useState<Geo>(INITIAL_VALUES.geo);
  const [location, setLocation] = useState<GetStartedT.WorkLocation>(
    INITIAL_VALUES.location,
  );
  const [node, setNode] = useState<GetStartedT.Node>(INITIAL_VALUES.node);
  const [program, setProgram] = useState<GetStartedT.Program>(
    INITIAL_VALUES.program,
  );
  const [invites, setInvites] = useState<GetStartedT.Invite[]>(
    INITIAL_VALUES.invites,
  );
  const [csv, setCsv] = useState<GetStartedT.BulkInviteCSV>(INITIAL_VALUES.csv);
  const [emails, setEmails] = useState<GetStartedT.Email[]>(
    INITIAL_VALUES.emails,
  );
  const [manualBulkUploadType, setManualBulkUploadType] =
    useState<GetStartedT.ManualOrderType>(INITIAL_VALUES.manualOrderType);
  const [localeType, setLocaleType] = useState<GetStartedT.Locale>(
    INITIAL_VALUES.localeType,
  );
  const [manualOrderType, setManualOrderType] = useState(
    INITIAL_VALUES.manualOrderType,
  );

  // SELECT PACKAGE
  const [selectedPackage, setSelectedPackage] = useState(
    INITIAL_VALUES.selectedPackage,
  );

  // ADD-ONS
  const [additionalProperties, setAdditionalProperties] =
    useState<AddonsT.AdditionalProperties>(INITIAL_VALUES.additionalProperties);

  const [addedScreeningTypeWithPrices, setAddedScreeningTypeWithPrices] =
    useState<AddonsT.ScreeningTypeWithPrices[]>(
      INITIAL_VALUES.addedScreeningTypeWithPrices,
    );

  const [newlyCreatedPackage, setNewlyCreatedPackage] =
    useState<PostPackageType>(INITIAL_VALUES.newlyCreatedPackage);

  const [aliasAdded, setAliasAdded] = useState<AddonsT.Alias>(
    INITIAL_VALUES.aliasAdded,
  );
  const [saveForNextTime, setSaveForNextTime] = useState<boolean>(
    INITIAL_VALUES.saveForNextTime,
  );
  const [newPackageName, setNewPackageName] = useState<string>(
    INITIAL_VALUES.newPackageName,
  );
  const [manualBulkUploadData, setManualBulkUploadData] =
    useState<BulkUploadT.ManualBulkUploadData>(
      INITIAL_VALUES.manualBulkUploadData,
    );
  const [pricesSummary, setPricesSummary] = useState<AddonsT.PricesSummary>(
    INITIAL_VALUES.pricesSummary,
  );

  const updateLocalStorage = useCallback(values => {
    const boxValues = localStorage.getItem('betterOrderExperience');
    let updatedValues = {};
    if (!boxValues) updatedValues = values;
    else {
      const parsedBoxValues = JSON.parse(boxValues);
      updatedValues = { ...parsedBoxValues, ...values };
    }

    localStorage.setItem(
      'betterOrderExperience',
      JSON.stringify(updatedValues),
    );
  }, []);

  const update = useCallback(
    values => {
      if (values.infoSource) setInfoSource(values.infoSource);
      if (values.infoMethod) setInfoMethod(values.infoMethod);
      if (values.manualOrderType) setManualOrderType(values.manualOrderType);
      if (values.paymentProfile) setPaymentProfile(values.paymentProfile);
      if (values.geo) setGeo(values.geo);
      if (values.location) setLocation(values.location);
      if (values.node) setNode(values.node);
      if (values.program) setProgram(values.program);
      if (values.localeType) setLocaleType(values.localeType);
      if (values.invites) setInvites(values.invites);
      if (values.csv) setCsv(values.csv);
      if (values.emails) setEmails(values.emails);
      if (values.manualBulkUploadType)
        setManualBulkUploadType(values.manualBulkUploadType);
      if (values.selectedPackage) setSelectedPackage(values.selectedPackage);
      if (values.addedScreeningTypeWithPrices?.length)
        setAddedScreeningTypeWithPrices(values.addedScreeningTypeWithPrices);
      if (values.newlyCreatedPackage)
        setNewlyCreatedPackage(values.newlyCreatedPackage);
      if (values.aliasAdded) setAliasAdded(values.aliasAdded);
      if (values.pricesSummary) setPricesSummary(values.pricesSummary);
      if (values.saveForNextTime) setSaveForNextTime(values.saveForNextTime);
      if (values.newPackageName) setNewPackageName(values.newPackageName);
      if (values.manualBulkUploadData)
        setManualBulkUploadData(values.manualBulkUploadData);
      if (values.additionalProperties)
        setAdditionalProperties(values.additionalProperties);
      if (values.addedScreeningTypeWithPrices?.length)
        setAddedScreeningTypeWithPrices(values.addedScreeningTypeWithPrices);

      updateLocalStorage(values);
    },

    [updateLocalStorage, setPaymentProfile],
  );

  const providerValue = useMemo(
    () => ({
      infoSource,
      infoMethod,
      paymentProfile,
      geo,
      location,
      node,
      program,
      localeType,
      invites,
      csv,
      emails,
      manualBulkUploadType,
      manualOrderType,
      selectedPackage,
      addedScreeningTypeWithPrices,
      additionalProperties,
      aliasAdded,
      saveForNextTime,
      newPackageName,
      manualBulkUploadData,
      newlyCreatedPackage,
      pricesSummary,
      update,
    }),
    [
      infoSource,
      infoMethod,
      paymentProfile,
      geo,
      location,
      node,
      program,
      localeType,
      invites,
      csv,
      emails,
      manualBulkUploadType,
      manualOrderType,
      selectedPackage,
      addedScreeningTypeWithPrices,
      additionalProperties,
      aliasAdded,
      saveForNextTime,
      newPackageName,
      manualBulkUploadData,
      newlyCreatedPackage,
      pricesSummary,
      update,
    ],
  );

  return (
    <BetterOrderExperienceContext.Provider value={providerValue}>
      {children}
    </BetterOrderExperienceContext.Provider>
  );
};

export default Provider;
