import { MouseEventHandler } from 'react';

export type AnalyticsIframeProps = { lookerName: string };
export type AnalyticsProps = { initialCategory: string };

export enum AnalyticsStates {
  LEGACY = 'legacy',
  V2 = 'v2',
}

export type BannerProps = {
  lookerName: string;
  toggle: MouseEventHandler<HTMLButtonElement>;
  state: AnalyticsStates;
};
